import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { login, register } from "../../../../actions/modals";
import DashboardOff from "../../../../assets/images/svg/dashboard-off.svg";
import TicketOff from "../../../../assets/images/svg/ticket-off.svg";
import ChatOff from "../../../../assets/images/svg/chat-off.svg";
import NotificationOff from "../../../../assets/images/svg/notification-off.svg";
import InstagramDark from "../../../../assets/images/svg/instagram-dark.svg";
import FacebookDark from "../../../../assets/images/svg/facebook-dark.svg";
import TwitterDark from "../../../../assets/images/svg/twitter-dark.svg";
import {
  messageListSidebar,
  notificationListSidebar
} from "../../../../actions/sidebars";
import moment from "moment";
import { toggleHyveRegister } from "../../../../actions/toggles";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { unsubscribe } from "../../../../actions/modals";
import { isNgCountry } from "../../../../utils/misc";
import platformParams from "../../../../utils/platformParams";
import { famobi_detection } from "../../../../utils/famobiDetect";
import { SelectPackageModal } from "../../../../components/select-package-modal/SelectPackageModal";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.deviceInfo = famobi_detection;
    this.state = { toggleMenu: false };
    this.state = {modalVisible: false}
  }

  render() {
    const { t } = this.props;
    let fullScreenClass = "d-block";
    if (this.props.isGameFullScreen) {
      fullScreenClass = "d-none";
    }
    const totalNoficationUnread =
      this.props.notifications.unread + this.props.notifications.unreadFriends;
    const lang = i18n.language;
    return (
      <>
      <SelectPackageModal 
						// ref={this.modalVisible}
						showModal={this.state.modalVisible}
						showLinkToSignIn={false}
						toggleModal={() => {this.setState({modalVisible: false})}}
					/>
        <footer
          className={
            "container-fluid bg-gradient-white" +
            (this.props.match.path === "/" ? "" : " d-none d-lg-block")
          }
        >
          <div className="row">
            <div className="container">
              <div className="row">
                <section
                  className={
                    "col-md-4 d-flex justify-content-center justify-content-md-start footer-sec-1   pl-custom" +
                    platformParams['className']
                  }
                >
                  <div className="footer-logo" 
                    style={{ 
                      width: "100%",  
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <img
                      style={{
                        height:"50px",
                      }}
                      src={platformParams['headerLogoSvg']}
                      alt=""
                    />
                  </div>
                </section>
                <section
                  className={
                    "col-md-3 d-flex justify-content-center justify-content-md-start footer-sec-2 " +
                    platformParams['className']
                  }
                >
                  {this.props.isLoggedIn ? (
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <h4>{t("play")}</h4>
                      </li>
                      <li>
                        <a href={"/" + lang + "/challenges/live"}>
                          {t("liveTournaments")}
                        </a>
                      </li>
                      <li>
                        <a href={"/" + lang + "/challenges/upcoming"}>
                          {t("upcomingTournaments")}
                        </a>
                      </li>
                      <li>
                        <a href={"/" + lang + "/challenges/quickplay"}>
                          {t("quickPlay")}
                        </a>
                      </li>
                      { !isNgCountry() ?
                        <li>
                          <a href={"/" + lang + "/raffles"}>
                            {t("raffleTicket")}
                          </a>
                        </li>
                        : <></>
                      }
                    </ul>
                  ) : (
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <h4>{t("play")}</h4>
                      </li>
                      <li>
                        <span
                          className="a"
                          onClick={() => {
                            this.setState({modalVisible: true})
                            // this.props.history.push(
                            //   "/" + lang + "/join-now"
                            // );
                            // this.props.resetHyveRegister();
                            // this.props.registerOpen();
                          }}
                        >
                          {t("live")} {t("navTournaments")}
                        </span>
                      </li>
                      <li>
                        <span
                          className="a"
                          onClick={() => {
                            this.setState({modalVisible: true})
                            // this.props.history.push(
                            //   "/" + lang + "/join-now"
                            // );
                            // this.props.resetHyveRegister();
                            // this.props.registerOpen();
                          }}
                        >
                          {t("upcoming")} {t("navTournaments")}
                        </span>
                      </li>
                      <li>
                        <span
                          className="a"
                          onClick={() => {
                            // this.props.history.push(
                            //   "/" + lang + "/join-now"
                            // );
                            this.setState({modalVisible: true})
                            // this.props.resetHyveRegister();
                            // this.props.registerOpen();
                          }}
                        >
                          {t("quickPlay")}
                        </span>
                      </li>
                      {
                        !isNgCountry() ?
                          <li>
                            <span
                              className="a"
                              onClick={() => {
                                // this.props.history.push(
                                //   "/" + lang + "/join-now"
                                // );
                                this.setState({modalVisible: true})
                                // this.props.resetHyveRegister();
                                // this.props.registerOpen();
                              }}
                            >
                              {t("raffleTicket")}
                            </span>
                          </li>
                          : <></>
                      }

                    </ul>
                  )}
                </section>
                <section
                  className={
                    "col-md-3 pl-custom d-flex justify-content-center justify-content-md-start footer-sec-3 " +
                    platformParams['className']
                  }
                >
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <h4>{t("support")}</h4>
                    </li>
                    {this.props.isLoggedIn ? null : (
                      <li>
                        {/* <span
                          className="a"
                          onClick={() => {
                            this.props.resetHyveRegister();
                            this.props.registerOpen();
                          }}
                        >
                          Join Now
                        </span> */}
                        <a href="javascript:void(0)"
                          onClick={() => {
                            this.setState({modalVisible: true})
                          }}>
                          {t("joinNow")}
                        </a>
                      </li>
                    )}

                    {/* <li>
                      <a href={this.props.isLoggedIn ?"/dashboard":"/"}>Premium Account</a>
                    </li> */}
                    <li>
                      <a href={"/" + lang + "/help-support"}>
                        {t("helpSupport")}
                      </a>
                    </li>
                    <li>
                    <a href={"/" + lang + "/terms-conditions"}>
                        {t("termsCondition")}
                      </a>
                    </li>
                    <li>
                    <a href={"/" + lang + "/privacy-policy"}>
                        {t("privacyPolicy")}
                      </a>
                    </li>
                    {this.props.isLoggedIn ? <li>
                      {/* <a href="#">{t("unsubscribe")}</a> */}

                      <span
                        className="a"
                        onClick={() => {
                          this.props.unsubscribeOpen();
                        }}
                      >
                        {t("unsubscribe")}
                      </span>
                    </li> : <></>}
                  </ul>
                </section>
                <section
                  className={
                    "col-md-2 d-flex d-flex justify-content-center justify-content-md-end footer-sec-4 " +
                    platformParams['className']
                  }
                >
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <h4>{t("company")}</h4>
                    </li>
                    <li>
                      <a href={"/" + lang + "/about-us"}>{t("navAboutUs")}</a>
                    </li>
                    <li>
                      <a href={"/" + lang + "/news"}>{t("navNews")}</a>
                    </li>
                    <li>
                      <a href={"/" + lang + "/contact-us"}>
                        {t("navContactUs")}
                      </a>
                    </li>
                    <li>
                      <ul className="list-inline socialMedia mt-3">
                        <li className="list-inline-item">
                          <a
                            href="https://www.instagram.com/mtnza/"
                            target="_blank"
                          >
                            <span>
                              <img src={InstagramDark} alt="" />
                            </span>
                          </a>
                        </li>
                        <li className="list-inline-item pl-1">
                          <a
                            href="https://www.facebook.com/MTNza/"
                            target="_blank"
                          >
                            <span>
                              <img src={FacebookDark} alt="" />
                            </span>
                          </a>
                        </li>
                        <li className="list-inline-item pl-1">
                          <a
                            href="https://twitter.com/MTNza"
                            target="_blank"
                          >
                            <span>
                              <img src={TwitterDark} alt="" />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
            <div
              className={
                "col-md-12 " +
                ( "bg-" +platformParams['colorClassSufix'] ) +
                " text-center text-white copyright mt-4"
              }
            >
              <p>
                © {moment().year()}{" "}
                {platformParams['titleX']} by Streamplay Solutions
              </p>
            </div>
          </div>
        </footer>
        <footer className="container-fluid bg-gradient-white fixed-bottom d-block d-lg-none">
          {!this.props.isLoggedIn ? (
            <div className="col-sm-12 d-block d-lg-none bg-white p-3 footer-wrapper fixed-bottom">
              <div className="row gutter-space">
                { !isNgCountry()
                  ?
                  <div className="col-5">
                    <button
                      onClick={() => {
                      // this.props.loginOpen();
                      this.props.history.push("/" + lang + "/billing-login");
                      }}
                      data-toggle="modal"
                      data-target="#login"
                      className="btn btn-yellow footer light btn-block"
                    >
                      {t("signin")}
                    </button>
                  </div>
                  :
                  <></>
                }

                <div className="col">
                  <button
                    onClick={() => {
                      // this.props.history.push("/" + lang + "/join-now");
                      this.setState({modalVisible: true})
                    }}
                    data-toggle="modal"
                    data-target="#onboarding"
                    className={"btn btn-" +  platformParams['baseButtonClassSufix'] + " footer btn-block light"}
                  >
                    {t("joinNow")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                  "container-fluid bg-gradient-white fixed-bottom " +
                  fullScreenClass +
                  " d-lg-none"
              }
              >
              <div className={"row footer-afterLogin"+(this.deviceInfo.is.iPhoneX === true ? " pb-20":" ")}>
                  <div className="col">
                  <a href={"/" + lang + "/dashboard"} className="">
                      <figure className="d-flex flex-column justify-content-center align-items-center">
                      <img
                          src={
                          this.props.match.path === "/" + lang + "/dashboard"
                              ? platformParams['dashboardOnImage']
                              : DashboardOff
                          }
                          className="mb-1 off"
                          alt=""
                      />
                      <figcaption
                          className={
                          this.props.match.path === "/" + lang + "/dashboard"
                              ? (" text-" + platformParams['linkClassSufix'])
                              : ""
                          }
                      >
                          {t("navDashboard")}
                      </figcaption>
                      </figure>
                  </a>
                  </div>
                  {
                    !isNgCountry() ?
                      <div className="col">
                        <a href={"/" + lang + "/raffles"} className="">
                          <figure className="d-flex flex-column justify-content-center align-items-center">
                          <img
                              src={
                              this.props.match.path === "/" + lang + "/raffles"
                                  ? platformParams['ticketOnImage']
                                  : TicketOff
                              }
                              className="mb-1 off"
                              alt=""
                          />
                          <figcaptionfinsta
                              className={
                              this.props.match.path === "/" + lang + "/raffles"
                                  ? (" text-" + platformParams['linkClassSufix'])
                                  : ""
                              }
                          >
                              {t("raffles")}
                          </figcaptionfinsta>
                          </figure>
                      </a>
                      </div>
                      : <></>
                  }
                  <div className="col">
                  <a
                      href="javascript:void(0);"
                      onClick={() => {
                      this.props.messageListSidebarOpen();
                      }}
                  >
                      <span>
                      <figure className="d-flex flex-column justify-content-center align-items-center">
                          <img src={ChatOff} className="mb-1 off" alt="" />
                          {this.props.messagehistory.unread > 0 ? (
                          <span
                              className={
                              "badge text-white badge-notification-count-responsive message-count " +
                              ( "bg-" + platformParams['linkClassNoti'])
                              }
                          >
                              {this.props.messagehistory.unread}
                          </span>
                          ) : (
                          ""
                          )}
                          <figcaption>{t("messages")}</figcaption>
                      </figure>
                      </span>
                  </a>
                  </div>
                  <div className="col">
                  <a
                      ref="javascript:void(0);"
                      onClick={() => {
                      this.props.notificationListSidebarOpen();
                      }}
                  >
                      <span>
                      <figure className="d-flex flex-column justify-content-center align-items-center">
                          <img
                          src={NotificationOff}
                          width="24"
                          height="24"
                          className="mb-1 off"
                          alt=""
                          />
                          {totalNoficationUnread > 0 ? (
                          <span
                              className={
                              "badge text-white badge-notification-count-responsive message-count " +
                              ( "bg-" + platformParams['linkClassNoti'])
                              }
                          >
                              {totalNoficationUnread}
                          </span>
                          ) : (
                          ""
                          )}
                          <figcaption>{t("notifications")}</figcaption>
                      </figure>
                      </span>
                  </a>
                  </div>
              </div>
              </div>
          )}
        </footer>
      </>
    );
  }
}
const mapDispatchToProps = {
  loginOpen: login.open,
  registerOpen: register.open,
  messageListSidebarOpen: messageListSidebar.open,
  notificationListSidebarOpen: notificationListSidebar.open,
  resetHyveRegister: toggleHyveRegister.reset,
  unsubscribeOpen: unsubscribe.open
};

const mapStateToProps = state => {
  return {
    userData: state.user.item || {},
    isLoggedIn: state.login.isLoggedIn || false,
    isLogging: state.login.isLogging || false,
    isGameFullScreen: state.toggles.gameFullScreen || false,
    messagehistory: state.messagehistory,
    notifications: state.notifications,
    currentLang: state.login.currentLang
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Footer))
);
