import { incrementFetch, decrementFetch } from "../../loaders";

export const invalidate = (params, prefix) => ({
  type: prefix + "_INVALIDATE_" + params.code
});
export const request = (params, prefix) => ({
  type: prefix + "_REQUEST_" + params.code
});
export const receive = (params, data, prefix) => ({
  type: prefix + "_RECEIVE_" + params.code,
  data: data
});
export const receiveError = (params, prefix) => ({
  type: prefix + "_RECEIVE_ERROR_" + params.code
});

export const shouldFetch = (state, params) => {
  const data = state[params.stateKey][params.stateSubKey];
  if (!data) {
    return true;
  }
  if (data.isFetching) {
    return false;
  }
  return data.didInvalidate;
};

export const fetchData = (params, prefix, ...extraArgs) => {
  return (dispatch, getState) => {
    return params
      .service(...extraArgs, dispatch, getState)
      .then(json => {
        dispatch(receive(params, params.dataMapper(json), prefix));
        dispatch(decrementFetch());
        if (params.onResponse) {
          params.onResponse(json, dispatch, getState);
        }
      })
      .catch(response => {
        dispatch(receiveError(params, prefix));
        dispatch(decrementFetch());
        if (params.onError) {
          params.onError(response, dispatch, getState);
        }
      });
  };
};
export const ActionCreator = prefix => params => ({
  invalidate: () => invalidate(params, prefix),
  request: () => request(params, prefix),
  receive: data => receive(params, data, prefix),
  shouldFetch: state => shouldFetch(state, params),
  fetchIfNeeded: (...extraArgs) => {
    return (dispatch, getState) => {
      if (shouldFetch(getState(), params)) {
        dispatch(request(params, prefix));
        dispatch(incrementFetch());
        return dispatch(fetchData(params, prefix, ...extraArgs));
      } else {
        return Promise.resolve();
      }
    };
  },
  fetchData: postParams => fetchData(params, prefix, postParams)
});
export const receiveForPaginationList = (
  params,
  data,
  isLastPage = false,
  prefix
) => ({
  type: prefix + "_RECEIVE_" + params.code,
  data: data,
  isLastPage: isLastPage
});
export const receiveForPaginationListAll = (
  params,
  data,
  isLastPage = false,
  prefix
) => ({
  type: prefix + "_RECEIVE_ALL_" + params.code,
  data: data,
  isLastPage: isLastPage
});

export const fetchDataForPaginationList = (params, ...extraArgs) => {
  return (dispatch, getState) => {
    return params
      .service(...extraArgs, dispatch, getState)
      .then(json => {
        dispatch(
          receiveForPaginationList(
            params,
            params.dataMapper(json),
            params.lastPageMapper ? params.lastPageMapper(json) : false,
            "PLIST"
          )
        );
        dispatch(decrementFetch());
        if (params.onResponse) {
          params.onResponse(json, dispatch, getState);
        }
      })
      .catch(response => {
        dispatch(receiveError(params, "PLIST"));
        dispatch(decrementFetch());
        if (params.onError) {
          params.onError(response, dispatch, getState);
        }
      });
  };
};
export const fetchDataForPaginationListAll = (params, ...extraArgs) => {
  return (dispatch, getState) => {
    return params
      .service(...extraArgs, dispatch, getState)
      .then(json => {
        dispatch(
          receiveForPaginationListAll(
            params,
            params.dataMapper(json),
            params.lastPageMapper ? params.lastPageMapper(json) : false,
            "PLIST"
          )
        );
        dispatch(decrementFetch());
        if (params.onResponse) {
          params.onResponse(json, dispatch, getState);
        }
      })
      .catch(response => {
        dispatch(receiveError(params, "PLIST"));
        dispatch(decrementFetch());
        if (params.onError) {
          params.onError(response, dispatch, getState);
        }
      });
  };
};
