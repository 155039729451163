import { createStore, applyMiddleware, compose } from "redux";

import thunk from "redux-thunk";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "modals",
    "sidebars",
    "subscribe",
    "errors",
    "forms",
    "redirect",
    "mytournaments",
    "challenges",
    "brands",
    "tournaments",
    "categories",
    "news",
    "raffles",
    "toggles",
    "loaders",
    "profiles",
    "toastr",
    "socketusers",
    "notifications",
    "messagehistory",
    "chathistory",
    "vas_blacklist"
  ]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

const composeEnhancers = process.env.REACT_APP_REDUX_LOG === "ON" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
export default () => {
  let persistor = persistStore(store, [() => { }]);
  return { store, persistor };
};
