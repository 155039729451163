import React from "react";
import { CHAT_HISTORY_REQUEST,CHAT_HISTORY_RESPONSE,CLOSE_CHAT_HISTORY,OPEN_CHAT_HISTORY,CHAT_PAGINATION ,ADD_NEW_CHAT_MESSAGE} from "../types/chathistory";
import socket from "../socket";
import { messageHistoryRequest } from "../actions/messagehistory";
import {  Trans } from "react-i18next";

export const closeChatHistory = () => ({
  type: CLOSE_CHAT_HISTORY
});


export const openChatHistory = (profileDetail) => (dispatch,getState) => {
  dispatch({ type: OPEN_CHAT_HISTORY, payload: profileDetail });
  dispatch(chatHistoryRequest(profileDetail.id));
};



export const messageSent = (message, chatroomId, type = "private") => (dispatch,getState) => {
  const user = getState().login;
  if (!user.isLoggedIn) return false;

  let data = {
    type: type,
    chatroomId: chatroomId,
    content: message,
    event: "message-sent"
  };

  socket.emit("chat", data);
};





export const messageReceived = data => (dispatch, getState) => {
  
  let userId = getState().user.item.id;
  let chatRoomId = getState().chathistory.chatRoomId;

  if(chatRoomId === data.chatroom_id)
  {
    dispatch({ type: ADD_NEW_CHAT_MESSAGE, payload: data });

    if(data.from_user_id !== userId)
    {
      let sentData = { messageId: data.id, event: "message-read-single" };
      socket.emit("chat", sentData);
    }  
  }
  else
  { 
    dispatch(messageHistoryRequest());
  }
  
};



export const loadMoreChat = (otherUserId) => (dispatch, getState) => {
  
  const isLastPage = getState().chathistory.isLastPage;
  const userChatList = getState().chathistory.items;
  const pageNo = getState().chathistory.page+1;
   
  if(userChatList.length > 0 && isLastPage)
  {
     dispatch(chatHistoryRequest(otherUserId,pageNo));
  }
  return false;
};


export const chatHistoryRequest = (otherUserId,pageNo = 1) => (dispatch, getState) => {
  
  dispatch({ type: CHAT_HISTORY_REQUEST, payload: pageNo });
  let data = {
    event: "chat-history-request",
    otherUserId: otherUserId,
    pageNo:pageNo,
    message : pageNo == 1 ? <Trans i18nKey="sayHi">Say hi to your new friend!</Trans> : ""
  };
  socket.emit("chat", data);
};

export const chatHistoryResponse = response => (dispatch, getState) => {
  let data = response.data;
  if(data.length > 0)
  {

    dispatch({
      type: CHAT_HISTORY_RESPONSE,
      payload: data
    });

    dispatch({
      type: CHAT_PAGINATION,
      payload: response.isLastPage
    });

    dispatch(messageHistoryRequest());
  }  
};

