import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { resetPassword } from "../../../../actions/modals";
import { resetPasswordForm } from "../../../../actions/forms";
import "./ResetPasswordModal.scss";
import Modal from "../../Modal";
import { togglePasswordEyeReset } from "../../../../actions/toggles";
import { APP_MESSAGES } from "../../../../services/config/messages";
import AppInputComponent from "../../../../layouts/components/sidebars/AppInputComponent";
import {
  validatePassword,
  validatePasswordRepeat
} from "../../../../utils/validators";
import { withTranslation } from "react-i18next";

class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.showResetPassword = this.showResetPassword.bind(this);
    this.hideResetPassword = this.hideResetPassword.bind(this);
    this.state = {
      isLandScape: false
    };
  }

  componentDidMount() {
    const { email, resetPasswordHash } = this.props.match.params;
    if (email && resetPasswordHash) this.props.resetPasswordOpen();
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.isResetPasswordOpen === true &&
      nextProps.isResetPasswordOpen === false
    ) {
      this.props.history.push("/");
    }
  }
  showResetPassword() {
    this.props.resetPasswordOpen();
  }
  hideResetPassword() {
    this.props.resetPasswordClose();
  }

  render() {
    let Success;
    let Error;
    Error = this.props.resetPasswordForm.fields.newPassword.errorMsg
      ? APP_MESSAGES[this.props.resetPasswordForm.fields.newPassword.errorMsg](
          this.props.resetPasswordForm.fields.newPassword.errorPayload,
          () => {
            this.props.resetPasswordFormResetError("newPassword");
          }
        )
      : props => <>{""}</>;
    Success = this.props.resetPasswordForm.fields.newPassword.successMsg
      ? APP_MESSAGES[
          this.props.resetPasswordForm.fields.newPassword.successMsg
        ](
          this.props.resetPasswordForm.fields.newPassword.successPayload,
          () => {
            this.props.resetPasswordFormResetSuccess("newPassword");
          }
        )
      : props => <>{""}</>;

    const { email, resetPasswordHash } = this.props.match.params;
    const { t } = this.props;
    return (
      <>
        <Modal
          handleHide={this.hideResetPassword}
          isOpen={this.props.isResetPasswordOpen}
          disableOuterClick={true}
        >
          <div className="card-popup login-form">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="bold">{t("resetPw")}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Error />
                <Success />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (!this.props.resetPasswordForm.isSubmitting) {
                      const password = this.props.resetPasswordForm.fields
                        .newPassword.value;
                      const renewPassword = this.props.resetPasswordForm.fields
                        .renewPassword.value;

                      if (!validatePassword(password)) {
                        this.props.resetPasswordFormSetError(
                          "newPassword",
                          "PASSWORD_LENGTH"
                        );
                        return false;
                      } else if (
                        !validatePasswordRepeat(password, renewPassword)
                      ) {
                        this.props.resetPasswordFormSetError(
                          "newPassword",
                          "COMPARE_RESET_PASSWORD"
                        );
                        return false;
                      }

                      this.props.resetPasswordFormSubmit({
                        newPassword: password,
                        resetPasswordHash: resetPasswordHash
                      });
                    }
                  }}
                >
                  <AppInputComponent
                    type={this.props.isPasswordVisible ? "text" : "password"}
                    labelText={t("enterNewPw")}
                    divClass="form-group with-lable mt-3"
                    value={
                      this.props.resetPasswordForm.fields.newPassword.value
                    }
                    onChange={event => {
                      this.props.resetPasswordFormSetError("newPassword", "");
                      this.props.resetPasswordFormUpdateField(
                        "newPassword",
                        event.target.value
                      );
                    }}
                    inputAddon={() => (
                      <span
                        toggle="#password-field"
                        className={
                          "field-icon toggle-password" +
                          (this.props.isPasswordVisible
                            ? " eye-close"
                            : " eye-open")
                        }
                        onClick={this.props.passwordEyeToggle}
                      />
                    )}
                  />

                  <AppInputComponent
                    type="password"
                    labelText={t("reEnterNewPw")}
                    divClass="form-group with-lable mt-5"
                    value={
                      this.props.resetPasswordForm.fields.renewPassword.value
                    }
                    onChange={event => {
                      this.props.resetPasswordFormSetError("newPassword", "");
                      this.props.resetPasswordFormUpdateField(
                        "renewPassword",
                        event.target.value
                      );
                    }}
                  />

                  <button
                    type="submit"
                    className="btn btn-pink light btn-block mt-2 mt-sm-5"
                  >
                    {t("submit")}
                  </button>
                </form>
              </div>
            </div>
            <div className="cross-div">
              <div className="row">
                <div className="col-sm-12 text-center mt-2 pb-3 pb-sm-0 mt-sm-5">
                  <small className="text-white">
                    <a
                      className="text-white"
                      href="/privacy-policy"
                      target="_blank"
                    >
                     {t("privacyPolicy")}
                    </a>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  resetPasswordOpen: resetPassword.open,
  resetPasswordClose: resetPassword.close,
  passwordEyeToggle: togglePasswordEyeReset.toggle,
  resetPasswordFormSubmit: resetPasswordForm.submit,
  resetPasswordFormReset: resetPasswordForm.reset,
  resetPasswordFormResetField: resetPasswordForm.resetField,
  resetPasswordFormUpdateField: resetPasswordForm.updateField,
  resetPasswordFormSetError: resetPasswordForm.setError,
  resetPasswordFormSetSuccess: resetPasswordForm.setSuccess,
  resetPasswordFormResetError: resetPasswordForm.resetError,
  resetPasswordFormResetSuccess: resetPasswordForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    isPasswordVisible: state.toggles.pwEyeResetPw,
    isResetPasswordOpen: state.modals.isResetPasswordOpen,
    resetPasswordForm: state.forms.resetPasswordForm
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ResetPasswordModal))
);
