import axios from "axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

const headers = {
  "Content-Type": "application/json"
};

export const subscribeEmail = postParams => {
  return axios
    .post(API_SERVER_URL + config.subscribeEmail, postParams, {
      headers: headers
    })
    .catch(err => {
      console.log(err);
    });
};
export const contactUsService = postParams => {
  return axios
    .post(API_SERVER_URL + config.contactUs, postParams, { headers: headers })
    .catch(err => {
      console.log(err);
    });
};
