import { MODAL_CLOSE_ALL, MODAL_OPEN_ANY } from "../../types/modals";

export const ModalCloseAll = () => ({
  type: MODAL_CLOSE_ALL
});
export const ModalOpenAny = () => ({
  type: MODAL_OPEN_ANY
});
export const ModalActionCreator = params => ({
  open: (closeAll = true) => dispatch => {
    if (closeAll) {
      dispatch(ModalCloseAll());
    }
    dispatch(ModalOpenAny());
    dispatch({
      type: "MODAL_" + params.code + "_OPEN"
    });
  },
  close: (closeAll = true) => dispatch => {
    if (closeAll) {
      dispatch(ModalCloseAll());
    } else {
      dispatch({
        type: "MODAL_" + params.code + "_CLOSE"
      });
    }
  }
});
