import React, { Component } from "react";

class CreateTutorialCardComponent extends Component {
  
  render() {
    const { figcaption, figImg, figdescription } = this.props;

    return (
      <div className="card-popup ">
        <div className="row">
          <div className="col-sm-12 text-center mb-0 mb-sm-4">
            <h4 className="mb-3">{figcaption}</h4>
          </div>
        </div>
        <div className="row gutter-space onboarding-responsive">
          <div className="col text-center onboarding-avatar">
            <img src={figImg} className={"img-fluid "+(this.props.keyIndex ? "":"profile-pic-200")} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5>{figdescription}</h5>
          </div>
        </div>
      </div>
    );
  }
}
export default CreateTutorialCardComponent;
