export const ChallengesLandingPopular = {
  code: "CHALLENGES_LANDING_POPULAR",
  stateKey: "challenges",
  stateSubKey: "landingPopular"
};

export const ChallengesListLive = {
  code: "CHALLENGES_LIVE",
  stateKey: "challenges",
  stateSubKey: "live"
};

export const FeatureChallenges = {
  code: "FEATURE_CHALLENGES",
  stateKey: "challenges",
  stateSubKey: "featureChallenges"
};

export const ChallengesListQuickPlay = {
  code: "CHALLENGES_QUICK_PLAY",
  stateKey: "challenges",
  stateSubKey: "quickPlay"
};

export const ChallengesListUpcoming = {
  code: "CHALLENGES_UPCOMING",
  stateKey: "challenges",
  stateSubKey: "upcoming"
};

export const ChallengesByBrand = {
  code: "CHALLENGES_BY_BRAND",
  stateKey: "challenges",
  stateSubKey: "challengesByBrand",
  type: "list"
};

export const ChallengesListPremium = {
  code: "CHALLENGES_PREMIUM",
  stateKey: "challenges",
  stateSubKey: "premium"
};

export const BrandsListPopular = {
  code: "POPULAR_BRANDS",
  stateKey: "brands",
  stateSubKey: "popularBrands"
};

export const BrandsListLive = {
  code: "BRANDS_LIVE",
  stateKey: "brands",
  stateSubKey: "live"
};

export const BrandsListQuickPlay = {
  code: "BRANDS_QUICK_PLAY",
  stateKey: "brands",
  stateSubKey: "quickPlay"
};

export const BrandsListUpcoming = {
  code: "BRANDS_UPCOMING",
  stateKey: "brands",
  stateSubKey: "upcoming"
};
export const BrandsTopPlayersList = {
  code: "BRANDS_TOP_PLAYER",
  stateKey: "brands",
  stateSubKey: "topPlayers"
};

export const FreeToPlayList = {
  code: "FREE_TO_PLAY_CHALLENGES",
  stateKey: "categories",
  stateSubKey: "freeToPlay"
};

export const NewsList = {
  code: "NEWS_ALL",
  stateKey: "news",
  stateSubKey: "newsList"
};
export const NewsListByCategories = {
  code: "NEWS_LIST_BY_CATEGORIES",
  stateKey: "news",
  stateSubKey: "newsListByCategories"
};
export const NewsCategoriesList = {
  code: "NEWS_CATEGORIES_LIST",
  stateKey: "news",
  stateSubKey: "newsCategoriesList"
};

export const RafflesList = {
  code: "RAFFLES_LIST",
  stateKey: "raffles",
  stateSubKey: "rafflesList"
};

export const MyActiveRafflesList = {
  code: "MY_ACTIVE_RAFFLES_LIST",
  stateKey: "raffles",
  stateSubKey: "myActiveRafflesList"
};

export const FriendsFollowers = {
  code: "FOLLOWERS_OF_FRIENDS",
  stateKey: "profiles",
  stateSubKey: "friendsFollowersList",
  type: "list"
};

export const FriendsFollowings = {
  code: "FOLLOWINGS_OF_FRIENDS",
  stateKey: "profiles",
  stateSubKey: "friendsFollowingList",
  type: "list"
};

export const FriendsTournaments = {
  code: "TOURNAMENTS_OF_FRIENDS",
  stateKey: "profiles",
  stateSubKey: "friendsTournamentsList",
  type: "list"
};
export const FriendsList = {
  code: "FRIENDS_LIST",
  stateKey: "friends",
  stateSubKey: "friendsList"
};

export const Followings = {
  code: "FOLLOWINGS",
  stateKey: "profiles",
  stateSubKey: "followingsList",
  type: "list"
};

export const FriendsFollowersViewAll = {
  code: "FRIENDS_FOLLOWERS_VIEW_ALL",
  stateKey: "profiles",
  stateSubKey: "friendsFollowersViewAll",
  type: "list"
};

export const FriendsFollowingViewAll = {
  code: "FRIENDS_FOLLOWING_VIEW_ALL",
  stateKey: "profiles",
  stateSubKey: "friendsFollowingViewAll",
  type: "list"
};
export const LanguagesList = {
  code: "LANGUAGES_LIST",
  stateKey: "profiles",
  stateSubKey: "languagesList",
  type: "list"
};

export const MobileOperatorList = {
  code: "MOBILE_OPERATOR",
  stateKey: "vas",
  stateSubKey: "vasDetails",
  type: "list"
};

export const ProductsByMobileOperatorList = {
  code: "PRODUCTS_BY_MOBILE_OPERATOR",
  stateKey: "vas",
  stateSubKey: "products",
  type: "list"
};
