import { combineReducers } from "redux";
import { ListReducerCreator, PaginationListReducerCreator } from "./creators";
import {
  RafflesList,
  MyActiveRafflesList
} from "../redux-config/listActionMap";
import {
  RafflesViewAll,
  RafflesActiveViewAll
} from "../redux-config/plistActionMap";

const raffles = combineReducers({
  [RafflesList.stateSubKey]: ListReducerCreator(RafflesList),
  [MyActiveRafflesList.stateSubKey]: ListReducerCreator(MyActiveRafflesList),
  [RafflesViewAll.stateSubKey]: PaginationListReducerCreator(RafflesViewAll),
  [RafflesActiveViewAll.stateSubKey]: PaginationListReducerCreator(
    RafflesActiveViewAll
  )
});

export default raffles;
