import { SOCKET_USERS_RECEIVE , SOCKET_ALL_ONLINE_USERS_LIST, REST_ALL_SOCKET_DATA } from "../types/socketusers";
import socket from "../socket";

export const searchProfilesRequest = (searchedWord = "") => (
  dispatch,
  getState
) => {
  let envId = getState().user.item.envId;
  let data = {
    type: "friends",
    searchString: searchedWord.toLowerCase(),
    event: "usersBySearchString request",
    envId: envId
  };

  socket.emit("chat", data);
};

export const updatePlayingChallengeRequest = (challengeId, profileId) => (
  dispatch,
  getState
) => {
  let data = {    
    event: "updatePlayingChallenge request",
    challengeId: challengeId,
    profileId: profileId
  };

  socket.emit("time-model", data);
};

export const endPlayingChallengeRequest = (challengeId, profileId, seconds, organicTime) => (
  dispatch,
  getState
) => {
  let data = {    
    event: "endPlayingChallenge request",
    challengeId: challengeId,
    profileId: profileId,
    seconds: seconds,
    organicTime: organicTime
  };

  socket.emit("time-model", data);
};

export const socketUsersReceived = searchedUsersList => (
  dispatch,
  getState
) => {
  dispatch({
    type: SOCKET_USERS_RECEIVE,
    payload: {
      users: searchedUsersList.res
    }
  });
};



export const resetSocketState = () =>({
  type: REST_ALL_SOCKET_DATA
})


export const socketAllOnlinUserList = allOnlineUserList => (
  dispatch,
  getState
) => {
  
  dispatch({
    type: SOCKET_ALL_ONLINE_USERS_LIST,
    payload: allOnlineUserList
  });
};
