import { SB_CLOSE_ALL, SB_OPEN_ANY } from "../../types/sidebars";

export const SidebarCloseAll = () => ({
  type: SB_CLOSE_ALL
});
export const SidebarOpenAny = () => ({
  type: SB_OPEN_ANY
});
export const SidebarActionCreator = params => ({
  open: (closeAll = true) => dispatch => {
    if (closeAll) {
      dispatch(SidebarCloseAll());
    }
    dispatch(SidebarOpenAny());
    dispatch({
      type: "SB_" + params.code + "_OPEN"
    });
  },
  close: (closeAll = true) => dispatch => {
    if (closeAll) {
      dispatch(SidebarCloseAll());
    } else {
      dispatch({
        type: "SB_" + params.code + "_CLOSE"
      });
    }
  }
});
