import { ADD_TOASTR,LIST_TOASTR,REMOVE_TOASTR } from "../types/toastr";

const generateKey = (pre) => {
  return `${ pre }_${ new Date().getTime() }`;
}

export const addToastr = (type, message,uniqueId) => ({
  type: ADD_TOASTR,
  payload: { type: type, message: message,uniqueId:uniqueId}
});


export const removeToastr = (index) => ({
  type: REMOVE_TOASTR,
  index: index 
});

export const setToastr = (type, message) => (dispatch, getState) => {
  let uniqueId = generateKey(type);
  dispatch(addToastr(type, message,uniqueId));
  setTimeout(() => {
    dispatch(removeToastr(uniqueId));
  },10000);
};



export const listToastr = () => ({
  type: LIST_TOASTR,
});