import { ListActionCreator, PaginationListActionCreator } from "./creators";
import { getRaffles, getMyActiveRaffles } from "../services/raffles.service";
import {
  RafflesList,
  MyActiveRafflesList
} from "../redux-config/listActionMap";
import { RafflesViewAll, RafflesActiveViewAll } from "../redux-config/plistActionMap";

export const rafflesList = ListActionCreator({
  ...RafflesList,
  service: getRaffles,
  dataMapper: json => json.data.data
});

export const myActiveRafflesList = ListActionCreator({
  ...MyActiveRafflesList,
  service: getMyActiveRaffles,
  dataMapper: json => json.data.data
});

export const rafflesViewAll = PaginationListActionCreator({
  ...RafflesViewAll,
  service: getRaffles,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const rafflesActiveViewAll = PaginationListActionCreator({
  ...RafflesActiveViewAll,
  service: getMyActiveRaffles,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});
