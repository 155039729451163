import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import navArrowDown from "./../../assets/images/svg/nav-arrow-down.svg";
import "./Select2Component.scss";

class Select2ComponentId extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      dropdownListVisible: false,
      name: ""
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.onSubmitRemove) {
      this.setState({ name: "" });
    }
  }

  handleChange(event, id) {
    let detail = {
      // id: event.currentTarget.value,
      id: id,
      text: event.currentTarget.textContent
    };

    this.props.onChange(detail);

    this.setState({
      dropdownListVisible: false
    });
    this.setState({
      name: detail.text
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  handleClick() {
    if (!this.state.dropdownListVisible) {
      // attach/remove event handler
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      dropdownListVisible: !prevState.dropdownListVisible
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.setState(prevState => ({
      dropdownListVisible: false
    }));
  }

  render() {
    return (
      <div className="select2">
        <div
          className={
            this.state.dropdownListVisible
              ? "dropdown dropdown--open"
              : "dropdown"
          }
          ref={node => {
            this.node = node;
          }}
        >
          <input
            type="text"
            className={this.props.selectClass}
            name="seen-value"
            placeholder={this.props.labelText}
            readOnly
            onClick={this.handleClick}
            value={this.props.selectedValue ? this.props.selectedValue : this.state.name}
            tabIndex={-1}
          />
          <img
            src={navArrowDown}
            className={
              this.state.dropdownListVisible
                ? "down-arrow-dropdown openArrow"
                : "down-arrow-dropdown"
            }
            alt=""
            onClick={this.handleClick}
          />
          <input
            type="hidden"
            className="form-control"
            name="hidden-value"
            readOnly
            tabIndex={-1}
          />
          <span className="focus-border" />
          <div className="dropdown__list">
            <p className="selection-heading">{this.props.labelText}</p>
            <ul className="list-unstyled">
              {this.props.selectOptions.map((msgTemplate, index) => {
                return (
                  <li
                    key={index}
                    data-value={msgTemplate.value}
                    value={msgTemplate.id}
                    onClick={e => this.handleChange(e, msgTemplate.id)}
                  >
                    {msgTemplate.value}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

Select2ComponentId.defaultProps = {
  labelText: "--Please Select--",
  selectClass: "form-control effect-16 select-2-input",
  onSubmitRemove: false
};

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Select2ComponentId)
);
