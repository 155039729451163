import { 
  ACTIVATE_USER_REQUEST,
  DEACTIVATE_USER_REQUEST

} from "../types/redirect";

const initialState = { 
  isUserActivated: false  
};

const redirect = (state = initialState, action) => {
  switch (action.type) {    
    case ACTIVATE_USER_REQUEST:
      return {
        ...state,
        isUserActivated: true        
      };
    case DEACTIVATE_USER_REQUEST:
      return {
        ...state,
        isUserActivated: false        
      };
    default:
      return state;
  }
};
export default redirect;
