import { ADD_TOASTR, REMOVE_TOASTR } from "../types/toastr";
const initialState = {
  toastrList: []
};

const toastr = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOASTR:
      return {
        ...state,
        toastrList: [...state.toastrList, action.payload]
      };

    case REMOVE_TOASTR:
      return {
        ...state,
        toastrList: state.toastrList.filter(
          item => action.index !== item.uniqueId
        )
      };
    default:
      return state;
  }
};

export default toastr;
