import { SidebarActionCreator, SidebarCloseAll } from "./creators";
import {
  LeftSidebar,
  ProfileSidebar,
  ProfileEditSidebar,
  FriendListSidebar,
  MessageListSidebar,
  ChatListSidebar,
  UserListSidebar,
  NotificationListSidebar
} from "../redux-config/sidebarActionMap";

export const leftSidebar = SidebarActionCreator(LeftSidebar);
export const profileSidebar = SidebarActionCreator(ProfileSidebar);
export const profileEditSidebar = SidebarActionCreator(ProfileEditSidebar);
export const friendListSidebar = SidebarActionCreator(FriendListSidebar);
export const messageListSidebar = SidebarActionCreator(MessageListSidebar);
export const chatListSidebar = SidebarActionCreator(ChatListSidebar);
export const userListSidebar = SidebarActionCreator(UserListSidebar);
export const notificationListSidebar = SidebarActionCreator(NotificationListSidebar);
export const sidebarCloseAll = SidebarCloseAll;
