import { ToggleActionCreator } from "./creators";
import {
  PasswordEyeLogin,
  PasswordEyeRegister,
  PasswordEyeResetPass,
  PasswordEyeChangeOldPass,
  PasswordEyeChangePass,
  HyveLogin,
  HyveRegister,
  MyTournamenPlaying,
  GameFullScreen,OtherReason
} from "../redux-config/toggleActionMap";

export const togglePasswordEyeLogin = ToggleActionCreator(PasswordEyeLogin);
export const togglePasswordEyeRegister = ToggleActionCreator(
  PasswordEyeRegister
);
export const togglePasswordEyeReset = ToggleActionCreator(PasswordEyeResetPass);
export const togglePasswordEyeChangeOldPass = ToggleActionCreator(
  PasswordEyeChangeOldPass
);
export const togglePasswordEyeChangePass = ToggleActionCreator(
  PasswordEyeChangePass
);
export const toggleHyveLogin = ToggleActionCreator(HyveLogin);
export const toggleHyveRegister = ToggleActionCreator(HyveRegister);
export const toggleMyTournamenPlaying = ToggleActionCreator(MyTournamenPlaying);
export const toggleGameFullScreen = ToggleActionCreator(GameFullScreen);
export const toggleOtherReason = ToggleActionCreator(OtherReason);
