import React, { Component } from "react";
import { connect } from "react-redux";
import { quickChallengeJoin } from "../../../actions/modals";
import "./QuickChallengeJoinModal.scss";
import Modal from "../Modal";
import defaultGiftImage from "../../../assets/images/giftbox@3x.png";
import { joinChallengePasswordForm } from "../../../actions/forms";
import { isEmpty } from "../../../utils/misc";
import profilePic80 from "../../../assets/images/profile-pic-80.png";
import { withTranslation } from "react-i18next";
import platformParams from "../../../utils/platformParams";

class QuickChallengeJoinModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLandScape: false
    };
  }

  prepareTagClass(brandName) {
    // if(brandName != ""){
    //   return " gtmJoinTournament-" + brandName.split(' ').join('-');
    // }
    return " gtmJoinTournament-1";
  }
  
  addDefaultSrc(ev) {
    ev.target.src = platformParams['challengeDefaultBannerImage'];
  }

  render() {
    if (isEmpty(this.props.challengeDetail)) return null;

    const { challengeDetail, allOnlineUserList, t } = this.props;
    const {
      brandAvatar,
      bannerImage,
      challengeImageUrl,
      logoUrl,
      seatsTaken,
      seatsMaximum,
      challengePlacementRewards,
      organizerId
    } = challengeDetail;
    let itemsTitle = "";
    let pointsTitle = "";
    let title = "";
    let imageUrl = defaultGiftImage;

    let remainSeat = 0;
    if (seatsMaximum) {remainSeat = seatsMaximum - seatsTaken;}
    if (challengePlacementRewards !== undefined && challengePlacementRewards.length ) {
      if (challengePlacementRewards[0].itemDescription)
        itemsTitle = challengePlacementRewards[0].itemDescription;
      if (challengePlacementRewards[0].points)
        pointsTitle = challengePlacementRewards[0].points + t("tokens");
      if (challengePlacementRewards[0].imageUrl !== "") {
        imageUrl = challengePlacementRewards[0].itemUrl;
      }
      if (itemsTitle !== "" && pointsTitle !== "")
        title = itemsTitle + " + " + pointsTitle;
      else if (itemsTitle !== "") title = itemsTitle;
      else title = pointsTitle;
    }

    return (
      <>
        <Modal
          isOpen={this.props.isQuickChallengeJoinOpen}
          showCloseBtn={false}
          disableOuterClick={true}
          modalClass="modal Arcadex-play"
        >
          <img className="fpb-icon" src='https://d1gj5wdhbiovf3.cloudfront.net/images/fpb.png' alt='The FPB Icon showing the age rating for this game.'></img>
          <div className="card-popup ">
            <div className="row">
              <div className="bannerImg w-100">
                <img src={challengeImageUrl} className="w-100" alt="" onError={this.addDefaultSrc}/>
              </div>

              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-sm-12">
                    <div className=" d-flex justify-content-center align-items-center player-info">
                      <div className="avatar quick-figure">
                        <figure>
                          <img src={logoUrl ? logoUrl : profilePic80} alt="" />
                          {allOnlineUserList.includes(organizerId) ? (
                            <figcaption className="online-56" />
                          ) : null}
                        </figure>
                      </div>
                      <h6 className="ml-3">
                        <span className="bold">{seatsTaken}</span>{" "}
                        {t("challengePage.players")}{" "}
                        <span className="ml-2 bold">{remainSeat}</span>{" "}
                        {t("challengePage.seatsOpen")}
                      </h6>
                    </div>
                  </div>
                  <div className="col-sm-10 text-center mt-5 gift-wrapper">
                    <div className="gift-img">
                      <img src={imageUrl} alt="" />
                      <h6 className="bold mt-2">
                        {t("challengePage.grandPrize")} {title}
                      </h6>
                    </div>

                    {this.props.challengeDetail.hasPassword ? (
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          if (
                            !this.props.joinChallengePasswordForm.fields
                              .password.value
                          ) {
                            this.props.joinChallengePasswordFormSetError(
                              "joinChallengePasswordformStatus",
                              "Please enter password"
                            );
                            return false;
                          }
                          window.dataLayer &&  window.dataLayer.push({
                            'event': "gtmJoinTournament-Tournament" + this.props.challengeDetail.id,
                            'Click Classes': "Tournament" + this.props.challengeDetail.id,
                            'challengeId': this.props.challengeDetail.id,
                            'challengeTitle': this.props.challengeDetail.challengeTitle,
                            'brandId': this.props.challengeDetail.brandId,
                            'brandName': this.props.challengeDetail.brandName,
                          })

                          this.props.joinChallengePasswordFormSubmit({
                            password: this.props.joinChallengePasswordForm
                              .fields.password.value,
                            challengeId: this.props.challengeDetail.id
                          });
                        }}
                      >
                        <div className="form-group without-lable pt-custom-1 mb-custom-1">
                          <input
                            type="password"
                            className="form-control effect-1"
                            placeholder="Enter Password"
                            onChange={event => {
                              this.props.joinChallengePasswordFormUpdateField(
                                "password",
                                event.target.value
                              );
                            }}
                            value={
                              this.props.joinChallengePasswordForm.fields
                                .password.value
                            }
                          />
                          <span className="focus-border" />
                        </div>

                        <button
                          disabled={
                            this.props.joinChallengePasswordForm.isSubmitting
                              ? true
                              : false
                          }
                          type="submit"
                          className={"btn btn-"+ platformParams['baseButtonClassSufix'] +" light btn-block mt-2 mt-sm-5"}
                        >
                          {t("challengePage.join")}
                        </button>
                      </form>
                    ) : (
                        <button
                          disabled={
                            this.props.joinChallengePasswordForm.isSubmitting ||
                              this.props.challengeDetail.hasAlreadyJoined
                              ? true
                              : false
                          }
                          type="button"
                          className={
                            "btn btn-"+ platformParams['baseButtonClassSufix'] +" light btn-block mt-2 mt-sm-5" +
                            this.prepareTagClass(
                              this.props.challengeDetail.brandName
                            )
                          }
                          onClick={e => {
                            e.preventDefault();
                            window.dataLayer &&  window.dataLayer.push({
                              'event': "gtmJoinTournament-Tournament" + this.props.challengeDetail.id,
                              'Click Classes': "Tournament" + this.props.challengeDetail.id,
                              'challengeId': this.props.challengeDetail.id,
                              'challengeTitle': this.props.challengeDetail.challengeTitle,
                              'brandId': this.props.challengeDetail.brandId,
                              'brandName': this.props.challengeDetail.brandName,
                            })
                            this.props.joinChallengePasswordFormSubmit({
                              password: "",
                              challengeId: this.props.challengeDetail.id
                            });
                          }}
                        >
                          {t("challengePage.join")}
                        </button>
                      )}

                    <span
                      className="a btn btn-link my-4 text-white bold cancel-btn"
                      onClick={e => {
                        e.preventDefault();
                        this.props.quickChallengeJoinClose();
                      }}
                    >
                      {t("challengePage.cancel")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  quickChallengeJoinOpen: quickChallengeJoin.open,
  quickChallengeJoinClose: quickChallengeJoin.close,
  joinChallengePasswordFormReset: joinChallengePasswordForm.reset,
  joinChallengePasswordFormSubmit: joinChallengePasswordForm.submit,
  joinChallengePasswordFormUpdateField: joinChallengePasswordForm.updateField,
  joinChallengePasswordFormSetError: joinChallengePasswordForm.setError,
};

const mapStateToProps = state => {
  return {
    isQuickChallengeJoinOpen: state.modals.isQuickChallengeJoinOpen,
    challengeDetail: state.challenges.challengeDetails.item,
    userDetail: state.user.item,
    joinChallengePasswordForm: state.forms.joinChallengePasswordForm,
    allOnlineUserList: state.socketusers.allOnlineUserList
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(QuickChallengeJoinModal));
