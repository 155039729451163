import { onNotificationsResponse,onNotificationReceived } from "../actions/notification";
import { socketUsersReceived,socketAllOnlinUserList } from "../actions/socketusers";
import { messageHistoryResponse } from "../actions/messagehistory";
import { chatHistoryResponse,messageReceived } from "../actions/chathistory";

export const socketOnMap = [
  {
    name: "usersBySearchString response",
    action: socketUsersReceived
  },
  {
    name: "notifications response",
    action: onNotificationsResponse
  },
  {
    name: "notification received",
    action: onNotificationReceived
  },
  {
    name: "allOnlineUserList",
    action: socketAllOnlinUserList,
  },
  {
    name: "message-history-response",
    action: messageHistoryResponse
  },
  {
    name: "chat-history-response",
    action: chatHistoryResponse
  },
  {
    name: "message-received",
    action: messageReceived
  },
];
