import React, { Component } from "react";
import "./TermsConditionsComponent.scss";
import platformParams from "../../utils/platformParams";
import PlayToWinTerms from "../../assets/pdfs/playtowin_terms.pdf";

class TermsContent extends Component {
  render() {
    return (
      <>
        <section className={this.props.setclass}>
          <div className={this.props.setclass!=="container-fluid"?"padder-main":""}>
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center ">
                  <div className="downloads-container">
                    <a href={PlayToWinTerms} target="_blank" rel="noopener noreferrer" className="download-btn">R250K Promo T&#38;C</a>
                  </div>
                  <div className="col-sm-12">
                    <h6
                      className={
                        " bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Terms and Conditions (Terms of Service)
                    </h6>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    1 Campaign:
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Arena R1,200,000  (one million two hundred thousand rand) Promotion (the <span className="text-black bold">“Competition”</span>).
                      This Competition is organized by Streamplay Solutions (Proprietary) Limited
                      <span className="text-black bold"> (“Streamplay”)</span> and MTN (the
                      <span className="text-black bold"> “Promoter/s” </span>).The number, value and distribution of Prizes is as follows: 
                      </li>
                      <table className="table privacy-table-border table-responsive">
                        <thead>
                          <tr className={platformParams['privacyTableBgColor']} >
                            <th scope="col" className={" text-white"}></th>
                            <th scope="col" className={" text-white"}>Annual Prize Distribution</th>
                            <th scope="col" className={" text-white"}>Annual Number of Prizes </th>
                            <th scope="col" className={" text-white"}>1st Prize Ranked </th>
                            <th scope="col" className={" text-white"}>2nd Prize Ranked </th>
                            <th scope="col" className={" tex t-white"}>3rd Prize Ranked </th>
                            <th scope="col" className={"text-white"}>Average Monthly Prize Value</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>Daily Tournament</td>
                          <td>R 205,500</td>
                          <td>411</td>
                          <td>R 500</td>
                          <td></td>
                          <td></td>
                          <td>R 17,125</td>
                        </tr>
                        <tr>
                          <td>Weekly Tournament</td>
                          <td>R 338,000</td>
                          <td>156</td>
                          <td>R 5,000</td>
                          <td>R 1,000</td>
                          <td>R 500</td>
                          <td>R 28,167</td>
                        </tr>
                        <tr>
                          <td>Weekend Tournament</td>
                          <td>R 169,000</td>
                          <td>52</td>
                          <td>R 2,500</td>
                          <td>R 500</td>
                          <td>R 250</td>
                          <td>R 14,083</td>
                        </tr>
                        <tr>
                          <td>Weekly Promotional</td>
                          <td>R 130,000</td>
                          <td>52</td>
                          <td>R 2,500</td>
                          <td></td>
                          <td></td>
                          <td>R 10,833</td>
                        </tr>
                        <tr>
                          <td>MTN Monthly Leadboard</td>
                          <td>R 357,500</td>
                          <td>39</td>
                          <td>R 20,000</td>
                          <td>R 5,000</td>
                          <td>R 2,500</td>
                          <td>R 29,792</td>
                        </tr>
                        <tr className={platformParams['privacyTableBgColor']}>
                          <td scope="col" className={" text-white"}>Monthly Prize  Allocation</td>
                          <td scope="col" className={" text-white"}>R 1,200,000</td>
                          <td scope="col" className={" text-white"}>710</td>
                          <td scope="col" className={" text-white"}></td>
                          <td scope="col" className={" text-white"}></td>
                          <td scope="col" className={" text-white"}></td>
                          <td scope="col" className={" text-white"}>R 100,000</td>
                        </tr>

                        </tbody>
                      </table>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    2 Campaign Period:
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      The Competition commences on 0:01 on 1 June 2020 and will continue until
                      23:59 on 30 May 2021 (the
                      <span className="text-black bold"> “Competition Period" </span>).
                      </li>
                    </ul>  
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    3 Who May Enter:
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                          <span className="mr-2">3.1</span>To be eligible to enter, the entrant must:
                      </li>
                      <li className="mb-2 ml-4">
                       <span className="mr-2">3.1.1</span>
                        be an MTN subscriber (PayAsYouGo, TopUp or Contract);
                      </li>
                      <li className="mb-2 ml-4">
                       <span className="mr-2">3.1.2</span>
                        be a South African citizen;
                      </li>
                      <li className="mb-2 ml-4">
                       <span className="mr-2">3.1.3</span>
                        subscribe to Arena;
                      </li>
                      <li className="mb-2 ml-4">
                       <span className="mr-2">3.1.4</span>
                        be a natural person; and
                      </li>
                      <li className="mb-2 ml-4">
                       <span className="mr-2">3.1.5</span>
                         be 18 years or older.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">3.2</span>
                          Participation in this Competition excludes employees, directors, members,
                          partners, consultants and agents of, or any other person who, directly or
                          indirectly controls or is controlled by the Promoter/s or marketing service
                          providers of this Competition, (and the spouses, life partners, immediate family
                          members or business partners of the people or entities listed above. This means:
                      </li>
                      <li className="mb-2 ml-4">
                       <span className="mr-2">3.2.1</span>
                        the Promoter/s;
                      </li>
                      <li className="mb-2 ml-4">
                       <span className="mr-2">3.2.2</span>
                        supplier/s of goods and or services in terms of this Competition; and
                      </li>
                      <li className="mb-2 ml-4">
                       <span className="mr-2">3.2.3</span>
                       promotional partners, printers, advertising and promotional agencies,
                       professional advisors and point of sale staff employed by or contracted to, or
                       providing goods or services of any kind, to all the people or entities listed above
                       during the Competition Period.
                      </li>
                    </ul> 
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    4 How to Enter:
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                    <li className="mb-2">
                          <span className="mr-2">4.1</span>
                          During the Competition Period, the entrants that meet all of the criteria
                          referred to in clause 3 above must do the following in order to stand a chance to
                          win: must:
                      </li>
                      <li className="mb-2 ml-4">
                       <span className="mr-2">4.1.1</span>
                       Subscribers must subscribe by:
                      </li>
                      <li className="mb-2 ml-5">
                       <span className="mr-2">4.1.1.1</span>Going onto 
                       {" "}
                       <a href="https://arena.mtn.co.za" style={{
                              color: "#0024a7",
                              textDecoration: "underline"
                             }}
                          >
                            https://arena.mtn.co.za
                          </a> {" "} and selecting 
                          <span className="text-black bold"> “Join now”</span>.
                       and be successfully billed at least 7 times for Arena during each month of
                       the competition, to be eligible for the tournament that user has entered.
                       The Arena subscription costs R5 per day
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">4.2</span>
                          Once the qualifying entrant has fulfilled the requirements of clauses 3 and 4.1
                          above, he or she will be automatically entered into the raffle
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">4.3</span>
                          Winners will be notified telephonically or my email by the Promoter/s and/or
                          its authorized agents on or as soon as possible after the draw.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">4.4</span>
                          Entrant’s acknowledge and accept that the Promoter/s shall utilize a third
                          party (the Promoters’ authorized agent/s) to contact the entrant, in the event that
                          the entrant is a winner, and arrange delivery of the Prize. In order to effect the
                          contacting and delivery process, the Promoters shall provide the entrant’s
                          information to such third party.
                      </li> 
                    </ul>   
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                     5 The Prize:
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                          <span className="mr-2">5.1</span>
                          Entrants that fulfill the requirements stated in clause 3 and 4 above, stand a
                          chance to win a share of R1,200,000 (referred to as the <span className="text-black bold"> “Prize”</span>), see clause 1 for Prize distribution.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">5.2</span>
                          The Prize shall be paid directly into the Winners MTN Mobile Money account. 
                          The Winners are solely responsible for registering an MTN Mobile Money 
                          account in order to receive their Prizes.  If an MTN Mobile Money account cannot
                          be provided by a Winner within 1 month of a Prize being won, then the Winner
                          forfeits the Prize.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">5.3</span>
                          At Streamplay’s sole discretion, it may elect to settle any Prize won by a Winner
                          by substituting such MTN Mobile Money with a Takealot.com online voucher,
                          competition promoted hardware products or an EFT into Winner’s confirmed
                          bank account for the same Prize value. 
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                     6 General:
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                          <span className="mr-2">6.1</span>
                          The Promoter/s, its respective directors, affiliates, members, partners,
                          employees, agents, consultants, suppliers, contractors, subsidiaries and
                          sponsors assume no liability whatsoever for any direct or indirect loss or
                          damage, including but not limited to physical harm or death, howsoever arising
                          from or as a result of an entrant’s participation in the Competition or the entrant’s
                          redemption of any Prize in terms of this Competition, or from any amendments to
                          Prize details and/or the terms and conditions of this Competition. All entrants
                          (including the winner) hereby expressly indemnify the Promoter/s in this regard
                          and shall hold it harmless from all and any claims arising from or as a result of
                          the above reasons.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.2</span>
                          In so far as it is necessary, required by law or beyond the reasonable control
                          of the Promoter/s, the Promoter/s reserves the right to vary the nature of the
                          Competition, the Prize, or these terms and conditions upon notice in this regard
                          being published on
                          {" "}<a href="https://arena.mtn.co.za" style={{
                              color: "#0024a7",
                              textDecoration: "underline"
                             }}
                          >
                            https://arena.mtn.co.za
                          </a> {" "}  or in another appropriate medium. In
                          the event that the Prize is limited or varied in this manner, the Promoter/s shall
                          replace the Prize with a prize of a similar economic value.
                         .
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.3</span>
                          With regard to clause 6.2 above, any amended terms and conditions
                          published in any media, or on the Arena website
                           {" "} <a href="https://arena.mtn.co.za" style={{
                              color: "#0024a7",
                              textDecoration: "underline"
                             }}
                          >
                            https://arena.mtn.co.za
                          </a> {" "} will
                          form part of the terms and conditions of the Competition, to which terms the
                          entrants agree to be bound.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.4</span>
                          Winners shall be contacted on the cell phone number/via the medium used to
                          enter the Competition or in terms of any contact details supplied by the winner
                          when entering the Competition. The Promoter/s shall attempt to contact the
                          winner for a period of 1 (one) month after his or her name is drawn as a winner.
                          In the event that the winner is either uncontactable (during the time frames 
                          stipulated in this clause 6.4) or in the event that the winner rejects, or declines
                          acceptance of the Prize, the Prize shall be subject to the next runner up.
                          Further, should contact have been made by the Promoter with the winner after
                          they have won a Prize and the winner is thereafter uncontactable for the
                          purposes of delivery of the prize. The Promoter/s shall attempt to contact the
                          winner for a period of 1 (one) week and if the winner is uncontactable, the Prize
                          will be subject to the next runner up.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.5</span>
                          The Prize is not transferable and is not exchangeable for another prize.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.6</span>
                          The decision of the Promoter in respect of disputes arising out of this
                          Competition shall be dealt with by the Promoter in terms of these terms and
                          conditions. The decision of the Promoter in this regard shall be final.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.7</span>
                          Winners are only entitled to win 1 (one) Prize each week.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.8</span>
                          The Promoter/s reserve the right to withhold the Prize until it is entirely
                          satisfied that the claimant of the Prize is the bona fide winner and reserves the
                          right to call for such proof as it may deem necessary.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.9</span>
                          The Promoter/s shall request that winners consent in writing to their name,
                          image and likeness being used and published by the Promoter/s in connection
                          with this Competition for a period of 12 (twelve) months after they are announced
                          as winners. Winners may decline the use of their name, likeness and image by
                          the Promoter/s.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.10</span>
                          By entering this Competition entrants signify their consent to be bound by
                          the terms and conditions contained herein. If a winner is under the age of 18
                          years (a <span className="text-black bold">"minor"</span>), the winner’s guardian or parent will be required to assist the
                          minor with all and any necessary documents or agreements in connection with
                          being able to take up any of the Prize/s in this Competition.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.11</span>
                          By entering the Competition all entrants give their consent to receive various
                          marketing and promotional material from the Promoter/s. Entrants will be
                          provided with an opportunity to <span className="text-black bold">“Opt Out”</span> of receiving such communications,
                          which may be via the relevant medium that such marketing communication was
                          received.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.12</span>
                          Winners agree that it is an express condition of the Competition that in order
                          to be eligible to redeem the Prize, the winner shall be required to sign the
                          appropriate acknowledgement of receipt of the Prize, as well as an indemnity
                          and/or waiver of liability as reflected in these terms.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.13</span>
                          Entrants acknowledge and accept that Prizes will be transferred
                          electronically into their designated bank account via EFT at the end of each
                          month.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.14</span>
                          The winning entrant that wins a share of the R1,200,000 agrees that he / she
                          is not eligible to WIN another prize in any draws within a 12 month period. 
                          The share of the prize is published and is determined at the sole discretion of
                          Streamplay and MTN, which can be changed from time to time.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    7 Arena TERMS OF USE:
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        SUBSCRIPTION SERVICE: 18+ ONLY. R5/DAY
                        <br></br>
                        NOTICE: The Arena service is provided subject to these terms and
                        conditions on 
                        {" "}
                       <a href="https://arena.mtn.co.za" style={{
                              color: "#0024a7",
                              textDecoration: "underline"
                             }}
                          >
                            https://arena.mtn.co.za
                          </a> {" "}  which explain how you may access and
                        use the service, and the Privacy Statement, 
                        {" "}
                       <a href="https://arena.mtn.co.za" style={{
                              color: "#0024a7",
                              textDecoration: "underline"
                             }}
                          >
                            https://arena.mtn.co.za
                          </a> {" "} which
                        explains how we process and use your personal information (the <span className="text-black bold">“Agreement”</span>).
                        IF YOU ARE UNDER 18 OR IF YOU ARE NOT WILLING TO BE BOUND BY
                        THIS AGREEMENT THEN DO NOT ACCESS THE SERVICE OR PROMPTLY
                        CEASE USING THE SERVICE ON YOUR MOBILE DEVICE.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    8 Arena CONTENT AND PRICING:
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Arena is an online platform through which we offer access to interactive
                      flash games with some of the hottest mobile content available, plus an extensive
                      amount of new content and categories added on a regular basis (the 
                      <span className="text-black bold"> “Content”</span>).
                      Being a member of Arena gives you access to this wide and varied
                      selection of Content[CM2].<br></br>
                      Arena is a mobile subscription service charged at R5 per day which offers
                      you unlimited access to the Content available while you remain subscribed (the
                        <span className="text-black bold"> “Service”</span>).
                      The daily charge will be recovered directly from your network service
                      provider and you will, in turn, pay your network service provider. You authorize
                      us to recover the relevant amounts directly from your network service provider
                      who you authorize to pay us. You confirm that you are allowed to provide the
                      authorizations given above. Network charges may also apply[CM3].
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                   9 CANCELLATION:
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                       You are free to cancel your subscription at any time by following the instructions
                       below:To unsubscribe dial *155# to follow the prompts and manage the cancellation of subscriptions.<br></br>
                       We do not provide any monetary refunds for any credits on your account.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                   10 MARKETING COMMUNICATIONS:
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      By entering into this Agreement, you consent to receive future marketing
                      communications from us and our affiliates, including by way of electronic
                      communication. You represent that you are not on any Do Not Call / Do Not
                      Disturb / Opt-out registries which would preclude us from sending you these
                      communications. All communications will abide by our Privacy Statement and
                      applicable law. You can opt-out of receiving these communications at any time
                      by following the opt-out instructions below or in each message sent to you[CM4].
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      1 INTRODUCTION
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                       The Service is operated by Streamplay Solutions (Proprietary) Limited,
                       which is a limited liability company registered in Australia - ACN 616 572 144,
                       trading here as ‘Streamplay PTY’ (the
                        <span className="text-black bold"> “Company“</span> ,
                        <span className="text-black bold">"we"</span>,
                        <span className="text-black bold">"us"</span>, 
                        <span className="text-black bold">“our“</span>).
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      2. EFFECT OF THIS AGREEMENT
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                       <li className="mb-2">
                          <span className="mr-2">2.1</span>
                          By consenting to this Agreement and/or by continuing to use the Service you
                          are bound by the entirety of this Agreement. In the case of any inconsistency
                          between any specific rules applicable to certain Content[CM5] and this
                          Agreement, this Agreement shall prevail.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">2.2</span>
                          We reserve the right to make any amendments to this Agreement, as we
                          deem necessary, in our sole discretion. By continuing to use the Service you are
                          accepting any changes to this Agreement.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">2.3</span>
                          Please note that to access or play certain Content, you may be required to
                          accept the terms and conditions of use specific to such Content. Any such terms
                          and conditions agreed to between you and the licensor of such Content, shall
                          apply in addition to this Agreement, and such terms of use shall not in any
                          manner limit, restrict, waive or substitute this Agreement.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    3. YOUR REPRESENTATIONS
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                       <li className="mb-2">
                          <span className="mr-2">3.1</span>
                          By seeking to register with us or by using the Service you hereby represent
                          and warrant to us that at all such times you:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">a)</span>
                        are located in South Africa;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">b)</span>
                        are aged 18 years or over;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">c)</span>
                        are of sound mind and capable of taking responsibility for your own actions;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">d)</span>
                        can enter into a legally binding agreement and you are the person who has
                        registered with us;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">e)</span>
                        understand that you may be charged for using the Service and that you accept
                        full responsibility for any such charges that may apply;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">f)</span>
                        are acting as principal and not on behalf of anyone else;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">g)</span>
                        are the authorized owner of the mobile device which you registered to the
                        Service during the registration process or any subsequent mobile device
                        registered on the Service.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">3.2</span>
                          You agree to abide in full by this Agreement.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">3.3</span>
                          You may only access the Content and/or use the Service by accessing:
                          {" "}<a href="https://arena.mtn.co.za" style={{
                              color: "#0024a7",
                              textDecoration: "underline"
                             }}
                          >
                            https://arena.mtn.co.za
                          </a> {" "} (the <span className="text-black bold">“Website”</span>). You are not allowed to access the
                          Service through any other medium.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">3.4</span>
                          You hereby warrant to us that: 
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">a)</span>
                        all information provided in your registration to the Service and all personal information provided to us is complete, 
                        accurate and not misleading;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">b)</span>
                        you will only use the Service strictly for legitimate purposes only; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">c)</span>
                        you will not attempt to hack, make unauthorized alterations to or introduce any kind of malicious code to the Website, 
                        the Service or the Company by any means. Charges and proceedings may be brought against you
                        if you try to manipulate the Service or any of the Content and we reserve the right
                        to pass on such information as we deem necessary to the relevant authorities if
                        we become aware or suspect that you are involved in any such activities. You
                        agree to any such disclosure.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    4. UPDATES
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      We may change at our sole discretion in whole or in part the format of the
                      Service or the Content that we offer in order to enhance, correct or support such
                      Services or Content or for any other reason.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    5. REGISTRATION
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                     <li className="mb-2">
                          <span className="mr-2">5.1</span>
                          Before you are able to access any of the Content or use the Service, you will
                          be required to register with us by completing the registration process on the
                          Website. You will be required to provide certain personal information. Please
                          refer to our Privacy Statement to see how we treat the personal information
                          collected from you. When registering, you must provide accurate and complete
                          information. You are responsible for keeping your account and password
                          confidential and for restricting access to your mobile device.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">5.2</span>
                          Following registration you will be provided with access to the Service, upon
                          being successfully billed by us via the mobile phone number that you registered
                          with. Transactions made using your mobile number are accepted by us strictly on
                          the understanding that you are using the Service. You agree to be solely
                          responsible for use of the Service through use of your mobile device at all times.
                          If an alternative source has accessed your mobile number we accept no liability
                          whatsoever including but not limited to any charges, information lost, stolen or
                          misused. If you believe that your mobile number is in any way being misused by
                          a third party please inform us immediately so that we may suspend the Service.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">5.3</span>
                          We reserve the right to verify your identity at any time (including by using
                          third parties, which may keep a record of that information). We reserve the right
                          to conduct checks against any of the details provided by you to us in your
                          registration. If upon our request you fail to provide evidence of proof of age or
                          other requested information this will result in the suspension of your registration
                          and/or the Service.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">5.4</span>
                          Upon completion of the registration we will confirm by SMS to the mobile
                          number which you provided that your registration is now complete.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">5.5</span>
                          We reserve the right to monitor the use of the Service and we may elect, in
                          our sole discretion to suspend and/or terminate the registration if we consider or
                          suspect that the Service or any parts thereof is being used in breach of this
                          Agreement or for any other reason that we deem necessary, without any notice
                          or liability.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">5.6</span>
                          You agree to return in the same condition or forthwith settle to us the cash
                          equivalent (if applicable, and calculated as at the date(s) of the prize(s) being
                          awarded) any and all prizes which may have been awarded to you under the
                          Service, in circumstances where we subsequently discover that you have
                          breached this Agreement (including without limitation where the mobile number
                          used to register to the Service, the use of the Service and/or the registration itself
                          is disputed).
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">5.7</span>
                          We reserve the right to record all telephone calls made to us and to monitor
                          all information relating to the Service for which purposes you consent including
                          forwarding on such calls to our authorized third parties[CM6].
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    6. COMPATIBILITY
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                          <span className="mr-2">6.1</span>
                          It is your responsibility to acquire and maintain, at your own expense, the
                          necessary mobile device, communication lines, data and Internet access
                          accounts required to access the Services. We do not guarantee that access to
                          the Services or the Content shall be error free.You may experience problems or
                          be unable to access or download certain Content successfully depending on the
                          mobile device used to access or download certain Content onto. Access via
                          streaming will be available for all smart devices. For feature devices which are
                          not compatible with streaming, downloads will be available. Non-android
                          smartphones (such as iOS devices and Windows devices) and possibly some
                          Android phones will only be able to access limited Content due to the type of
                          Operating systems on the mobile devices. This is an issue with the third-party
                          technology used in the provision of the Content which is not available on all
                          handsets and out of the Company’s control. If you have this type of issue that
                          limits your access to some or all of the Content and would like to raise a query or
                          ask for technical assistance, please email our customer support team at
                            {" "}  <a
                            href="mailto:mtnarena@arcadex.co"
                            style={{
                              color: "#0024a7",
                              textDecoration: "underline"
                            }}
                          >
                           mtnarena@arcadex.co </a>{" "}
                          You acknowledge that we are not responsible for any
                          costs or losses incurred by you, damage to your mobile device or loss of data
                          resulting from any such incompatibility, and that we are not responsible for any
                          costs that you incur while attempting to access the Services or the Content,
                          including payments towards internet and broadband access or any additional
                          expenses incurred by you (including charges imposed by your network service
                          provider for 3G/or roaming access).
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.2</span>
                          We make no representation as to the compatibility of your mobile device with
                          the Service and you acknowledge and agree that the Company shall have no
                          liability for the compatibility or non-compatibility of your mobile handset with the
                          Service and/or the Content.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">6.3</span>
                          We do not warrant that the Content shall function in accordance with the
                          rules of such Content. All images, videos and presentations used for the
                          promotion of the Content are merely representative and we do not warrant that
                          you will be able to experience/perform the Content in the same manner.
                      </li>
                      </ul>
                    
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    7. SECURITY POLICY
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                          <span className="mr-2">7.1</span>
                          Any information that you submit to us via the Website and Services, including
                          personal information, is subject to the terms of our Privacy Statement.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">7.2</span>
                          By using or accessing the Services, you
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">i)</span>
                          consent to the use of electronic communications in order to conclude contracts and communicate with the Company;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">ii)</span>
                        consent to the electronic delivery of notices, policies and records of transactions initiated or completed by you online; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">iii)</span>
                        acknowledge that you have access to the necessary software and hardware to receive electronic
                        records from the Company in connection with transactions initiated or completed by you online.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">7.3</span>
                          We will not sell your personal details to third parties. However, we may pass
                          on your details to relevant authorities or regulators if we wish to investigate any
                          suspected or alleged illegal activity, fraud, harassment, or abuse of the Service in
                          any manner or to assist in the investigation of any suspected or illegal activity,
                          fraud or abuse of the Service or if we are required by law to do so.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    8. REWARDS
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                          <span className="mr-2">8.1</span>
                          We may offer from time to time Content which is free to access but which will
                          require full registration.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">8.1</span>
                          The rules of access to free Content will be displayed on the Website and may
                          be accessible to non-registered members.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    9. COMPLAINTS AND DISPUTES
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                          <span className="mr-2">9.1</span>
                          Should you wish to make a complaint to us in respect of the Service or other
                          matter, please contact us by emailing 
                          {" "}  <a
                            href="mailto:mtnarena@arcadex.co"
                            style={{
                              color: "#0024a7",
                              textDecoration: "underline"
                            }}
                          >
                            mtnarena@arcadex.co</a> {" "} or by writing to
                          our address at the bottom of this Agreement. We will endeavor to assist you as
                          soon as reasonably possible.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">9.2</span>
                          The Company is a member of WASPA and is bound by the WASPA Code of
                          Conduct. Customers have the right to approach WASPA to lodge a complaint in
                          accordance with the WASPA complaints procedure. The Company may be
                          required to share information relating to the Service or a customer with WASPA
                          for the purpose of resolving a complaint. WASPA website:  {" "} 
                            <a href="www.waspa.org.za" style={{
                              color: "#0024a7",
                              textDecoration: "underline"
                             }}
                          >
                            www.waspa.org.za
                          </a> 
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    10. OUR LIABILITY
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                          <span className="mr-2">10.1</span>
                          The following provisions set out the entire financial liability of the Company
                          (including any liability for acts or omissions of its parent company, subsidiaries,
                          associated companies, directors, employees, agents and sub-contractors) to you
                          in respect of:
                      </li>
                      <li className="mb-2 ml-4">
                          <span className="mr-2">a)</span>
                          any breach of this Agreement; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">b)</span>
                        any representation,statement and/or act or omission including negligence 
                        arising under or in connection with the Service.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">10.2</span>
                          Nothing in these terms and conditions excludes or limits our liability:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">a)</span>
                        for death or personal injury caused by the Company’s negligence; or
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">b)</span>
                        for any matter which it would be illegal for the Company to exclude or attempt to exclude 
                        its liability for; or
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">c)</span>
                        for fraud or fraudulent misrepresentation.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">10.3</span>
                          We are not liable for any loss or damage that you may suffer because of
                          any act of God, power cut, trade or labour dispute, failure or any omission of any
                          government or authority; obstruction or failure of telecommunication services or
                          any other delay or failure caused by a third party or which is outside of our
                          control. In such an event, we reserve the right to cancel or suspend the Services
                          in whole or part without incurring any liability.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">10.4</span>
                          All representations, warranties and terms (whether express or implied) not
                          set out in this Agreement are to the fullest extent permitted by law, excluded. We
                          do not make any warranty that the Services or the Content will meet your
                          requirements, or that the Services or the Content will be uninterrupted, timely,
                          secure, free from errors, interruption, loss, corruption, attack, viruses,
                          interference, hacking, or other security intrusion, or that defects, if any, will be
                          corrected. You agree to indemnify us in respect of any liability, damages, costs or
                          claims (save to the extent the same arise out of or in connection with the
                          Company’s breach of contract or its negligence) which we may suffer arising out
                          of or in connection with your BREACH OF THIS AGREEMENT OR your use of
                          the Services or otherwise arising out of or in connection with our services. Your
                          statutory rights as a consumer (if any) are not affected by this Agreement.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">10.5</span>
                          Subject to Clause 10.2,
                      </li>
                      <li className="mb-2 ml-4">
                          <span className="mr-2">a)</span>
                          our liability to you in contract, delict (including
                          negligence or breach of statutory duty), misrepresentation, restitution or
                          otherwise, arising in connection with the performance or contemplated
                          performance of the Service shall be limited to the aggregate of the sum paid for
                          the Service in question for the preceding 12 (twelve) months; 
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">b)</span>
                          we shall not be
                          liable to you for any SPECIAL, indirect or consequential loss (including without
                          limitation, loss of profit, loss of goodwill, loss of amenity and loss of contract) or
                          any claims for consequential compensation whatsoever (howsoever caused)
                          which arise out of or in connection with the Services whether or not we have
                          been made aware of it; 
                      </li>
                      <li className="mb-2 ml-4">
                          <span className="mr-2">c)</span>
                          we shall not be held liable or responsible for any
                          consequences that occur through your use of the Service where the
                          circumstances that caused such consequences were beyond our reasonable
                          control, including any loss or damage that has arisen through the Website, the
                          Content or the Service or its content, including delays or interruptions in
                          operation or transmission, loss or corruption of data, any person’s misuse of the
                          Service or any error or omission in content.
                      </li>
                      
                      <li className="mb-2">
                          <span className="mr-2">10.6</span>
                          We accept no responsibility and shall not be liable to you for the content of
                          or use by you of any information or services offered by third parties’ advertising
                          (including advertising by any referral companies) or otherwise posting information
                          via the Website (whether directly or via links to or from other sites or resources or
                          through framing or other electronic mechanisms), nor can we be said to endorse
                          the contents of such advertisements or information. In particular, we shall have
                          no liability in respect of material hyperlinked to its web pages which may be
                          misleading, inaccurate, defamatory, threatening or obscene or otherwise not in
                          accordance with applicable laws or regulations. The provision by us on the
                          Website of a link to another website does not constitute any authorization to
                          access materials, nor any accreditation of any such materials held at that location.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">10.7</span>
                          We make no representation or warranty about the information or any other
                          items able to be accessed either directly or indirectly via the Website and/or
                          Service (save to the extent expressly provided on the Website or Service) and
                          we reserve the right to make changes and corrections at any time to such
                          information, without notice. We accept no liability for any inaccuracies or
                          omissions (other than a fraudulent misrepresentation) in or from such information
                          and any decisions based on such information are the sole responsibility of the
                          visitor to the Website.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">10.8</span>
                          We are not liable for any failure to perform by a third party to this Agreement.
                      </li>
                      
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      11. INTELLECTUAL PROPERTY
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                    <li className="mb-2">
                          <span className="mr-2">11.1</span>
                          The copyright, database rights and other intellectual property Rights
                          (<span className="text-black bold">“IPR”</span>)
                          in material displayed on or via the Service (the <span className="text-black bold"> “Materials“</span>
                          , which expression
                          includes text, data, graphics, photographs, videos, animation, images and
                          audiovisual content, are owned by or licensed to us or are the ownership of third
                          party websites). The IPR is protected by the laws of South Africa, international
                          treaties and all other applicable copyright and intellectual property rights laws.
                          You are not authorized to copy or distribute any Materials and/or IPR and legal
                          action could be taken against you or any such person who makes unauthorized
                          copies or distribution of Materials and/or IPR.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">11.2</span>
                          Any downloading, use or copying of the Materials is strictly prohibited and,
                          in particular, you agree to use the Materials solely for your own personal, non-
                          commercial use and specifically not for any business, commercial or public purposes.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">11.3</span>
                          You may not use the Website:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">a)</span>
                        to deploy within the Website any spider, robot, web crawler, scraper or other automated query program;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">b)</span>
                        to re-use and/or aggregate any of the Materials in the provision of a commercial service;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">c)</span>
                        for any harmful or illegal purpose;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">d)</span>
                        for disclosing, sharing or publishing material that may be offensive,
                        defamatory, abusive, regulated, copyrighted, incorrect, untrue, prohibited,
                        infringing, pornographic, obscene, indecent, unconstitutional or damaging to any person;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">e)</span>
                        for the creation, storage and sending of unsolicited commercial
                        communications, for example spam, chain letters, or pyramid schemes;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">f)</span>
                        to infringe any third-party right, whether personal or proprietary;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">g)</span>
                        to distribute viruses or any other technologies that may harm the Company or
                        the interests or property of other Website users;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">h)</span>
                        to impose an unreasonable load on the Company’s infrastructure or interfere
                        with the proper working of the Company;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">i)</span>
                        to copy, modify, or distribute any other person's content without their consent;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">j)</span>
                        to harvest or otherwise collect information about others, including email
                        addresses, without their consent or otherwise violate the privacy of another
                        person; or
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">k)</span>
                        to bypass measures used to prevent or restrict access to the Website.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">11.4</span>
                          The copying and use of third party Materials accessed via the Website is
                          governed by the terms of use applicable to the third party website accessed by you.
                      </li>
                      <li className="mb-2">
                          <span className="mr-2">11.5</span>
                          Our names and associated logos are our exclusive trademarks and cannot
                          be used by you without our prior written permission.
                      </li> 
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    12. SUBMISSION OF INFORMATION
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        Save for personal information (which shall be dealt with in accordance with our
                        Privacy Statement), all information (including but not limited to ideas,
                        suggestions, concepts and graphics) submitted to us or other users through the
                        Service will become our exclusive property and we shall not be subject to any
                        obligation of confidentiality and be free to use such information for any purpose
                        without any restriction or consideration whatsoever.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    13. GENERAL
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                          <span className="mr-2">13.1</span>
                          This Agreement constitutes to the fullest extent permitted by law the whole
                          of the Agreement between you and us with regard to the use of the Website, Content and the Service.
                      </li> 
                      <li className="mb-2">
                          <span className="mr-2">13.2</span>
                          If any part of this Agreement is found by a court of competent jurisdiction or
                          other competent authority to be invalid, unlawful or unenforceable then any such
                          part will be severed from the remainder of this Agreement, which will continue to
                          be valid and enforceable to the fullest extent permitted by law.
                      </li> 
                      <li className="mb-2">
                          <span className="mr-2">13.3</span>
                          No failure or delay by us to exercise any of our rights under this Agreement
                          shall operate as a waiver thereof and no single or partial exercise of any such
                          right shall prevent any other or further exercise of that or any other right by us.
                      </li> 
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    14. GOVERNING LAW AND DISPUTES
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        This Agreement shall be governed by and construed in accordance with the laws
                        of the Republic of South Africa. You irrevocably agree that the courts of the
                        Republic of South Africa shall have exclusive jurisdiction to resolve any dispute
                        or claim of whatever nature arising out of or relating to the Website and the
                        Service, and that the laws of the Republic of South Africa shall govern any such
                        dispute or claim. However, we retain the right to bring legal proceedings in any
                        jurisdiction where we believe that infringement of our intellectual property rights
                        or breach of this Agreement is taking place or originating. You are responsible for
                        compliance with any applicable laws and regulations of the jurisdiction from
                        which you are accessing or using the Website, the Service, its Contents whether
                        in whole or part.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                    Customer Service
                    </h6>
                    <p className="text-black mb-4 bold">
                        If you have any questions concerning this Agreement please contact:
                    </p>
                    <p className="text-muted mb-4">
                     Customer Services : Streamplay Limited, 3rd Floor, Wembley Square, 
                        40 Solan Road, Gardens, Cape Town, 8005
                    </p>
                    <p className="text-muted mb-4">
                      Email{" : "}
                      <a
                        href="mailto:mtnarena@arcadex.co"
                        style={{
                          color: "#0024a7",
                          textDecoration: "underline"
                        }}
                      >
                      mtnarena@arcadex.co
                      </a> or Call 135 (MTN Contact Centre)
                    </p>
                    <p className="text-muted mb-4">
                      Dial : *155# to unsubscribe by following the prompts and manage the cancellation of subscriptions.
                    </p>
                    <p className="text-muted mb-4">Update: December 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default TermsContent;
