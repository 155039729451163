export const RafflesByProfile = {
  code: "RAFFLES_BY_PROFILE",
  stateKey: "raffles",
  stateSubKey: "rafflesByProfile"
};

export const ChallengeDetails = {
  code: "CHALLENGE_DETAIL",
  stateKey: "challenges",
  stateSubKey: "challengeDetails",
  type: "detail"
};

export const ArcadeGameDetail = {
  code: "ARCADE_GAME_DETAIL",
  stateKey: "challenges",
  stateSubKey: "arcadeGameDetail",
  type: "detail"
};

export const FriendsProfile = {
  code: "FRIENDS_PROFILE",
  stateKey: "profiles",
  stateSubKey: "profileOfFriend",
  type: "detail"
};
export const BrandDetails = {
  code: "BRAND_DETAIL",
  stateKey: "brands",
  stateSubKey: "brandDetails",
  type: "detail"
};
export const ClaimChallengeDetails = {
  code: "CLAIM_CHALLENGE_DETAILS",
  stateKey: "challenges",
  stateSubKey: "claimChallengeDetails"
};
export const NewsDetails = {
  code: "NEWS_DETAIL",
  stateKey: "news",
  stateSubKey: "newsDetails",
  type: "detail"
};