import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const fileUpload = (files, callbackFunc, returnObj = {}) => {
  let data = new FormData();
  for (var i = 0; i < files.length; i++) {
    let file = files[i];
    data.append("files[]", file, file.name);
  }

  const axiosConfig = {
    headers: { "content-type": "multipart/form-data" },
    onUploadProgress: callbackFunc
  };

  return new Promise(function(resolve, reject) {
    axios
      .post(API_SERVER_URL + config.upload, data, axiosConfig)
      .then(res => {
        if (res.data.success && res.data.data.url) {
          resolve(res.data.data.url);
        } else {
          reject();
        }
      })
      .catch(err => {
        reject();
      });
  });
};
