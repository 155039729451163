import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getMyTournaments = (postParams) => {
  return axios
    .request({
      url: API_SERVER_URL + config.mytournaments,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getProfileEnteredTournaments = (postParams) => {
  return axios
    .request({
      url: API_SERVER_URL + config.enteredTournamentsByProfile,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};


export const getMyTournamentsEndedOfTwoMonth = (postParams) => {
  
  return axios
  .request({
    url: API_SERVER_URL + config.mytournamentsEndedOfTwoMonth,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  })
  .catch(err => {
    console.log(err);
  });
};