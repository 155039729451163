import React, { Component } from "react";
import { connect } from "react-redux";
import { createTutorial } from "../../../../actions/modals";
import { createTutorialForm } from "../../../../actions/forms";

import Modal from "../../Modal";
import "../cards/CreateTutorialCardComponent.scss";
import CreateTutorialCardComponent from "../cards/CreateTutorialCardComponent";
import OnBoard1 from "../../../../assets/images/onboarding-1.png";
import OnBoard2 from "../../../../assets/images/onboarding-2.png";
import OnBoard3 from "../../../../assets/images/onboarding-3.png";
import OnBoard4 from "../../../../assets/images/onboarding-4.png";
import SwiperContainer from "../../../Slider/SwiperContainer";
import { SHOWN_PROFILES } from "../../../../types/tutorials";
import { getContainerLeftWidth, isNgCountry } from "../../../../utils/misc";
import { withTranslation } from "react-i18next";
import platformParams from "../../../../utils/platformParams";

class CreateTutorialCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calcSize: 0,
      nextBtnLabel: false
    };
    this.onSlideChange = this.onSlideChange.bind(this);
    this.showCreateTutorial = this.showCreateTutorial.bind(this);
    this.hideCreateTutorial = this.hideCreateTutorial.bind(this);
    this.modalFooter = this.modalFooter.bind(this);
  }
  onSlideChange() {
    const { t } = this.props;
    if (this.swiper && this.swiper.swiper && this.swiper.swiper.swiper) {
      let slideLength = this.swiper.swiper.swiper.slides.length;
      slideLength = slideLength - 1;
      if (this.swiper.swiper.swiper.activeIndex < slideLength) {
        this.nextLabel.textContent = t("next");
        this.nextLabel.onclick = e => {};
      } else {
        this.nextLabel.textContent = t("done");
        this.nextLabel.onclick = e => {
          e.preventDefault();

          if (!this.props.createTutorialForm.isSubmitting) {
            this.props.createTutorialFormSubmit({
              shownTutorial: SHOWN_PROFILES
            });
          }
          return false;
        };
      }
    }
    return false;
  }
  showCreateTutorial() {
    this.props.createTutorialOpen();
  }
  hideCreateTutorial() {
    this.props.createTutorialClose();
  }
  componentDidMount() {
    this.setState((state, props) => {
      return {
        calcSize: getContainerLeftWidth()
      };
    });
  }

  modalFooter() {
    const { t } = this.props;
    return (
      <div className={"modal-footer " + platformParams['className']}>
        <div className="col d-flex justify-content-start align-items-center">
          <div className="prev-btn">{t("  ")}</div>
        </div>
        <div className="col d-flex justify-content-center align-items-center">
          <div className="onboarding-pagination" />
        </div>
        <div className="col d-flex justify-content-end align-items-center">
          <div className="next-btn">
            <span ref={ele => (this.nextLabel = ele)}>{t("next")}</span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const params = {
      fixDrag: true,
      slidesPerView: 1,
      spaceBetween: 30,
      touchRatio: 1,
      loop: false,
      pagination: {
        el: ".onboarding-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".next-btn",
        prevEl: ".prev-btn"
      },
      centeredSlides: false,
      slideExtraClass: "tutorial",
      breakpoints: {
        768: {
          touchRatio: 1
        },
        320: {
          slidesPerView: 1,
          touchRatio: 1,
          loop: false,
          centerInsufficientSlides: true,
          setWrapperSize: true,
          touchEventsTarget: "swiper-slide"
        }
      },
      on: {
        slideChange: this.onSlideChange
      }
    };
    const { t } = this.props;
    let playMobidata = [];
    if( isNgCountry() ){
      playMobidata = [
        {
          id: 1,
          figImg: this.props.userAvatar,
          figcaption: t("welcome") + this.props.userName + "!",
          figdescription: t("getInQuickTutorial")
        }
      ];
    }else{
      playMobidata = [
        {
          id: 1,
          figImg: this.props.userAvatar,
          figcaption: t("welcome") + this.props.userName + "!",
          figdescription: t("getInQuickTutorial")
        },
        {
          id: 2,
          figImg: OnBoard2,
          figcaption: t("playInFree"),
          figdescription: t("winGreatPrizeDaily")
        },
        {
          id: 3,
          figImg: OnBoard3,
          figcaption: t("greatPrize"),
          figdescription: t("winGreatPrizeDaily")
        },
        {
          id: 4,
          figImg: OnBoard4,
          figcaption: t("earnTicket"),
          figdescription: t("everyTimePlay")
        }
      ];
    }
    

    return (
      <>
        <Modal
          handleHide={this.hideCreateTutorial}
          isOpen={this.props.isCreateTutorialOpen}
          withFooter={true}
          disableOuterClick={true}
          footer={this.modalFooter()}
          closeBtnType="skip"
          modalCustomClass = "create-tutorial"

        >
          <SwiperContainer
            {...params}
            ref={swiper => {
              this.swiper = swiper;
            }}
            shouldSlideTo={false}
          >
            {playMobidata.map(function(card, index) {
              return (
                <CreateTutorialCardComponent
                  key={index}
                  keyIndex={index}
                  {...card}
                />
              );
            })}
          </SwiperContainer>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  createTutorialOpen: createTutorial.open,
  createTutorialClose: createTutorial.close,
  createTutorialFormSubmit: createTutorialForm.submit
};

const mapStateToProps = state => {
  return {
    isCreateTutorialOpen: state.modals.isCreateTutorialOpen,
    userId: state.user.item.id,
    userName: state.user.item.name,
    userAvatar:
      state.user.item.avatarUrl &&
      state.user.item.avatarUrl != "get_random_avatar()"
        ? state.user.item.avatarUrl
        : OnBoard1,
    createTutorialForm: state.forms.createTutorialForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateTutorialCardContainer));
