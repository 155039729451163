import { incrementSubmit, decrementSubmit } from "../loaders";

const setError = (params, field, errorMsg, errorPayload = null) => dispatch => {
  dispatch({
    type: "FORM_" + params.code + "_SET_ERROR/" + field,
    field,
    msg: errorMsg,
    payload: errorPayload
  });
};
const setSuccess = (
  params,
  field,
  successMsg,
  successPayload = null
) => dispatch => {
  dispatch({
    type: "FORM_" + params.code + "_SET_SUCCESS/" + field,
    field,
    msg: successMsg,
    payload: successPayload
  });
};
const updateField = (params, field, value) => dispatch => {
  return dispatch({
    type: "FORM_" + params.code + "_UPDATE_FIELD/" + field,
    field,
    value
  });
};
const resetField = (params, field) => dispatch => {
  dispatch({
    type: "FORM_" + params.code + "_RESET/" + field,
    field
  });
};
const reset = params => dispatch => {
  dispatch({
    type: "FORM_" + params.code + "_RESET"
  });
};
const resetError = (params, field) => dispatch => {
  dispatch({
    type: "FORM_" + params.code + "_RESET_ERROR/" + field,
    field
  });
};
const resetSuccess = (params, field) => dispatch => {
  dispatch({
    type: "FORM_" + params.code + "_RESET_SUCCESS/" + field,
    field
  });
};
const submit = (params, ...extraArgs) => {
  return (dispatch, getState) => {
    dispatch({
      type: "FORM_" + params.code + "_REQUEST"
    });
    dispatch(incrementSubmit());
    return params
      .service(...extraArgs, dispatch, getState)
      .then(json => {
        dispatch({
          type: "FORM_" + params.code + "_RESPONSE"
        });
        dispatch(decrementSubmit());
        params.onResponse(
          json,
          {
            setError,
            setSuccess,
            updateField,
            resetField,
            reset,
            resetError,
            resetSuccess
          },
          dispatch,
          getState
        );
      })
      .catch(err => {
        dispatch({
          type: "FORM_" + params.code + "_RESPONSE"
        });
        dispatch(decrementSubmit());
        params.onError(err, setError, setSuccess, dispatch, getState);
      });
  };
};

export default params => ({
  submit: (...extraArgs) => submit(params, ...extraArgs),
  reset: () => reset(params),
  resetField: field => resetField(params, field),
  updateField: (field, value) => updateField(params, field, value),
  setError: (field, errorMsg, errorPayload = null) =>
    setError(params, field, errorMsg, errorPayload),
  setSuccess: (field, successMsg, successPayload = null) =>
    setSuccess(params, field, successMsg, successPayload),
  resetError: field => resetError(params, field),
  resetSuccess: field => resetSuccess(params, field)
});
