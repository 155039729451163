import React, { Component } from "react";
import { connect } from "react-redux";
import { endGameAnimateModal } from "../../../actions/modals";
import "./EndGameAnimateModal.scss";
import Modal from "../Modal";
import goldTokenImage from "../../../assets/images/gold-tokens.png";
import highScoreImage from "../../../assets/images/high-score.png";
import { ordinalSuffix, findWithAttr, isEmpty } from "../../../utils/misc";
import { toggleGameFullScreen } from "../../../actions/toggles";
import platformParams from "../../../utils/platformParams";
// import gsap, { TimelineLite } from 'gsap/all';
// import { Tween } from "gsap/gsap-core";
// import {TweenMax, Linear} from 'gsap';

class EndGameAnimateModal extends Component {
  constructor(props) {
    super(props);
    this.hideEndGameAnimateModal = this.hideEndGameAnimateModal.bind(this);
    this.state = {
      isLandScape: false
    };
    this.trofy = null;

    this.animation1 = null;
    this.animation2 = null;
    this.animation3 = null;
    this.yourScoreCounter = null;
  }
// your-score
  componentDidMount(){
		let Cont={val:0} , NewVal = 100 ;
		let that = this;
	  	let animationId = parseInt(this.props.animationId);
		let timelineLiteObj =  new window.TimelineLite();
		if(timelineLiteObj){
			switch (animationId) {
			case 1:
				/* scaling ( fixed potition ) */
				this.animation1 =  new window.TimelineLite({ delay: 1 })
				.set(".trophy-img", { scale: 0, opacity: 0 })
				.to(".trophy-img",1, { scale: 1, ease: "elastic.out(1, 0.3)", opacity: 1}, "l1")
				.to(Cont, 1,{val:NewVal,roundProps:"val",onUpdate:function(){
					that.yourScoreCounter.innerHTML=Cont.val;
				  }}, "l1");
				break;
			case 2:
				/** Top to bottom (smoothly) */
				this.animation2 =  new window.TimelineLite({ delay: 1})
				.from(".trophy-img", { duration: 1, x: 0, y: -40, scale: 1, opacity: 0, ease: "bounce.out"}, "l1")
				.to(Cont, 1, {val:NewVal,roundProps:"val",onUpdate:function(){
					that.yourScoreCounter.innerHTML=Cont.val;
				  }}, "l1");
				break;
			case 3:
				/*** Scaling from Full screen image to original size */
				this.animation3 =  new window.TimelineLite({ delay: 1 ,scale: 10})
				.from(".trophy-img", { duration: 0.5, x: 0, y: -40, scale: 14,  opacity: 0}, "l1")
				.to(Cont, 0.5, {val:NewVal,roundProps:"val",onUpdate:function(){
					that.yourScoreCounter.innerHTML=Cont.val;
				  }}, "l1");
				break;		
			}
		}
	 
		// TweenLite.to(Cont,5,{val:NewVal,roundProps:"val",onUpdate:function(){
		// 	document.getElementById("counter").innerHTML=Cont.val
		//   }});
  }

  hideEndGameAnimateModal() {
    this.props.endGameAnimateModalClose();
  }

  render() {

    let highScore = 1000;
    let finishGameChallengeFormScore = 0;
    let challengeDetailsCurrentUserLeaderBoardRank = 1;

    return (
      <>
        <Modal
          handleHide={this.hideEndGameAnimateModal}
          isOpen={this.props.isEndGameAnimateModalOpen}
          disableOuterClick={true}
          modalClass="modal score-card"
        >
          <div className="card-popup ">
            <div className="row">
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-sm-10 text-center mt-3 gift-wrapper">
                    <h6 className="bold mb-5">You Scored</h6>
                    <div className="gift-img">
                      <img src={platformParams['trophy3x']} className="trophy-img" alt="" ref = { img => this.trofy = img}/>
                    </div>
                    <h1 className="display-3 my-4 bold text-danger your-score" ref={ yourScoreCounter => this.yourScoreCounter = yourScoreCounter }>
                      {finishGameChallengeFormScore}
                    </h1>
                    <div className="row">
                      <div className="col gold-token  br-1">
                        <img src={goldTokenImage} alt="" className="mb-2" />
                        <h4
                          className="bold mb-1"
                          dangerouslySetInnerHTML={{
                            __html: ordinalSuffix(
                                challengeDetailsCurrentUserLeaderBoardRank,
                              true
                            )
                          }}
                        />
                        <p className="fs-12 earned-token mb-1 semi-bold">
                          Placed
                        </p>
                      </div>
                      <div className="col gold-token ">
                        <img src={highScoreImage} alt="" className="mb-2" />
                        <h4 className="bold mb-1">{highScore}</h4>
                        <p className="fs-12 earned-token mb-1 semi-bold">
                          High Score
                        </p>
                      </div>
                    </div>
                    <span
                      className={"a btn btn-" + platformParams['baseButtonClassSufix'] + " btn-block light mt-5 text-white"}
                      onClick={e => {
                        e.preventDefault();
                        this.props.gameFullScreenReset();
                        this.props.gameFullScreenToggle();
                        this.props.endGameAnimateModalClose();
                      }}
                    >
                      Play Again
                    </span>

                    <span
                      className="a btn btn-link my-4 text-dark bold cancel-btn"
                      onClick={e => {
                        e.preventDefault();
                        this.props.endGameAnimateModalClose();
                      }}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  endGameAnimateModalClose: endGameAnimateModal.close,
  gameFullScreenReset: toggleGameFullScreen.reset,
  gameFullScreenToggle: toggleGameFullScreen.toggle
};

const mapStateToProps = state => {
  return {
    isEndGameAnimateModalOpen: state.modals.isEndGameAnimateModalOpen,
    finishGameChallengeForm: state.forms.finishGameChallengeForm,
    challengeDetails: state.challenges.challengeDetails.item || null,
    ChallengesLeaderboardsViewAll: state.challenges.ChallengesLeaderboardsViewAll.items || null,
    userData: state.user.item
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndGameAnimateModal);
