export default params => ({
  toggle: () => dispatch => {
    dispatch({
      type: "TOGGLE_" + params.code
    });
  },
  reset: () => dispatch => {
    dispatch({
      type: "TOGGLE_RESET_" + params.code
    });
  }
});
