import { combineReducers } from "redux";
import { SidebarReducerCreator } from "./creators";
import {
  LeftSidebar,
  ProfileSidebar,
  ProfileEditSidebar,
  FriendListSidebar,
  MessageListSidebar,
  ChatListSidebar,
  UserListSidebar,
  NotificationListSidebar
} from "../redux-config/sidebarActionMap";
import { SB_OPEN_ANY, SB_CLOSE_ALL } from "../types/sidebars";

const sidebars = combineReducers({
  [LeftSidebar.stateModalKey]: SidebarReducerCreator(LeftSidebar),
  [ProfileSidebar.stateModalKey]: SidebarReducerCreator(ProfileSidebar),
  [ProfileEditSidebar.stateModalKey]: SidebarReducerCreator(ProfileEditSidebar),
  [FriendListSidebar.stateModalKey]: SidebarReducerCreator(FriendListSidebar),
  [MessageListSidebar.stateModalKey]: SidebarReducerCreator(MessageListSidebar),
  [ChatListSidebar.stateModalKey]: SidebarReducerCreator(ChatListSidebar),
  [UserListSidebar.stateModalKey]: SidebarReducerCreator(UserListSidebar),
  [NotificationListSidebar.stateModalKey]: SidebarReducerCreator(NotificationListSidebar),
  isAnySidebarOpen: (state = false, action) => {
    switch (action.type) {
      case SB_OPEN_ANY:
        return true;
      case SB_CLOSE_ALL:
        return false;
      default:
        return state;
    }
  }
});

export default sidebars;
