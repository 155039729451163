import React, { Component } from "react";
import { connect } from "react-redux";
import { register, login } from "../../../actions/modals";
import { registerForm, registerHyveForm } from "../../../actions/forms";
import "./RegisterModal.scss";
import ModalV2 from "../ModalV2";

import {
  toggleHyveLogin,
  togglePasswordEyeRegister
} from "../../../actions/toggles";
import { APP_MESSAGES } from "../../../services/config/messages";
import {
  validateEmail,
  validateMsisdn,
  validatePassword
} from "../../../utils/validators";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";
import TermsContent from "./../../TermsConditions/TermsContent";
import PrivacyContent from "./../../PrivacyPolicy/PrivacyContent";
import { withTranslation } from "react-i18next";
import platformParams from "../../../utils/platformParams";

class RegisterModal extends Component {
  constructor(props) {
    super(props);
    this.showRegister = this.showRegister.bind(this);
    this.hideRegister = this.hideRegister.bind(this);
    this.state = { termsChecked: false, policyChecked: false };
    this.termRef = React.createRef();
    this.privacyRef = React.createRef();
  }
  showRegister() {
    this.props.registerOpen();
  }
  hideRegister() {
    this.setState({ termsChecked: false, policyChecked: false });
    this.props.registerFormReset();
    this.props.registerHyveFormReset();
    this.props.registerClose();
  }
  componentDidMount() {
    if (this.props.isRegisterType === "hyve") {
      this.props.registerHyveFormUpdateField("terms", false);
      this.props.registerHyveFormUpdateField("policy", false);
    } else {
      this.props.registerFormUpdateField("terms", false);
      this.props.registerFormUpdateField("policy", false);
    }
  }
  render() {
    let Error;
    let Success;
    if (this.props.isRegisterType !== "hyve") {
      Success = this.props.registerForm.fields.email.successMsg
        ? APP_MESSAGES[this.props.registerForm.fields.email.successMsg](
            this.props.registerForm.fields.email.successPayload,
            () => {
              this.props.registerFormResetSuccess("email");
            }
          )
        : props => <>{""}</>;

      Error = this.props.registerForm.fields.email.errorMsg
        ? APP_MESSAGES[this.props.registerForm.fields.email.errorMsg](
            this.props.registerForm.fields.email.errorPayload,
            () => {
              this.props.registerFormResetError("email");
            }
          )
        : props => <>{""}</>;
    } else {
      Success = this.props.registerHyveForm.fields.email.successMsg
        ? APP_MESSAGES[this.props.registerHyveForm.fields.email.successMsg](
            this.props.registerHyveForm.fields.email.successPayload,
            () => {
              this.props.registerHyveFormResetSuccess("email");
            }
          )
        : props => <>{""}</>;

      Error = this.props.registerHyveForm.fields.email.errorMsg
        ? APP_MESSAGES[this.props.registerHyveForm.fields.email.errorMsg](
            this.props.registerHyveForm.fields.email.errorPayload,
            () => {
              this.props.registerHyveFormResetError("email");
            }
          )
        : props => <>{""}</>;
    }
    const { t } = this.props;
    return (
      <>
        <ModalV2
          handleHide={this.hideRegister}
          isOpen={this.props.isRegisterOpen}
          modalClass="modal register popups registerwithterms"
          id="signup"
          withError={
            this.props.registerForm.fields.email.error ||
            this.props.registerHyveForm.fields.email.error ||
            this.props.registerForm.fields.email.success ||
            this.props.registerHyveForm.fields.email.success
          }
          showSocialBtns={false}
        >
          <div className="card-popup register-form">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="mb-3 bold">{t("singUpWith")}</h4>
              </div>
            </div>
            {/* <div className="row gutter-space">
              <div className="col pr-1">
                <span className="btn btn-outline-secondary social-login align-items-center justify-content-center  btn-block">
                  <span className="social-icon facebook mr-2">
                    <img src={IconFacebook} alt="" />
                  </span>{" "}
                  facebook
                </span>
              </div>
              <div className="col pl-1">
                <span className="btn btn-outline-secondary social-login d-flex align-items-center justify-content-center  btn-block">
                  <span className="social-icon google mr-2">
                    <img src={IconGoogle} alt="" />
                  </span>{" "}
                  Google
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 mt-4 mb-0 mt-sm-5 mb-sm-3 devider d-flex justify-content-center">
                <div className="dropdown-divider w-100" />
                <span className="position-absolute mt-nagative-4">OR</span>
              </div>
            </div> */}
            <div className="row">
              <div className="col-sm-12">
                <Error />
                <Success />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (
                      !(
                        this.props.registerForm.isSubmitting ||
                        this.props.registerHyveForm.isSubmitting
                      )
                    ) {
                      let postParams;
                      let newMobileno;

                      if (this.props.isRegisterType === "hyve") {
                        if (
                          !validateMsisdn(
                            this.props.registerHyveForm.fields.phone.value
                          )
                        ) {
                          this.props.registerHyveFormSetError(
                            "email",
                            "MOBILE"
                          );
                          return false;
                        } else {
                          newMobileno = validateMsisdn(
                            this.props.registerHyveForm.fields.phone.value
                          );
                        }

                        if (
                          this.props.registerHyveForm.fields.email.value ===
                            "" ||
                          this.props.registerHyveForm.fields.password.value ===
                            ""
                        ) {
                          this.props.registerHyveFormSetError(
                            "email",
                            "NOT_EMPTY"
                          );
                          return false;
                        }

                        if (
                          !validatePassword(
                            this.props.registerHyveForm.fields.password.value
                          )
                        ) {
                          this.props.registerHyveFormSetError(
                            "email",
                            "PASSWORD_LENGTH"
                          );
                          return false;
                        }

                        if (!this.props.registerHyveForm.fields.terms.value) {
                          this.props.registerHyveFormSetError(
                            "email",
                            "TERMS_CHECK"
                          );
                          return false;
                        }

                        postParams = {
                          email: this.props.registerHyveForm.fields.email.value,
                          password: this.props.registerHyveForm.fields.password
                            .value,
                          msisdn: newMobileno,
                          temporaryUrl: window.location.origin,
                          type: ""
                        };
                        this.props.registerHyveFormSubmit(postParams);
                      } else {
                        let emailAddress = this.props.registerForm.fields.email
                          .value;
                        emailAddress = emailAddress.trim();

                        if (!emailAddress) {
                          this.props.registerFormSetError("email", "NOT_EMPTY");
                          return false;
                        }

                        if (!validateEmail(emailAddress)) {
                          this.props.registerFormSetError("email", "EMAIL");
                          return false;
                        }
                        if (
                          !validatePassword(
                            this.props.registerForm.fields.password.value
                          )
                        ) {
                          this.props.registerFormSetError(
                            "email",
                            "PASSWORD_LENGTH"
                          );
                          return false;
                        }

                        if (!this.props.registerForm.fields.terms.value) {
                          this.props.registerFormSetError(
                            "email",
                            "TERMS_CHECK"
                          );
                          return false;
                        }

                        postParams = {
                          email: emailAddress,
                          password: this.props.registerForm.fields.password
                            .value,
                          temporaryUrl: window.location.origin,
                          type: "ArcadeX",
                          msisdn: "",
                          countryId: 1
                        };
                        this.props.registerFormSubmit(postParams);
                      }
                    }
                  }}
                >
                  {this.props.isRegisterType === "hyve" ? (
                    <AppInputComponent
                      type="number"
                      labelText="Mobile no"
                      divClass="form-group with-lable mt-3"
                      maxLength={12}
                      value={
                        this.props.registerHyveForm.fields.phone.value
                          ? this.props.registerHyveForm.fields.phone.value
                          : ""
                      }
                      onChange={event => {
                        this.props.registerHyveFormResetError("email");
                        this.props.registerHyveFormUpdateField(
                          "phone",
                          event.target.value
                        );
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {this.state.termsChecked ? (
                    !this.state.policyChecked ? (
                      <div
                        ref={this.privacyRef}
                        id="privacyConditionDiv"
                        style={{
                          overflowY: "auto",
                          overflowX: "hidden",
                          height: "190px",
                          overflowScrolling: "touch",
                          WebkitOverflowScrolling: "touch"
                        }}
                      >
                        <PrivacyContent setclass="container-fluid" />
                      </div>
                    ) : (
                      <>
                        <AppInputComponent
                          divClass="form-group with-lable mt-5"
                          labelText={t("labelEmailAdd")}
                          value={
                            this.props.isRegisterType === "hyve"
                              ? this.props.registerHyveForm.fields.email.value
                              : this.props.registerForm.fields.email.value
                          }
                          onChange={event => {
                            if (this.props.isRegisterType === "hyve") {
                              this.props.registerHyveFormResetError("email");
                              this.props.registerHyveFormUpdateField(
                                "email",
                                event.target.value
                              );
                            } else {
                              this.props.registerFormResetError("email");
                              this.props.registerFormUpdateField(
                                "email",
                                event.target.value
                              );
                            }
                          }}
                        />

                        <AppInputComponent
                          autoComplete="true"
                          type={
                            this.props.isPasswordVisible ? "text" : "password"
                          }
                          labelText={t("labelPassword")}
                          divClass="form-group with-lable mt-5"
                          value={
                            this.props.isRegisterType === "hyve"
                              ? this.props.registerHyveForm.fields.password
                                  .value
                              : this.props.registerForm.fields.password.value
                          }
                          onChange={event => {
                            if (this.props.isRegisterType === "hyve") {
                              this.props.registerHyveFormUpdateField(
                                "password",
                                event.target.value
                              );
                            } else {
                              this.props.registerFormUpdateField(
                                "password",
                                event.target.value
                              );
                            }
                          }}
                          inputAddon={() => (
                            <span
                              toggle="#password-field"
                              className={
                                "field-icon toggle-password" +
                                (this.props.isPasswordVisible
                                  ? " eye-close"
                                  : " eye-open")
                              }
                              onClick={this.props.togglePasswordEyeRegister}
                            />
                          )}
                        />
                      </>
                    )
                  ) : (
                    <div
                      ref={this.termRef}
                      id="termConditionDiv"
                      style={{
                        overflowY: "auto",
                        overflowX: "hidden",
                        height: "190px",
                        overflowScrolling: "touch",
                        WebkitOverflowScrolling: "touch"
                      }}
                    >
                      <TermsContent setclass="container-fluid" />
                    </div>
                  )}

                  {!this.state.termsChecked ||
                  (this.state.termsChecked && this.state.policyChecked) ? (
                    <label className="custom-control fill-checkbox mt-4">
                      <input
                        type="checkbox"
                        className="fill-control-input"
                        onChange={e => {
                          if (this.props.isRegisterType === "hyve") {
                            if (
                              this.props.registerHyveForm.fields.terms.value ===
                              true
                            ) {
                              this.setState({
                                termsChecked: false,
                                policyChecked: false
                              });
                            }
                            this.props.registerHyveFormUpdateField(
                              "terms",
                              !this.props.registerHyveForm.fields.terms.value
                            );
                            this.props.registerHyveFormUpdateField(
                              "policy",
                              false
                            );
                          } else {
                            if (
                              this.props.registerForm.fields.terms.value ===
                              true
                            ) {
                              this.setState({
                                termsChecked: false,
                                policyChecked: false
                              });
                            }
                            this.props.registerFormResetError("email");
                            this.props.registerFormUpdateField(
                              "terms",
                              !this.props.registerForm.fields.terms.value
                            );
                            this.props.registerFormUpdateField("policy", false);
                          }
                        }}
                        checked={
                          this.props.isRegisterType === "hyve"
                            ? this.props.registerHyveForm.fields.terms.value
                            : this.props.registerForm.fields.terms.value
                        }
                      />
                      <span className="fill-control-indicator" />
                      <span className="fill-control-description">
                        {t("iAcceptThe")} &nbsp;
                        <a
                          className={"text-" + platformParams['colorClassSufix']
                          }
                          href="/terms-conditions"
                          target="_blank"
                        >
                          {t("termOfService")}
                        </a>
                      </span>
                    </label>
                  ) : (
                    <label className="custom-control fill-checkbox mt-4">
                      <input
                        type="checkbox"
                        className="fill-control-input"
                        onChange={e => {
                          if (this.props.isRegisterType === "hyve") {
                            if (
                              this.props.registerHyveForm.fields.policy
                                .value === true
                            ) {
                              this.setState({ policyChecked: false });
                            }
                            this.props.registerHyveFormUpdateField(
                              "policy",
                              !this.props.registerHyveForm.fields.policy.value
                            );
                          } else {
                            if (
                              this.props.registerForm.fields.policy.value ===
                              true
                            ) {
                              this.setState({ policyChecked: false });
                            }
                            this.props.registerFormUpdateField(
                              "policy",
                              !this.props.registerForm.fields.policy.value
                            );
                          }
                        }}
                        checked={
                          this.props.isRegisterType === "hyve"
                            ? this.props.registerHyveForm.fields.policy.value
                            : this.props.registerForm.fields.policy.value
                        }
                      />
                      <span className="fill-control-indicator" />
                      <span className="fill-control-description">
                        {t("iAcceptThe")} &nbsp;
                        <a
                          className={"text-" + platformParams['colorClassSufix']}
                          href="/privacy-policy"
                          target="_blank"
                        >
                          {t("privacyPolicy")}
                        </a>
                      </span>
                    </label>
                  )}

                  {this.state.termsChecked ? (
                    !this.state.policyChecked ? (
                      <button
                        type="button"
                        className={
                          "btn btn-pink dark btn-block mt-2 mt-sm-5" +
                          ((this.props.isRegisterType === "hyve"
                          ? this.props.registerHyveForm.fields.policy.value
                          : this.props.registerForm.fields.policy.value)
                            ? " disable"
                            : "")
                        }
                        disabled={
                          !(this.props.isRegisterType === "hyve"
                            ? this.props.registerHyveForm.fields.policy.value
                            : this.props.registerForm.fields.policy.value)
                        }
                        onClick={e => {
                          this.setState({ policyChecked: true });
                          e.preventDefault();
                          return false;
                        }}
                      >
                        {t("proceed")}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className={
                          "btn btn-pink dark btn-block mt-2 mt-sm-5" +
                          (this.props.registerForm.isSubmitting ||
                          this.props.registerHyveForm.isSubmitting
                            ? " disable"
                            : "")
                        }
                      >
                        {t("createAccount")}
                      </button>
                    )
                  ) : (
                    <button
                      type="button"
                      className={
                        "btn btn-pink dark btn-block mt-2 mt-sm-5" +
                        ((this.props.isRegisterType === "hyve"
                        ? this.props.registerHyveForm.fields.terms.value
                        : this.props.registerForm.fields.terms.value)
                          ? " disable"
                          : "")
                      }
                      disabled={
                        !(this.props.isRegisterType === "hyve"
                          ? this.props.registerHyveForm.fields.terms.value
                          : this.props.registerForm.fields.terms.value)
                      }
                      onClick={e => {
                        this.setState({ termsChecked: true });

                        setTimeout(
                          function() {
                            this.privacyRef.current.scrollTo(0, 0);
                          }.bind(this),
                          100
                        );

                        e.preventDefault();
                        return false;
                      }}
                    >
                      {t("proceed")}
                    </button>
                  )}
                </form>
              </div>
            </div>
            <div className="cross-div">
              <div className="row">
                <div className="col-sm-12 text-center mt-2 mt-sm-4">
                  <button
                    className="btn btn-link text-white creat-account"
                    onClick={() => {
                      if (this.termRef.current) {
                        this.termRef.current.scrollTo(0, 0);
                      }
                      if (this.privacyRef.current) {
                        this.privacyRef.current.scrollTo(0, 0);
                      }

                      this.setState({
                        termsChecked: false,
                        policyChecked: false
                      });
                      this.props.registerFormReset();
                      this.props.resetHyveLogin();
                      if (this.props.isRegisterType === "hyve") {
                        this.props.toggleHyveLogin();
                      }
                      this.props.loginOpen();
                    }}
                  >
                    {t("orSignIn")}
                  </button>
                </div>
              </div>
              <div className="row privacy-policy">
                <div className="col-sm-12 text-center mt-2 pb-3 pb-sm-0 mt-sm-5">
                  <small className="text-white">
                    <a
                      className="text-white"
                      href="/privacy-policy"
                      target="_blank"
                    >
                      {t("privacyPolicy")}
                    </a>
                  </small>
                </div>
              </div>
              <div className="cross-div-bg" />
            </div>
          </div>
        </ModalV2>
      </>
    );
  }
}

const mapDispatchToProps = {
  registerOpen: register.open,
  registerClose: register.close,
  loginOpen: login.open,
  passwordHideShowIfNeeded: () => {},
  toggleHyveLogin: toggleHyveLogin.toggle,
  resetHyveLogin: toggleHyveLogin.reset,
  togglePasswordEyeRegister: togglePasswordEyeRegister.toggle,

  registerFormSubmit: registerForm.submit,
  registerFormReset: registerForm.reset,
  registerFormResetField: registerForm.resetField,
  registerFormUpdateField: registerForm.updateField,
  registerFormSetError: registerForm.setError,
  registerFormSetSuccess: registerForm.setSuccess,
  registerFormResetError: registerForm.resetError,
  registerFormResetSuccess: registerForm.resetSuccess,

  registerHyveFormSubmit: registerHyveForm.submit,
  registerHyveFormReset: registerHyveForm.reset,
  registerHyveFormResetField: registerHyveForm.resetField,
  registerHyveFormUpdateField: registerHyveForm.updateField,
  registerHyveFormSetError: registerHyveForm.setError,
  registerHyveFormSetSuccess: registerHyveForm.setSuccess,
  registerHyveFormResetError: registerHyveForm.resetError,
  registerHyveFormResetSuccess: registerHyveForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    isRegisterOpen: state.modals.isRegisterOpen,
    isRegisterType: state.toggles.hyveRegister ? "hyve" : "simple",
    isPasswordVisible: state.toggles.pwEyeRegister,
    registerForm: state.forms.registerForm,
    registerHyveForm: state.forms.registerHyveForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RegisterModal));
