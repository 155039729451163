import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getNewsViewAll = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getNewsListUrl,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const getNewsCategories = postParams => {
  let categoryId = postParams.categoryId;
  delete postParams.categoryId;
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.getNewsCategoriesList.replace("##id##", categoryId),
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getNewsDetails = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.newsDetails.replace("##id##", postParams.newsId),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};
export const getNewsListWithCategories = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.newsCategories,
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};
