import { combineReducers } from "redux";
import { FormReducerCreator, ListReducerCreator } from "./creators";

import {
  BillingInfoForm,
  NGBillingInfoForm,
  BillingRegistrationForm,
  BillingLoginForm,
  BillingForgotPasswordForm,
  BillingResetPasswordForm,
  NGAutoLoginForm,
  NGLoginForm,
  NGGetDOIUrlForm,
} from "../redux-config/formActionMap";
import { ProductsByMobileOperatorList } from "../redux-config/listActionMap";

const vas_blacklist = combineReducers({
  [BillingLoginForm.stateSubKey]: FormReducerCreator(BillingLoginForm),
  [BillingRegistrationForm.stateSubKey]: FormReducerCreator(
    BillingRegistrationForm
  ),
  [BillingInfoForm.stateSubKey]: FormReducerCreator(BillingInfoForm),
  [NGBillingInfoForm.stateSubKey]: FormReducerCreator(NGBillingInfoForm),
  [BillingForgotPasswordForm.stateSubKey]: FormReducerCreator(
    BillingForgotPasswordForm
  ),
  [BillingResetPasswordForm.stateSubKey]: FormReducerCreator(
    BillingResetPasswordForm
  ),
  [NGAutoLoginForm.stateSubKey]: FormReducerCreator(NGAutoLoginForm),
  [NGLoginForm.stateSubKey]: FormReducerCreator(NGLoginForm),
  [NGGetDOIUrlForm.stateSubKey]: FormReducerCreator(NGGetDOIUrlForm),
  [ProductsByMobileOperatorList.stateSubKey]: ListReducerCreator(ProductsByMobileOperatorList)
});

export default vas_blacklist;
