const initialStateForPaginationList = {
  isFetching: false,
  items: [],
  didInvalidate: true,
  isLastPage: false,
  receiveError: false,
  page: 1
};

export default params => (state = initialStateForPaginationList, action) => {
  switch (action.type) {
    case "PLIST_INVALIDATE_" + params.code:
      return {
        ...state,
        didInvalidate: true
      };
    case "PLIST_INVALIDATE_ALL_" + params.code:
      return initialStateForPaginationList;
    case "PLIST_REQUEST_" + params.code:
      return {
        ...state,
        isFetching: true
      };
    case "PLIST_RECEIVE_" + params.code:
      return {
        ...state,
        isFetching: false,
        items: [...state.items, ...action.data.items],
        didInvalidate: false,
        isLastPage: action.data.isLastPage,
        receiveError: false,
        page: state.page + 1
      };
    case "PLIST_RECEIVE_ALL_" + params.code:
      return {
        ...state,
        isFetching: false,
        items: [...action.data.items],
        didInvalidate: false,
        isLastPage: action.data.isLastPage,
        receiveError: false,
        page: 2
      };
    case "PLIST_RECEIVE_ERROR_" + params.code:
      return {
        ...initialStateForPaginationList,
        receiveError: true
      };
    default:
      return state;
  }
};
