import { combineReducers } from "redux";
import { ToggleReducerCreator } from "./creators";
import {
  PasswordEyeLogin,
  HyveLogin,
  HyveRegister,
  PasswordEyeRegister,
  PasswordEyeResetPass,
  PasswordEyeChangeOldPass,
  PasswordEyeChangePass,
  MyTournamenPlaying,
  GameFullScreen,OtherReason
} from "../redux-config/toggleActionMap";

const toggles = combineReducers({
  [PasswordEyeLogin.stateSubKey]: ToggleReducerCreator(PasswordEyeLogin),
  [PasswordEyeRegister.stateSubKey]: ToggleReducerCreator(PasswordEyeRegister),
  [PasswordEyeResetPass.stateSubKey]: ToggleReducerCreator(PasswordEyeResetPass),
  [PasswordEyeChangePass.stateSubKey]: ToggleReducerCreator(PasswordEyeChangePass),
  [PasswordEyeChangeOldPass.stateSubKey]: ToggleReducerCreator(PasswordEyeChangeOldPass),
  [HyveLogin.stateSubKey]: ToggleReducerCreator(HyveLogin),
  [HyveRegister.stateSubKey]: ToggleReducerCreator(HyveRegister),
  [MyTournamenPlaying.stateSubKey]: ToggleReducerCreator(MyTournamenPlaying),
  [GameFullScreen.stateSubKey]: ToggleReducerCreator(GameFullScreen),
  [OtherReason.stateSubKey]: ToggleReducerCreator(OtherReason)
});
export default toggles;
