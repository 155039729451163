import { SOCKET_USERS_RECEIVE,SOCKET_ALL_ONLINE_USERS_LIST , REST_ALL_SOCKET_DATA } from "../types/socketusers";

const initialState = {
  socketUsers: [],
  allOnlineUserList:[],
};

const socketusers = (state = initialState, action) => {
  switch (action.type) {

    case SOCKET_ALL_ONLINE_USERS_LIST:    
    return {
      ...state,
      allOnlineUserList:action.payload
    };

    case REST_ALL_SOCKET_DATA:    
    return {
      ...state,
      socketUsers:[],
      allOnlineUserList:[]
    };

    case SOCKET_USERS_RECEIVE:    
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
export default socketusers;
