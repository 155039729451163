import { combineReducers } from "redux";
import {
  ListReducerCreator,
} from "./creators";
import { FriendsList } from "../redux-config/listActionMap";

const friends = combineReducers({
  [FriendsList.stateSubKey]: ListReducerCreator(FriendsList)
});

export default friends;
