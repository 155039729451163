import { LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGIN_USER  , CHANGE_LANGUAGE } from "../types/login";
import { ListActionCreator } from "./creators";
import { modalCloseAll } from "./modals";
import { tutorialResetAll } from "./tutorials";

import { axiosInstance } from "../utils/axios";
import { sidebarCloseAll } from "./sidebars";
import { onStopSocket } from "./socket";
import { LOGIN_USER_DETAIL_UPDATE, LOGOUT_USER_DETAIL } from "../types/user";
import i18n from "../i18n";



export const changeLanguage = (lang , id) => dispatch => {
  dispatch({
    type: CHANGE_LANGUAGE,
    currentLang:lang,
    language : id
  });
};

export const loginSuccess = userdata => dispatch => {
  dispatch({
    type: LOGIN_USER_DETAIL_UPDATE,
    userdata
  });
  dispatch({
    type: LOGIN_USER,
    userdata
  });


};


export const updateUserDetail = userdata => dispatch => {
  dispatch({
    type: LOGIN_USER_DETAIL_UPDATE,
    userdata
  });
};




export const logoutRequest = () => ({
  type: LOGOUT_REQUEST
});
export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});


export const logoutUserDetail = () => ({
  type: LOGOUT_USER_DETAIL
});


const logoutUser = () => dispatch => {
  const lang = i18n.language;
  
  dispatch(logoutRequest());
  dispatch(logoutSuccess());
  dispatch(logoutUserDetail());
  dispatch(onStopSocket());
  var redirectInterval = setInterval(() => {
    if(localStorage.getItem("token")== null){
        clearInterval(redirectInterval);
        window.location = "/" + lang;
    }
  }, 100);
  return setTimeout(() => {
    clearInterval(redirectInterval);
  }, 1500);
};

const shouldLogout = () => {
  return true;
};

export const logoutUserIfNeeded = () => (dispatch, getState) => {
  if (shouldLogout(getState())) {
    axiosInstance.removeToken();
    return dispatch(logoutUser());
  }
};
