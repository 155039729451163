import {
  INCREMENT_FETCH,
  DECREMENT_FETCH,
  INCREMENT_SUBMIT,
  DECREMENT_SUBMIT
} from "../types/loaders";

const initialState = {
  fetchCount: 0,
  submitCount: 0
};

const loaders = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_FETCH:
      return {
        ...state,
        fetchCount: state.fetchCount + 1
      };
    case DECREMENT_FETCH:
      return {
        ...state,
        fetchCount: state.fetchCount - 1
      };
    case INCREMENT_SUBMIT:
      return {
        ...state,
        submitCount: state.submitCount + 1
      };
    case DECREMENT_SUBMIT:
      return {
        ...state,
        submitCount: state.submitCount - 1
      };
    default:
      return state;
  }
};
export default loaders;
