//dashboard
import React, { lazy } from 'react';
import MainLayout from '../layouts/Main/MainLayout';
import EmptyLayout from '../layouts/Empty/EmptyLayout';
import ContentLayout from "../layouts/Content/ContentLayout";
import GameLayout from "../layouts/GameLayout/GameLayout";

const  DashboardContainer = lazy(() => import("../containers/DashboardContainer"));
const  ChallengesLiveViewAllContainer = lazy(() => import("../containers/Challenges/ChallengesLiveViewAllContainer"));
const  ChallengesCategoryViewAllContainer = lazy(() => import("../containers/Challenges/ChallengesCategoryViewAllContainer"));
const  ChallengesBrandViewAllContainer = lazy(() => import("../containers/Challenges/ChallengesBrandViewAllContainer"));
const  ArcadeGamesByCategoryViewAllContainer = lazy(() => import("../containers/ArcadeGamesByCategoryViewAllContainer"));
const  ChallengesQuickPlayViewAllContainer = lazy(() => import("../containers/Challenges/ChallengesQuickPlayViewAllContainer"));
const  ChallengesUpcomingViewAllContainer = lazy(() => import("../containers/Challenges/ChallengesUpcomingViewAllContainer"));
const  PopularHostsViewAllContainer  = lazy(() => import("../containers/PopularHostsViewAllContainer"));
const  FreeToPlayGamesViewAllContainer = lazy(() => import("../containers/FreeToPlayGamesViewAllContainer"));
const  FreeGameDetailContainer = lazy(() => import("../containers/FreeGameDetailContainer"));
const  HelpSupportContainer= lazy(() => import("../containers/HelpSupportContainer"));
const  TermsConditionsContainer = lazy(() => import("../containers/TermsConditionsContainer"));
const  ContactUsContainer= lazy(() => import("../containers/ContactUsContainer"));
const  PrivacyPolicyContainer= lazy(() => import("../containers/PrivacyPolicyContainer"));
const  UserActivateContainer = lazy(() => import("../containers/UserActivateContainer"));
const  ResetPasswordContainer= lazy(() => import("../containers/ResetPasswordContainer"));
const  HomeContainer= lazy(() => import("../containers/HomeContainer"));
const  FriendsProfileContainer= lazy(() => import("../containers/FriendsProfile/FriendsProfileContainer"));
const  AboutUsContainer = lazy(() => import("../containers/AboutUsContainer"));
const  MyTournamentsContainer = lazy(() => import("../containers/Challenges/MyTournamentsContainer"));
const  RafflesContainer= lazy(() => import("../containers/Raffles/RafflesContainer"));
const  RafflesViewAllContainer= lazy(() => import("../containers/Raffles/RafflesViewAllContainer"));
const  RafflesActiveViewAllContainer= lazy(() => import("../containers/Raffles/RafflesActiveViewAllContainer"));
const  NotFoundContainer= lazy(() => import("../containers/NotFoundContainer"));
const  MobileGameContainer= lazy(() => import("../containers/MobileGameContainer"));
const  ChallengeContainer= lazy(() => import("../containers/Challenges/ChallengeContainer"));
//  const  MainLayout= lazy(() => import("../layouts/Main/MainLayout"));
//  const  GameLayout= lazy(() => import("../layouts/GameLayout/GameLayout"));
const  BrandContainer= lazy(() => import("../containers/Host/BrandContainer"));
//  const  ContentLayout= lazy(() => import("../layouts/Content/ContentLayout"));
const  ClaimContainer= lazy(() => import("../containers/ClaimContainer"));
const  NewsContainer= lazy(() => import("../containers/News/NewsContainer"));
const  NewsDetailsContainer= lazy(() => import("../containers/News/NewsDetailsContainer"));
const  FriendsChallengesViewAllContainer= lazy(() => import("../containers/FriendsProfile/FriendsChallengesViewAllContainer"));
const  FriendsFollowersViewAllContainer= lazy(() => import("../containers/FriendsProfile/FriendsFollowersViewAllContainer"));
const  FriendsFollowingsViewAllContainer= lazy(() => import("../containers/FriendsProfile/FriendsFollowingsViewAllContainer"));
const  HostChallengesLiveViewAllContainer= lazy(() => import("../containers/Host/HostChallengesLiveViewAllContainer"));
const  HostChallengesUpcomingViewAllContainer= lazy(() => import("../containers/Host/HostChallengesUpcomingViewAllContainer"));
const  HostChallengesQuickPlayViewAllContainer= lazy(() => import("../containers/Host/HostChallengesQuickPlayViewAllContainer"));
const  HostTopPlayersViewAllContainer= lazy(() => import("../containers/Host/HostTopPlayersViewAllContainer"));
const  SubscribeComponent= lazy(() => import("../components/Subscribe/SubscribeComponent"));
const  JoinNowComponent= lazy(() => import("../components/Subscribe/JoinNowComponent"));
const  BillingSubscriptionComponent= lazy(() => import("../components/Subscribe/BillingSubscriptionComponent"));
const  BillingRegistrationComponent= lazy(() => import("../components/Subscribe/BillingRegistrationComponent"));
const  BillingLoginComponent= lazy(() => import("../components/Subscribe/BillingLoginComponent"));
const  BillingInfoComponent= lazy(() => import("../components/Subscribe/BillingInfoComponent"));
//  const  EmptyLayout= lazy(() => import("../layouts/Empty/EmptyLayout"));
const  BillingResetPasswordComponent= lazy(() => import("../components/Subscribe/BillingResetPasswordComponent"));
const  BillingForgotPasswordComponent= lazy(() => import("../components/Subscribe/BillingForgotPasswordComponent"));
const  BillingHoltComponent= lazy(() => import("../components/Subscribe/BillingHoltComponent"));
const  MaintenanceContainer= lazy(() => import("../containers/MaintenanceContainer"));
const  AffiliateContainer = lazy(() => import("../containers/AffiliateContainer"));

const  AnimationComponent = lazy(() => import("../components/Challenge/AnimationComponent"));

// import DashboardContainer from "../containers/DashboardContainer";
// import ChallengesLiveViewAllContainer from "../containers/Challenges/ChallengesLiveViewAllContainer";
// import ChallengesCategoryViewAllContainer from "../containers/Challenges/ChallengesCategoryViewAllContainer";
// import ChallengesBrandViewAllContainer from "../containers/Challenges/ChallengesBrandViewAllContainer";
// import ArcadeGamesByCategoryViewAllContainer from "../containers/ArcadeGamesByCategoryViewAllContainer";
// import ChallengesQuickPlayViewAllContainer from "../containers/Challenges/ChallengesQuickPlayViewAllContainer";
// import ChallengesUpcomingViewAllContainer from "../containers/Challenges/ChallengesUpcomingViewAllContainer";
// import PopularHostsViewAllContainer from "../containers/PopularHostsViewAllContainer";
// import FreeToPlayGamesViewAllContainer from "../containers/FreeToPlayGamesViewAllContainer";
// import FreeGameDetailContainer from "../containers/FreeGameDetailContainer";
// import HelpSupportContainer from "../containers/HelpSupportContainer";
// import TermsConditionsContainer from "../containers/TermsConditionsContainer";
// import ContactUsContainer from "../containers/ContactUsContainer";
// import PrivacyPolicyContainer from "../containers/PrivacyPolicyContainer";
// import UserActivateContainer from "../containers/UserActivateContainer";
// import ResetPasswordContainer from "../containers/ResetPasswordContainer";
// import HomeContainer from "../containers/HomeContainer";
// import FriendsProfileContainer from "../containers/FriendsProfile/FriendsProfileContainer";
// import AboutUsContainer from "../containers/AboutUsContainer";
// import MyTournamentsContainer from "../containers/Challenges/MyTournamentsContainer";
// import RafflesContainer from "../containers/Raffles/RafflesContainer";
// import RafflesViewAllContainer from "../containers/Raffles/RafflesViewAllContainer";
// import RafflesActiveViewAllContainer from "../containers/Raffles/RafflesActiveViewAllContainer";
// import NotFoundContainer from "../containers/NotFoundContainer";
// import MobileGameContainer from "../containers/MobileGameContainer";
// import ChallengeContainer from "../containers/Challenges/ChallengeContainer";
// import MainLayout from "../layouts/Main/MainLayout";
// import GameLayout from "../layouts/GameLayout/GameLayout";
// import BrandContainer from "../containers/Host/BrandContainer";
// import ContentLayout from "../layouts/Content/ContentLayout";
// import ClaimContainer from "../containers/ClaimContainer";
// import NewsContainer from "../containers/News/NewsContainer";
// import NewsDetailsContainer from "../containers/News/NewsDetailsContainer";
// import FriendsChallengesViewAllContainer from "../containers/FriendsProfile/FriendsChallengesViewAllContainer";
// import FriendsFollowersViewAllContainer from "../containers/FriendsProfile/FriendsFollowersViewAllContainer";
// import FriendsFollowingsViewAllContainer from "../containers/FriendsProfile/FriendsFollowingsViewAllContainer";
// import HostChallengesLiveViewAllContainer from "../containers/Host/HostChallengesLiveViewAllContainer";
// import HostChallengesUpcomingViewAllContainer from "../containers/Host/HostChallengesUpcomingViewAllContainer";
// import HostChallengesQuickPlayViewAllContainer from "../containers/Host/HostChallengesQuickPlayViewAllContainer";
// import HostTopPlayersViewAllContainer from "../containers/Host/HostTopPlayersViewAllContainer";
// import SubscribeComponent from "../components/Subscribe/SubscribeComponent";
// import JoinNowComponent from "../components/Subscribe/JoinNowComponent";
// import BillingSubscriptionComponent from "../components/Subscribe/BillingSubscriptionComponent";
// import BillingRegistrationComponent from "../components/Subscribe/BillingRegistrationComponent";
// import BillingLoginComponent from "../components/Subscribe/BillingLoginComponent";
// import BillingInfoComponent from "../components/Subscribe/BillingInfoComponent";
// import EmptyLayout from "../layouts/Empty/EmptyLayout";
// import BillingResetPasswordComponent from "../components/Subscribe/BillingResetPasswordComponent";
// import BillingForgotPasswordComponent from "../components/Subscribe/BillingForgotPasswordComponent";
// import BillingHoltComponent from "../components/Subscribe/BillingHoltComponent";
// import MaintenanceContainer from "../containers/MaintenanceContainer";
// import AffiliateContainer from "../containers/AffiliateContainer";

let routes = [];
if (process.env.REACT_APP_MAINTENANCE_MODE !== "ON") {
  routes = [
    {
      layout: MainLayout,
      routes: [
        // {
        //   path: "/animation/:id?",
        //   exact: true,
        //   container: AnimationComponent,
        //   auth: false,
        //   allowAfterLogin: true
        // },
        {
          path: "/about-us",
          exact: true,
          container: AboutUsContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/a",
          exact: true,
          container: AffiliateContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/affiliate",
          exact: true,
          container: AffiliateContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/raffles",
          exact: true,
          container: RafflesContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/my-tournaments",
          exact: true,
          container: MyTournamentsContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/dashboard",
          exact: true,
          container: DashboardContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/challenge/:id",
          exact: true,
          container: ChallengeContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/",
          exact: true,
          container: HomeContainer,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/activate/:email/:hash",
          exact: true,
          container: UserActivateContainer,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/resetpassword/:email/:resetPasswordHash",
          exact: true,
          container: ResetPasswordContainer,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/challenges/live",
          exact: true,
          container: ChallengesLiveViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/challenges/quickplay",
          exact: true,
          container: ChallengesQuickPlayViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/challenges/upcoming",
          exact: true,
          container: ChallengesUpcomingViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/hosts/popular",
          exact: true,
          container: PopularHostsViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/freetoplay",
          exact: true,
          container: FreeToPlayGamesViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/news",
          exact: true,
          container: NewsContainer,
          auth: false,
          allowAfterLogin: true
        },
        // {
        //   path: "/news",
        //   exact: true,
        //   container: NewsViewAllContainer,
        //   auth: true,
        //   allowAfterLogin: true
        // },
        {
          path: "/news/:id",
          exact: true,
          container: NewsDetailsContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/challenges/category/:id/:description",
          exact: true,
          container: ChallengesCategoryViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/challenges/brand/:id/:description",
          exact: true,
          container: ChallengesBrandViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/category/:id/:description",
          exact: true,
          container: ArcadeGamesByCategoryViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/free-game-detail/:id",
          exact: true,
          container: FreeGameDetailContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/help-support",
          exact: true,
          container: HelpSupportContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/raffles/all",
          exact: true,
          container: RafflesViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/raffles/active/all",
          exact: true,
          container: RafflesActiveViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/terms-conditions",
          exact: true,
          container: TermsConditionsContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/contact-us",
          exact: true,
          container: ContactUsContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/privacy-policy",
          exact: true,
          container: PrivacyPolicyContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/profile/:id",
          exact: true,
          container: FriendsProfileContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/profile/:id/challenges",
          exact: true,
          container: FriendsChallengesViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/profile/:id/followers",
          exact: true,
          container: FriendsFollowersViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/profile/:id/followings",
          exact: true,
          container: FriendsFollowingsViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/host/:id",
          exact: true,
          container: BrandContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/host/:id/challenges/live",
          exact: true,
          container: HostChallengesLiveViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/host/:id/challenges/upcoming",
          exact: true,
          container: HostChallengesUpcomingViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/host/:id/challenges/quickPlay",
          exact: true,
          container: HostChallengesQuickPlayViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/host/:id/topPlayer",
          exact: true,
          container: HostTopPlayersViewAllContainer,
          auth: true,
          allowAfterLogin: true
        },
        {
          path: "/getprize/:id/:challengeId",
          exact: true,
          container: ClaimContainer,
          auth: true,
          allowAfterLogin: true
        }
      ]
    },
    {
      layout: ContentLayout,
      routes: [
        {
          path: "/about-us-mobile",
          exact: true,
          container: AboutUsContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/help-support-mobile",
          exact: true,
          container: HelpSupportContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/terms-conditions-mobile",
          exact: true,
          container: TermsConditionsContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/terms-conditions-ios",
          exact: true,
          container: TermsConditionsContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/terms-conditions-android",
          exact: true,
          container: TermsConditionsContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/privacy-policy-mobile",
          exact: true,
          container: PrivacyPolicyContainer,
          auth: false,
          allowAfterLogin: true
        },
        {
          path: "/contact-us-mobile",
          exact: true,
          container: ContactUsContainer,
          auth: false,
          allowAfterLogin: true
        }
      ]
    },
    {
      layout: GameLayout,
      routes: [
        {
          path: "/mobile-game/:gameName",
          exact: false,
          container: MobileGameContainer,
          auth: false,
          allowAfterLogin: true
        }
      ]
    },
    {
      layout: EmptyLayout,
      routes: [
        {
          path: "/join-now",
          exact: true,
          container: JoinNowComponent,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/billing-holt",
          exact: true,
          container: BillingHoltComponent,
          auth: false,
          allowAfterLogin: false
        },

        {
          path: "/subscribe",
          exact: true,
          container: SubscribeComponent,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/billing-subscription",
          exact: true,
          container: BillingSubscriptionComponent,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/billing-registration",
          exact: true,
          container: BillingRegistrationComponent,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/billing-resetpassword",
          exact: true,
          container: BillingResetPasswordComponent,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/billing-forgotpassword",
          exact: true,
          container: BillingForgotPasswordComponent,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/billing-login",
          exact: true,
          container: BillingLoginComponent,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/billing-info",
          exact: true,
          container: BillingInfoComponent,
          auth: false,
          allowAfterLogin: false
        },
        {
          path: "/billing-holt",
          exact: true,
          container: BillingHoltComponent,
          auth: false,
          allowAfterLogin: false
        },
      ]
    },
    {
      layout: MainLayout,
      routes: [
        {
          path: "*",
          exact: false,
          container: NotFoundContainer,
          auth: false,
          allowAfterLogin: true
        }
      ]
    }
  ];
} else {
  routes = [
    {
      layout: ContentLayout,
      routes: [
        {
          path: "/",
          exact: true,
          container: MaintenanceContainer,
          auth: false,
          allowAfterLogin: false
        }
      ]
    }
  ];
}
export default routes;
