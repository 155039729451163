import {
  ERROR_SET,
  ERROR_CLEAR,
  SUCCESS_SET,
  SUCCESS_CLEAR,
  ALL_MSG_CLEAR
} from "../types/errors";

export const ErrorSet = (scope, errorMsg) => ({
  type: ERROR_SET,
  payload: { scope: scope, errorMsg: errorMsg }
});
export const ErrorClear = () => ({
  type: ERROR_CLEAR
});
export const SuccessSet = (scope, successMsg) => ({
  type: SUCCESS_SET,
  payload: { scope: scope, successMsg: successMsg }
});
export const SuccessClear = () => ({
  type: SUCCESS_CLEAR
});
export const AllMsgClear = () => ({
  type: ALL_MSG_CLEAR
});
