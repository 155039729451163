import React, { Component } from "react";
import { connect } from "react-redux";
import { declarationModal } from "../../../actions/modals";
import "./DeclarationModal.scss";
import Modal from "../Modal";
import goldTokenImage from "../../../assets/images/gold-tokens.png";
import highScoreImage from "../../../assets/images/high-score.png";
import { ordinalSuffix, findWithAttr, isEmpty } from "../../../utils/misc";
import { toggleDeclaration } from "../../../actions/toggles";
import platformParams from "../../../utils/platformParams";

class DeclarationModal extends Component {
  constructor(props) {
    super(props);
    this.hideDeclarationModal = this.hideDeclarationModal.bind(this);
    this.state = {
      isLandScape: false
    };
  }

  hideDeclarationModal() {
    this.props.declarationModalClose();
  }

  render() {
    return (
      <>
        <Modal
          handleHide={this.hideDeclarationModal}
          isOpen={this.props.isDeclarationModalOpen}
          disableOuterClick={true}
          modalClass="modal score-card"
        >
          <div className="card-popup ">
            <div className="row">
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-sm-10 text-center mt-3 gift-wrapper">
                    <div className="row">
                      <div className="col gold-token">
                        <h5
                          className="bold mb-1"
                          >We are aware of an issue introduced by the latest iOS 14 version of Safari affecting games and score submissions and are working on addressing the problem. Please use an alternative mobile or desktop to enter tournaments in the interim.</h5>
                      </div>
                    </div>
                    <span
                      className={"a btn btn-" + platformParams['baseButtonClassSufix'] + " btn-block light mt-4 text-white"}
                      onClick={e => {
                        e.preventDefault();
                        this.props.declarationModalClose();
                      }}
                    >
                      OK
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  declarationModalClose: declarationModal.close
};

const mapStateToProps = state => {
  return {
    isDeclarationModalOpen: state.modals.isDeclarationModalOpen
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationModal);
