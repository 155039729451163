/*eslint-disable no-unused-vars*/
import React, { Component } from "react";
/*eslint-enable no-unused-vars*/
class UpdateDimension extends Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  updateDimensions() {
    this.props.onUpdate();
  }
  componentDidUpdate() {
    if (!this.props.hasSetState) {
      this.updateDimensions();
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  render() {
    return <>{this.props.children}</>;
  }
}
const defaultProps = {
  hasSetState: false
};
UpdateDimension.defaultProps = defaultProps;
export default UpdateDimension;
