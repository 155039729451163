const initialStateForDetails = {
  isFetching: false,
  item: {},
  didInvalidate: true,
  receiveError: false
};

export default params => (state = initialStateForDetails, action) => {
  switch (action.type) {
    case "DETAIL_INVALIDATE_" + params.code:
      return {
        ...state,
        didInvalidate: true
      };
    case "DETAIL_REQUEST_" + params.code:
      return {
        ...state,
        isFetching: true
      };
    case "DETAIL_RECEIVE_" + params.code:
      return {
        ...state,
        isFetching: false,
        item: action.data,
        didInvalidate: false,
        receiveError: false
      };
    case "DETAIL_RECEIVE_ERROR_" + params.code:
      return {
        ...initialStateForDetails,
        receiveError: true
      };
    default:
      return state;
  }
};
