import { MESSAGE_HISTORY_REQUEST,MESSAGE_HISTORY_RESPONSE,MESSAGE_HISTORY_RESET } from "../types/messageshistory";
import moment from "moment";
import socket from "../socket";

export const resetMessageHistory = () => ({
  type: MESSAGE_HISTORY_RESET
});

export const messageHistoryRequest = response => (dispatch, getState) => {
  dispatch({
    type: MESSAGE_HISTORY_REQUEST,
  });
  socket.emit("chat", { event: "message-history-request", count: 50 });
};

export const messageHistoryResponse = response => (dispatch, getState) => {
  dispatch({
    type: MESSAGE_HISTORY_RESPONSE,
    payload: response
  });
};

