export default params => (state = false, action) => {
  switch (action.type) {
    case "TOGGLE_" + params.code:
      return !state;
    case "TOGGLE_RESET_" + params.code:
      return false;
    default:
      return state;
  }
};
