import { combineReducers } from "redux";
import {
  ListReducerCreator,
  PaginationListReducerCreator,
  DetailReducerCreator
} from "./creators";
import {
  BrandsListPopular,
  BrandsListLive,
  BrandsListUpcoming,
  BrandsListQuickPlay,
  BrandsTopPlayersList
} from "../redux-config/listActionMap";
import {
  BrandsPopularViewAll,
  HostChallengesLiveViewAll,
  HostChallengesQuickPlayViewAll,
  HostChallengesUpcomingViewAll,
  HostTopPlayersViewAll,
  BrandLeaderboardsViewAll
} from "../redux-config/plistActionMap";
import { BrandDetails } from "../redux-config/detailActionMap";

const brands = combineReducers({
  [BrandsListPopular.stateSubKey]: ListReducerCreator(BrandsListPopular),
  [HostChallengesLiveViewAll.stateSubKey]: PaginationListReducerCreator(
    HostChallengesLiveViewAll
  ),
  [HostChallengesQuickPlayViewAll.stateSubKey]: PaginationListReducerCreator(
    HostChallengesQuickPlayViewAll
  ),
  [HostTopPlayersViewAll.stateSubKey]: PaginationListReducerCreator(
    HostTopPlayersViewAll
  ),
  [HostChallengesUpcomingViewAll.stateSubKey]: PaginationListReducerCreator(
    HostChallengesUpcomingViewAll
  ),
  [BrandsPopularViewAll.stateSubKey]: PaginationListReducerCreator(
    BrandsPopularViewAll
  ),
  [BrandLeaderboardsViewAll.stateSubKey]: PaginationListReducerCreator(
    BrandLeaderboardsViewAll
  ),
  [BrandDetails.stateSubKey]: DetailReducerCreator(BrandDetails),
  [BrandsListLive.stateSubKey]: ListReducerCreator(BrandsListLive),
  [BrandsListQuickPlay.stateSubKey]: ListReducerCreator(BrandsListQuickPlay),
  [BrandsTopPlayersList.stateSubKey]: PaginationListReducerCreator(
    BrandsTopPlayersList
  ),
  [BrandsListUpcoming.stateSubKey]: ListReducerCreator(BrandsListUpcoming)
});

export default brands;
