import {
  NOTIFICATIONS_RECEIVED_NEW,
  REMOVE_NOTIFICATION,
  CHECK_ALERT,
  BLACK_IMG_B64,
  UPDATE_NOTIFICATIONS_DATA,
  REMOVE_ALL_NOTIFICATIONS,
  NOTIFICATIONS_OPEN,
  NOTIFICATIONS_REQUEST
} from "../types/notification";
import socket from "../socket";
import { findWithAttr } from "../utils/misc";
import { friendsList } from "./friends";
import { claimPrizeForm, getProfileDataForm } from "../actions/forms";
import moment from "moment";
import { isLater } from "../utils/date";
import { logoutUserIfNeeded } from "./user";
import React from "react";
import { Trans } from "react-i18next";

export const onNotificationDeactivated = id => (dispatch, getState) => {
  let data = {
    event: "notification deactivated",
    notificationId: id
  };
  socket.emit("notificator", data);
  dispatch({
    type: REMOVE_NOTIFICATION,
    payload: { id: id }
  });
};

export const onNotificationsResponse = data => (dispatch, getState) => {
  if (
    data.list &&
    data.list.length > 0 &&
    getState().user.item &&
    getState().user.item.id
  ) {
    let list = getState().notifications.list.slice(0);
    let userId = getState().user.item.id;
    let friendNotifications = getState().notifications.friends;
    data.list
      .filter(n => n.active)
      .map(n => {
        if (
          n.payload.event.toLowerCase() === "friend request" ||
          n.payload.event.toLowerCase() === "friend accepted"
        ) {
          if (findWithAttr(friendNotifications, "id", n.id) === -1) {
            const newNotification = processFriendsNotificationNew(n);
            if (newNotification !== false) {
              friendNotifications = [newNotification].concat(
                friendNotifications
              );
            }
          }
        } else {
          if (
            n.payload.event.toLowerCase() === "competition canceled" ||
            n.payload.event.toLowerCase() === "rewards distributed" ||
            n.payload.event.toLowerCase() === "raffle won" ||
            n.payload.event.toLowerCase() === "follow request" ||
            n.payload.event.toLowerCase() === "today challenge" ||
            n.payload.event.toLowerCase() === "invitation received"
          ) {
            list = addNotificationNew(list, n).list;
          }
        }
      });

    friendNotifications = friendNotifications.filter(function(ndata) {
      if (ndata.profileId != userId && ndata.requesteeId != userId) {
        return ndata;
      }
    });

    dispatch({
      type: NOTIFICATIONS_RECEIVED_NEW,
      payload: {
        list: list,
        friends: friendNotifications,
        unread: Math.min(99, list.slice(0).filter(n => !n.isRead).length),
        unreadFriends: Math.min(
          99,
          friendNotifications.slice(0).filter(n => !n.isRead).length
        )
      }
    });
  }
};

export const onNotificationReceived = data => (dispatch, getState) => {
  if (
    getState().user.item &&
    getState().user.item.token &&
    getState().user.item.token === data.payload.token &&
    data.payload.event === "user logout"
  ) {
    dispatch(logoutUserIfNeeded());
  }

  if (data.payload.event.toLowerCase() === "unfollow request") {
    dispatch(getProfileDataForm.submit({ profileId: getState().user.item.id }));
  } else {
    if (data.payload.event === "friend accepted") {
      dispatch(friendsList.fetchData({ profileId: getState().user.item.id }));
    }

    if (
      data.payload.event.toLowerCase() === "raffle won" ||
      data.payload.event.toLowerCase() === "rewards distributed" ||
      data.payload.event.toLowerCase() === "follow request"
    ) {
      dispatch(
        getProfileDataForm.submit({ profileId: getState().user.item.id })
      );
    }

    let listData = { list: [data] };
    dispatch(onNotificationsResponse(listData));
  }
};

export const onRemoveFriendNotitication = notificationId => (
  dispatch,
  getState
) => {
  dispatch(onNotificationDeactivated(notificationId));
  const friends = getState().notifications.friends;
  if (!friends) return false;
  const existing = findWithAttr(friends, "id", notificationId);
  if (existing !== -1) {
    let resFriends = friends.slice(0);
    resFriends.splice(existing, 1);
    dispatch({
      type: UPDATE_NOTIFICATIONS_DATA,
      payload: { friends: resFriends }
    });
  }
};

export function processFriendsNotificationNew(notification) {
  let result = false;

  try {
    result = {};
    result.avatarUrl = BLACK_IMG_B64;
    result.id = notification.id;
    switch (notification.payload.event.toLowerCase()) {
      case "friend request":
        let requestorName = notification.payload.requestorName;
        result.type = "requestReceived";
        result.name = notification.payload.requestorName;
        result.profileId = notification.payload.requestorId;
        result.title = (
          <Trans i18nKey="addYouAsFriend" requestorName={requestorName}>
            {{ requestorName }} wants to add you as friend
          </Trans>
        );
        if ("requestorAvatar" in notification.payload)
          result.avatarUrl = notification.payload.requestorAvatar;
        break;
      case "friend accepted":
        let requesteeName = notification.payload.requesteeName;
        result.type = "requestAccepted";
        result.name = notification.payload.requesteeName;
        result.requesteeId = notification.payload.requesteeId || 0;
        result.title = (
          <Trans
            i18nKey="acceptYourFriendRequesr"
            requesteeName={{ requesteeName }}
          >
            {{ requesteeName }} accepted your friend request!
          </Trans>
        );
        if ("requesteeAvatar" in notification.payload)
          result.avatarUrl = notification.payload.requesteeAvatar;
        break;
    }

    result.createdAt = notification.time_of_creation;
    result.isRead = notification.seen;
  } catch (error) {}
  return result;
}

export function addNotificationNew(list, newNotification) {
  let index = false;
  const existing = list.filter(notification => {
    return notification.id === newNotification.id;
  });
  if (existing.length === 0) {
    const insert = processNotificationNew(newNotification);
    if (!insert) {
      return { list: list };
    }

    let found = false;
    index = 0;
    while (!found && index < list.length) {
      if (moment(insert.createdAt).diff(moment(list[index].createdAt)) > 0) {
        found = true;
      } else {
        index += 1;
      }
    }
    list.splice(index, 0, insert);
  }

  return { list: list, index: index };
}

export function processNotificationNew(notification) {
  notification.type = 1;
  notification.isRead = notification.seen;
  notification.createdAt = notification.time_of_creation;
  notification.content = "";

  switch (notification.payload.event) {
    case "competition canceled":
      notification.title = `${notification.payload.competitionName}`;
      notification.content = (
        <Trans i18nKey="hasBeenCancel">has been canceled!</Trans>
      );
      break;
    case "rewards distributed":
      const {
        competitionName,
        competitionType,
        competitionId,
        organizerId,
        didWinReward
      } = notification.payload;

      if (didWinReward) {
        notification.title = (
          <Trans i18nKey="youAreWinner">You're a winner!"</Trans>
        );
        notification.content = (
          <Trans i18nKey="youWonPrize" competitionName={competitionName}>
            You won a prize in {{ competitionName }}
          </Trans>
        );
        notification.actionButton = true;
        notification.type = 4; // ?
      } else {
        notification.title = <Trans i18nKey="goodGame">Good game! </Trans>;
        notification.content = (
          <Trans
            i18nKey="thankyouForParticipate"
            competitionName={competitionName}
          >
            Thank you for participating in {{ competitionName }}!
          </Trans>
        );
        notification.actionButton = false;
        notification.type = 3; // ?
      }
      break;
    case "raffle won":
      notification.title = (
        <Trans i18nKey="congratsWonRaffle">
          Congrats! You have won the raffle
        </Trans>
      );
      notification.content = (
        <Trans i18nKey="yourPrizeArriveShort">
          Your prize will arrive shortly.
        </Trans>
      );
      notification.actionButton = true;
      notification.type = 2;
      break;
    case "follow request":
      notification.title = notification.payload.requestorName;
      notification.content = (
        <Trans i18nKey="startedFollowingYou">Started Following You.</Trans>
      );
      notification.actionButton = true;
      notification.type = 2;
      break;
    case "today challenge":
      notification.title = (
        <Trans i18nKey="challengePage.todayschallenge">Today's Challenge</Trans>
      );
      notification.content = notification.payload.competitionName;
      notification.actionButton = true;
      notification.type = 2;
      break;
    case "invitation received":
      notification.title = (
        <Trans i18nKey="challengeInvitation">Challenge Invitation</Trans>
      );
      notification.content = notification.payload.competitionName;
      notification.actionButton = true;
      notification.type = 2;
      break;
  }

  return notification;
}

export const onNotificationSeen = id => (dispatch, getState) => {
  let data = {
    event: "notifications seen",
    notificationIds: typeof id === "object" ? id : [id]
  };
  socket.emit("notificator", data);
};

export const openNotificationSidebar = () => (dispatch, getState) => {
  let markSeenIds = [];
  const list = getState()
    .notifications.list.slice(0)
    .map(n => {
      markSeenIds.push(n.id);
    });
  dispatch(onNotificationSeen(markSeenIds));
  dispatch({
    type: NOTIFICATIONS_OPEN
  });
};

export const clearNotificationLogout = () => ({
  type: REMOVE_ALL_NOTIFICATIONS
});

export const getNotificationDataById = id => (dispatch, getState) => {
  const challengeId = getState()
    .notifications.list.filter(data => data.id === id)
    .map((data, index) => data.payload.competitionId);

  if (challengeId.length > 0)
    dispatch(claimPrizeForm.updateField("challengeId", challengeId[0]));
  else dispatch(claimPrizeForm.updateField("challengeId", "notFound"));
};
export const notificationsRequest = response => (dispatch, getState) => {
  let userDetail = getState().user.item;
  dispatch({
    type: NOTIFICATIONS_REQUEST,
  });
  socket.emit("notificator", {
    event: "notifications request",
    userId: userDetail.id,
    pagination: { from: 0, to: 50 }
  });
};