import { combineReducers } from "redux";
import {
  ListReducerCreator,
  PaginationListReducerCreator,
  DetailReducerCreator
} from "./creators";
import { FreeToPlayList } from "../redux-config/listActionMap";
import { ArcadeGameDetail } from "../redux-config/detailActionMap";

import { FreeToPlayViewAll, ArcadeGamesByCategoryViewAll } from "../redux-config/plistActionMap";

const categories = combineReducers({
  [FreeToPlayList.stateSubKey]: ListReducerCreator(FreeToPlayList),
  [FreeToPlayViewAll.stateSubKey]: PaginationListReducerCreator(FreeToPlayViewAll),
  [ArcadeGamesByCategoryViewAll.stateSubKey]: PaginationListReducerCreator(ArcadeGamesByCategoryViewAll),
  [ArcadeGameDetail.stateSubKey]: DetailReducerCreator(ArcadeGameDetail),
});

export default categories;
