import { CHAT_HISTORY_REQUEST,CHAT_HISTORY_RESPONSE,CLOSE_CHAT_HISTORY, OPEN_CHAT_HISTORY ,CHAT_PAGINATION ,ADD_NEW_CHAT_MESSAGE} from "../types/chathistory";

const initialState = {
  isFetching: false,
  items: [],
  page:1,
  isLastPage:false,
  chatRoomId:"",
  chatUserId:"",
  chatUserDetail:{},
  vScrollPosition:""
};

const chathistory = (state = initialState, action) => {
  switch (action.type) {

    case OPEN_CHAT_HISTORY:
    {
      return {
        ...state,
        chatUserId: action.payload.id,
        chatUserDetail:action.payload
      };
    }

    case CHAT_HISTORY_REQUEST:
    {
      return {
        ...state,
        isFetching: true,
        page:action.payload
     };

    } 
    case CHAT_PAGINATION:{
      return { 
                ...state,
                isLastPage: action.payload,
              };
    }

    case CHAT_HISTORY_RESPONSE:
    {
      
      
      let chatRoomId =  action.payload[0].chatroom_id;
      let oldChatMessage = state.items;
      let vScrollPosition="bottom";
      let oldChatRoomId = state.chatRoomId;
      if(oldChatRoomId === "")
      { 
        vScrollPosition = "bottom";
      }
      else
      {
        vScrollPosition = "top";
      }
      oldChatMessage.unshift(...action.payload);

      return {
        ...state,
        isFetching: false,
        items: oldChatMessage,
        chatRoomId:chatRoomId,
        vScrollPosition:vScrollPosition
    }; 
    } 

    case ADD_NEW_CHAT_MESSAGE:
    {
      let oldChatMessage = state.items;
      oldChatMessage.push(action.payload);
      return {
        ...state,
        items: oldChatMessage,
        vScrollPosition:"bottom"
      }; 
    } 

    case CLOSE_CHAT_HISTORY:
    {
      return {
        isFetching: false,
        items: [],
        page:1,
        isLastPage:false,
        chatRoomId:"",
        chatUserId:"",
        chatUserDetail:{},
        vScrollPosition:""
    }; 
  }
    default:
      return state;
  }
};

export default chathistory;
