import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getChallengesLandingPopular = () => {
  const postParams = {
    brandId: "",
    page: "1"
  };
  return axios
    .request({
      url: API_SERVER_URL + config.challengesLandingPopular,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getLiveChallengesViewAll = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.challengesLive,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getFeatureChallenges = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.featureChallenges,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getQuickPlayChallengesViewAll = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.challengesQuickPlay,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getUpcomingChallengesViewAll = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.challengesUpcoming,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getPremiumChallengesViewAll = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.challengesPremium,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getCategoryChallengesViewAll = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.challengesLive,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getBrandChallengesViewAll = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.challengesByBrand,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getChallengesForSpecificBrand = brandId => {
  const postParams = {
    brandId: brandId,
    // casualGameId: "",
    page: "1"
  };
  return axios
    .request({
      url: API_SERVER_URL + config.challengesListByBrand,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getChallengeDetail = (challengeId, dispatch, getState) => {
  return axios
    .request({
      url:
        API_SERVER_URL + config.challengeDetail.replace("##id##", challengeId),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const joinChallengeService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.joinChallenge,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const finishGameChallengeService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.finishChallenge,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const claimPrizeService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.prizeRedemption,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getChallengesLeaderboard = postParams => {
    return axios
      .request({
        url: API_SERVER_URL + config.challengeLeaderboard,
        method: "post",
        headers: {
          "content-type": "application/json"
        },
        data: postParams
      })
      .catch(err => {
        console.log(err);
      });
  };
export const getBrandLeaderboard = postParams => {
    return axios
      .request({
        url: API_SERVER_URL + config.brandLeaderboard,
        method: "post",
        headers: {
          "content-type": "application/json"
        },
        data: postParams
      })
      .catch(err => {
        console.log(err);
      });
  };