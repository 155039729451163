import React from "react";
import {
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  MAX_USERNAME_LENGTH,
  MIN_USERNAME_LENGTH,
  MIN_NUMBER_NAME_LENGTH,
  MAX_NUMBER_NAME_LENGTH
} from "../../utils/constants";
import { Trans } from "react-i18next";

export const APP_MESSAGES = {
  PASSWORD_LENGTH: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans
        i18nKey="passwordLength"
        MIN_PASSWORD_LENGTH={MIN_PASSWORD_LENGTH}
        MAX_PASSWORD_LENGTH={MAX_PASSWORD_LENGTH}
      >
        Password must be {{ MIN_PASSWORD_LENGTH }} - {{ MAX_PASSWORD_LENGTH }}{" "}
        characters
      </Trans>

      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  VAS_PASSWORD_LENGTH: (payload, handleClick) => props => (
    <div>
      <Trans
        i18nKey="passwordLength"
        MIN_PASSWORD_LENGTH={MIN_PASSWORD_LENGTH}
        MAX_PASSWORD_LENGTH={MAX_PASSWORD_LENGTH}
      >
        Password must be {{ MIN_PASSWORD_LENGTH }}-{{ MAX_PASSWORD_LENGTH }}
        characters
      </Trans>
    </div>
  ),
  VAS_NUMBER_LENGTH: (payload, handleClick) => props => (
    <div>
      <Trans
        i18nKey="vasNumberLength"
        MIN_NUMBER_NAME_LENGTH={MIN_NUMBER_NAME_LENGTH}
        MAX_NUMBER_NAME_LENGTH={MAX_NUMBER_NAME_LENGTH}
      >
        Number must be {{ MIN_NUMBER_NAME_LENGTH }}-{{ MAX_NUMBER_NAME_LENGTH }}{" "}
        characters
      </Trans>
    </div>
  ),
  USERNAME_LENGTH: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans
        i18nKey="usernameLength"
        MIN_USERNAME_LENGTH={MIN_USERNAME_LENGTH}
        MAX_USERNAME_LENGTH={MAX_USERNAME_LENGTH}
      >
        username must be {{ MIN_USERNAME_LENGTH }} - {{ MAX_USERNAME_LENGTH }}
        characters
      </Trans>

      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  FILE_SIZE: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="fileSizeLarge">File Size too large</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  FILE_TYPE: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="validFileType">Enter valid file</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  MOBILE: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="correctMobileNo">
        Please, make sure you have entered the correct mobile number
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  EMAIL: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="correctEmail">
        Please, make sure you have entered the correct email
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  CREDENTIALS: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="P055">
        Please, make sure you have entered the correct sign in credentials!
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  V001: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="P055">
        Please, make sure you have entered the correct sign in credentials!
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  CONTACT_SUPPORT: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="contactSupport">
        There was a problem with this action. Please try again or contact
        support!
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  P062: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="P062">Email already registered!</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  S002: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="subscriptionPending">
        Subscription is pending! Please check your mobile phone and confirm the
        subscription!
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  S003: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      Subscription is inactive! Send ##text## to ##shortCode##
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  S005: (payload, handleClick) => props => {
    let link = (
      <span
        className="btn btn-link"
        onClick={() => {
          props.handleLinkClick();
        }}
      >
        Subscribe?
      </span>
    );
    return (
      <div className="mt-2 alert alert-dismissible alert-danger small">
        <Trans i18nKey="subscriptionNotFound"> Subscription not found. </Trans>
        {link}
        <button type="button" className="close" onClick={handleClick}>
          <span>&times;</span>
        </button>
      </div>
    );
  },
  S006: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="notVerifySubscription">
        We couldn't verify your subscription! Please retry in a few minutes.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  S004: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="notMakeSubscriptionReq">
        We couldn't make a subscription request!
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  S001: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="subsciptionActive">Subscription is active!</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),

  S008: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      We couldn't make a subscription request! Send ##text## to ##shortCode##.
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  S009: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      We couldn't make a subscription request! Send ##text## to ##shortCode##.
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  P055: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="P055">
        Please, make sure you have entered the correct sign in credentials!
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  P008: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="profileAlredyReg">Profile already registered!</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  HYVE_SIGNUP_REGISTERED: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="profileAlredyReg">Profile already registered!</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  P005: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="nickxNotLunched">
        Oops! Nick X has not launched in your country or region yet.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  P063: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="notVerifyPlayerName">
        The player name you have chosen is already in use, please try another
        name.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  COMPARE_PASSWORD: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="compareResetPw">
        Please, make sure you have entered the both password are same!
      </Trans>
    </div>
  ),
  V002: (payload, handleClick) => props => (
    <div>
      Please, make sure you have entered the correct sign in credentials!
    </div>
  ),
  BT05: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="P055">
        Please, make sure you have entered the correct sign in credentials!
      </Trans>
    </div>
  ),
  BT02: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="somethingWentWrong">Something went wrong!</Trans>
    </div>
  ),
  BT03: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="billingTokenExpiry">Billing token expired.</Trans>
    </div>
  ),
  BI02: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="profileAlredyReg">Profile already registered.</Trans>
    </div>
  ),
  BI06: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="phoneNoAlreadyExit">Phone number already exist.</Trans>
    </div>
  ),
  BI07: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="phoneNotMatch">Phone number does not match.</Trans>
    </div>
  ),
  BI08: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="resubscribe">You are no longer subscribed. Please resubscribe to access ArcadeX again!</Trans>
    </div>
  ),
  BI00: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="noRecordFound">No records found.</Trans>
    </div>
  ),
  BT01: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="noTokenFound">Token Not Found.</Trans>
    </div>
  ),

  S007: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="subInactive">Unsubscribe failed.</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  SUB06: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="UnsubscribeFailed">Unsubscribe failed.</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  SUB014: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="invalidToken">Invalid Token.</Trans>
    </div>
  ),
  SUB015: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="errorSubscribedToSite">Could not found the subscription data.</Trans>
    </div>
  ),

  BM02: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="mobileOperatorNotFound">Mobile Operator not found.</Trans>
    </div>
  ),
  E001: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="envNotExit">env does not exists.</Trans>
    </div>
  ),
  BT04: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="countryOfOriginUnknown">
        Country of origin not whitelisted or is unknown.
      </Trans>
    </div>
  ),
  BT06: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="apiRetryLimitExceeded">
        Something went wrong. Please, contact support.
      </Trans>
    </div>
  ),
  SUB01: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="subscriptionFailed">Your subscription wasn’t successful due to insufficient funds. If that isn’t the case, please contact us on mtnarena@arcadex.co</Trans>
    </div>
  ),
  ST001: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="subscriptionTimeout">Your subscription wasn’t successful due to insufficient funds. If that isn’t the case, please contact us on mtnarena@arcadex.co</Trans>
    </div>
  ),
  BI03: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="profileNotFound">Profile not found.</Trans>
    </div>
  ),
  SUB02: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="alreadyVerify">Already Verified!</Trans>
    </div>
  ),
  SUB013: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="subscribedSuccessfully">Subscribed Successfully!</Trans>
    </div>
  ),
  SUB03: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="otpVerifyFail">OTP verification failed.</Trans>
    </div>
  ),
  SUB07: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="otpSendFail">OTP send failed.</Trans>
    </div>
  ),
  SUB012: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="subscriptionNotFound">Subscription not Found.</Trans>
    </div>
  ),
  SUB08: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="otpInvalid">Invalid Otp.</Trans>
    </div>
  ),
  SUB09: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="otpExpire">Otp Expired.</Trans>
    </div>
  ),
  BI04: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="profileVerificationPending">
        Profile verification pending.
      </Trans>
    </div>
  ),
  BI03: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="profileNotFound">Profile not found.</Trans>
    </div>
  ),
  BS02: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="siteNotFound">Site not found.</Trans>
    </div>
  ),
  SUB04: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="invalidPhoneNo">Invalid phone number.</Trans>
    </div>
  ),
  SUB05: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="subscriptionExpired">Subscription Expired.</Trans>
    </div>
  ),
  SUB016: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="subscriptionSuspended">Your Arena subscription is currently overdue. Please load airtime to continue enjoying access to tournaments and game play.</Trans>
    </div>
  ),

  P007: (payload, handleClick) => props => (
    <div className="holdmessage small">
      {/* <Trans i18nKey="youhaveBaned">
        Your Miggster account has been temporarily put on hold because we detected unusual activity. Contact support on <a href="mailto:miggster@arcadex.co">miggster@arcadex.co</a> if you believe this has been done in error.
      </Trans> */}
      {/* (##reason##). */}
      {/* <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button> */}
      <div className="inner-holdmessage">
        Your Arena account has been temporarily put on hold because we detected unusual activity. Contact support on <a href="mailto:mtnarena@arcadex.co">mtnarena@arcadex.co</a> if you believe this has been done in error.
      </div>
    </div>
  ),
  UNSUBSCRIBE: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="problemWithUnsubscribing">
        we had a problem with unsubscribing, please try again later
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  WRONG_ACCOUNT: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="wrongAcoount">
        Wrong account or account already activated!
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  COMPARE_RESET_PASSWORD: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="compareResetPw">
        Please, make sure you have entered the both password are same!
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  NOT_RESET_PASSWORD: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="notResetPw">
        Could not reset password. Please, contact support.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  P061: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="resetPwLinkExpire">
        Reset password link has expried.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  D001: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="somthingWrong">
        Something went wrong. Please, contact support.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  ACCOUNT_ASSOCIATED: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="accountAssociated">
        There is no account associated with this email
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  P004: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="emailInUse">Email already in use!</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  P001: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="P055">
        Please, make sure you have entered the correct sign in credentials!
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  P057: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="accountAssociated">
        There is no account associated with this email
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  ALREADY_SUBSCRIBE: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="alreadySubscribe">Already subscribed!</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),

  NOT_EMPTY: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="notEmpty">
        Please, make sure you have entered values
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  VAS_NOT_EMPTY: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="notEmpty">
        Please, make sure you have entered values
      </Trans>
    </div>
  ),
  VAS_NOT_SELECT: (payload, handleClick) => props => (
    <div>
      <Trans i18nKey="makeSureSelect">
        Please, make sure you have selected a value
      </Trans>
    </div>
  ),
  USERNAME_USE: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="usernameUse">Username already in use!</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  ACTIVATION_SUCCESS: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-success small">
      <Trans i18nKey="successActiveLogin">
        You are successfully activated, Please login.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  ACTIVATION_FAILURE: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="notActiveTryAgain">
        You are not activated, Please try again.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  REGISTER_SUCCESS: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-success small">
      <Trans i18nKey="registerSuccess">
        You are successfully registered, Please Check your email.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  SUBSCRIBE_EMAIL_EMPTY: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="enterEmail">Please enter email</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  SUBSCRIBE_EMAIL_INVALID: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="emailInvalid">Please enter valid email</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  SUBSCRIBE_FAILED: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="subscribeFail">Subscription failed</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  SUBSCRIBE_SUCCESS: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-success small">
      <Trans i18nKey="successSubscribe">You are successfully subscribed.</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  FORGOT_PASSWORD_SEND_MAIL: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-success small">
      <Trans i18nKey="forgotPwsendmail">
        send mail successfully please check register email address
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  FORGOT_PASSWORD_RESEND_MAIL: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-success small">
      <Trans i18nKey="forgotPwResendmail">
        resend mail successfully to your registered email address
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  RESET_PASSWORD_SUCCESS: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-success small">
      <Trans i18nKey="resetPwSuccess">
        Success! New password successfully set.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  TERMS_CHECK: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="termsCheck">Please accept Terms of Service.</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  HELP_SUPPORT_SUCCESS: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-success small">
      <Trans i18nKey="helpsupportSuccess">
        Your question sent successfully.
      </Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  D005: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-danger small">
      <Trans i18nKey="currentPasswordWrong">Current password is wrong.</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  REPORT_PLAYER_SUCCESS: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-success small">
      <Trans i18nKey="reportSentSucess">Your report sent successfully.</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  PUBLISH_RAFFLE_SUCCESS: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-success small">
      <Trans i18nKey="raffleCreatedSuccess">Raffle created successfully.</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  ),
  CONTACT_US_SUCCESS: (payload, handleClick) => props => (
    <div className="mt-2 alert alert-dismissible alert-success small">
      <Trans i18nKey="contactUsSuccess">Mail Send Successfully.</Trans>
      <button type="button" className="close" onClick={handleClick}>
        <span>&times;</span>
      </button>
    </div>
  )
};
