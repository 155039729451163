import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";
import { isNgCountry } from "../utils/misc";

export const onGetSubscribedToSite = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasSubscribedToSite,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
}

export const getMobileOperatorList = postParams => {
  // url:  "http://192.168.0.135:8000/api/v1/billing/mondia/getMobileOperators",
  return axios
    .request({
      url: API_SERVER_URL + config.mobileOperatorDetails,
      method: "post",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getProductsByMobileOperatorList = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.productsByMobileOperator,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    })
}

export const onSubscribeNowRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasSubscribe,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onSubscribeWrapmeRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasWrapme,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onBillingSubscriptionRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasVerifyOTP,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onBillingRegistrationRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasMTNRegister,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onBillingLoginRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasProfileLogin,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onBillingInfoRequest = postParams => {
  // let endpoint = config.vasProfileBillingInfo;
  // if(isNgCountry()){
  //   endpoint = config.vasNGProfileBillingInfo;
  // }
  return axios
    .request({
      url: API_SERVER_URL + config.vasProfileBillingInfo,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onNGBillingInfoRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasNGProfileBillingInfo,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onBillingForgotPasswordRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasForgotPassword,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onBillingResetPasswordRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasResetPassword,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const onUnsubscribeRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasUnsubscribe,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const onHoldRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasHoldScreen,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const onNGHoldRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasNGHoldScreen,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const onNGAutoLoginRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasNGAutoLogin,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onNGGetDOIUrlFormRequest = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.vasNGGetDOIUrl,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
