import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getPopularBrands = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.popularBrands,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getPopularBrandsViewAll = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.popularBrands,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getBrandDetails = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.brandDetails.replace("##id##", postParams.brandId),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getTopPlayersOfBrand = postParams => {
  let brandId = postParams.brandId;
  delete postParams.brandId;
  return axios
    .request({
      url: API_SERVER_URL + config.topPlayers.replace("##id##", brandId),
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const followBrand = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL + config.followBrand.replace("##id##", postParams.followeeId),
      method: "post",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const unfollowBrand = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.unfollowBrand.replace("##id##", postParams.followeeId),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};
