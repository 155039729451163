export const LeftSidebar = {
  code: "LEFT",
  stateKey: "sidebars",
  stateModalKey: "isLeftSidebarOpen"
};
export const ProfileSidebar = {
  code: "PROFILE",
  stateKey: "sidebars",
  stateModalKey: "isProfileSidebarOpen"
};
export const ProfileEditSidebar = {
  code: "PROFILE_EDIT",
  stateKey: "sidebars",
  stateModalKey: "isProfileEditSidebarOpen"
};
export const FriendListSidebar = {
  code: "FRIEND_LIST",
  stateKey: "sidebars",
  stateModalKey: "isfriendListSidebarOpen"
};
export const MessageListSidebar = {
  code: "Message_LIST",
  stateKey: "sidebars",
  stateModalKey: "isMessageListSidebarOpen"
};
export const ChatListSidebar = {
  code: "CHAT_LIST",
  stateKey: "sidebars",
  stateModalKey: "isChatListSidebarOpen"
};

export const UserListSidebar = {
  code: "USER_LIST",
  stateKey: "sidebars",
  stateModalKey: "isUserListSidebarOpen"
};
export const NotificationListSidebar = {
  code: "NOTIFICATION_LIST",
  stateKey: "sidebars",
  stateModalKey: "isNotificationListSidebarOpen"
};