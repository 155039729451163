import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { createName, createAvatar, createTutorial } from "../actions/modals";
import i18n from "../i18n";

export default function(ComposedComponent) {
  class FirstLoginHoc extends Component {
    componentDidMount() {
      if (
        this.props.isAuthenticated &&
        !this.props.has_createname_preferences &&
        this.props.match.path === this.props.match.url
      ) {
        this.props.createNameOpen();
      } else if (
        this.props.isAuthenticated &&
        !this.props.has_createavatar_preferences &&
        this.props.match.path === this.props.match.url
      ) {
        this.props.createAvatarOpen();
      } else if (
        this.props.isAuthenticated &&
        !this.props.has_createtutorial_preferences &&
        this.props.match.path === this.props.match.url
      ) {
        this.props.createTutorialOpen();
      }
    }
    render() {
      const lang = i18n.language;
      return (
        <>
          {this.props.isAuthenticated &&
          (!this.props.has_createname_preferences ||
            !this.props.has_createavatar_preferences ||
            !this.props.has_createtutorial_preferences) &&
          this.props.match.path !== this.props.match.url ? (
            <>
              {/* <Redirect to={this.props.match.url} /> */}
              <Redirect to={"/" + lang + "/"} />
            </>
          ) : (
            <ComposedComponent {...this.props} />
          )}
        </>
      );
    }
  }
  const mapDispatchToProps = {
    createNameOpen: createName.open,
    createAvatarOpen: createAvatar.open,
    createTutorialOpen: createTutorial.open
  };

  const mapStateToProps = state => {
    return {
      isAuthenticated: state.login.isLoggedIn,
      has_createname_preferences: state.tutorials.hasCreatenamePreferences,
      has_createavatar_preferences: state.tutorials.hasCreateavatarPreferences,
      has_createtutorial_preferences:
        state.tutorials.hasCreatetutorialPreferences
    };
  };
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(FirstLoginHoc);
}
