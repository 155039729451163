import { ListActionCreator } from "./creators";
import { getFriends } from "../services/friends.service";

import { FriendsList } from "../redux-config/listActionMap";

export const friendsList = ListActionCreator({
  ...FriendsList,
  service: getFriends,
  dataMapper: json => {
    return { items: json.data.data.friends };
  }
});
