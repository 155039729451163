import React, { Component } from "react";
import { connect } from "react-redux";
import { removeToastr } from "../../actions/toastr";

class ToastrItem extends Component {
  constructor(props) {
    super(props);
    this.closeToastr = this.closeToastr.bind(this);
  }

  closeToastr()
  {
    this.props.removeToastr(this.props.index);
  }

  render() {
    return (
      <>
        <div className={"toast toast-"+this.props.type}>
            <button type="button" className="toast-close-button" onClick={this.closeToastr}>×</button>
            <div className="toast-message">{this.props.message}</div>
          </div>
      </>
    );
  }
}

const defaultProps = {
  type:"success",
  message:"Please enter message"
};

ToastrItem.defaultProps = defaultProps;



const mapDispatchToProps = {
  removeToastr: removeToastr
};

const mapStateToProps = null;


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastrItem);
