import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getRaffles = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.raffles,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getMyActiveRaffles = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.myActiveRaffles,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const joinRaffles = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.joinRaffles,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const publishRaffleService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.publishRaffle,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
