import React, { useState } from 'react'
import packageModalHero from "../../assets/images/package-hero.jpg";
import iconClose from '../../assets/images/icon-close.svg'
import './SelectPackageModal.scss'

// export interface SelectPackageModalProps {
// 	toggleModal: () => void
// 	showModal?: boolean
// 	showLinkToSignIn?: boolean
// }

export function SelectPackageModal(props) {
	const { toggleModal, showModal, showLinkToSignIn = true } = props
	const [packageSelected, setPackageSelected] = useState(null)
	const handleSubmit = () => {
		if (packageSelected === 'monthly') {
			window.location.href = 'http://doi.mtndep.co.za/service/6937?gv_id=1365'
		} else if (packageSelected === 'weekly') {
			window.location.href = 'http://doi.mtndep.co.za/service/9154?gv_id=3335'
		} else {
			window.location.href = 'http://doi.mtndep.co.za/service/10334?gv_id=4424'
		}
	}
	const handleClose = () => {
		setPackageSelected(null)
		toggleModal()
	}

	return (
		<>
			{showModal && (
				<div className="package-select-modal">
					<div className="dimmed-background" onClick={() => handleClose()} />
					<div className="modal-content-container">
						<div className="modal-contentx">
							<div className="close-btn" onClick={() => handleClose()}>
								<img src={iconClose} />
							</div>
							<img src={packageModalHero} className="hero-image" />
							<div className="form-container">
								<h1>Select a Package</h1>
								<div
									className={`package-btn ${packageSelected === 'monthly' ? 'selected' : ''}`}
									onClick={() => setPackageSelected('monthly')}>
									<div className="checkbox"></div>
									<div className="interval">
										Most Popular<span>Save over 80% each month</span>
									</div>
									<div className="price">
										<div className="rand-value">
											<span className="rand-symbol">R</span>30
										</div>
										<span>per month</span>
									</div>
								</div>
								<div
									className={`package-btn ${packageSelected === 'weekly' ? 'selected' : ''}`}
									onClick={() => setPackageSelected('weekly')}>
									<div className="checkbox"></div>
									<div className="interval">
										Most Flexible<span>Save ±45% each week</span>
									</div>
									<div className="price">
										<div className="rand-value">
											<span className="rand-symbol">R</span>20
										</div>
										<span>per week</span>
									</div>
								</div>
								<div
									className={`package-btn ${packageSelected === 'daily' ? 'selected' : ''}`}
									onClick={() => setPackageSelected('daily')}>
									<div className="checkbox"></div>
									<div className="interval">
										Budget Friendly<span>Pay per day to play</span>
									</div>
									<div className="price">
										<div className="rand-value">
											<span className="rand-symbol">R</span>5
										</div>
										<span>per day</span>
									</div>
								</div>
								{/* <Button
									text="Subscribe"
									block
									disabled={!packageSelected}
									onClick={() => handleSubmit()}
								/> */}
								{/* d-none  */}
								<button
									className="btn btn-mtnThemeMainColor footer light btn-block"
									disabled={!packageSelected}
									text="Subscribe"
									onClick={
										() => handleSubmit()
									}
								>
									Subscribe
								</button>
								{showLinkToSignIn && (
									<a href="/sign-in" className="already-have-account-btn">
										Already have an account? <span className="underlined-text">Sign in</span>
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
