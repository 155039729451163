import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyPrizeModal } from "../../../actions/modals";
import "./VerifyPrizeModal.scss";
import Modal from "../Modal";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../../i18n";

class VerifyPrizeModal extends Component {
  constructor(props) {
    super(props);
    this.showVerifyPrize = this.showVerifyPrize.bind(this);
    this.hideVerifyPrize = this.hideVerifyPrize.bind(this);
  }
  componentDidMount() {}
  showVerifyPrize() {
    this.props.verifyPrizeOpen();
  }
  hideVerifyPrize() {
    this.props.verifyPrizeClose();
  }
  render() {
    const { t } = this.props;
    const lang = i18n.language;
    return (
      <>
        <Modal
          showCloseBtn={false}
          modalCrossSvgClass={false}
          handleHide={this.hideVerifyPrize}
          isOpen={this.props.isVerifyPrizeOpen}
          disableOuterClick={true}
        >
          <div className="card-popup create-player">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h3 className="mb-5">
                  <Trans i18nKey="alreadyComplete">
                    You Have Already <br />
                    Complete This Process
                  </Trans>
                </h3>
                <p className="mb-5">{t("anyQueriesContact")}</p>
                <div className="mt-3">
                  <a href={"/" + lang + "/dashboard"} className="goBackLink">
                    {t("goBackDashboard")}
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12"></div>
            </div>

            <div className="row">
              <div className="col-sm-12 text-left" />
            </div>
            <div className="row privacy-policy">
              <div className="col-sm-12 text-center bottom-margin" />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  verifyPrizeOpen: verifyPrizeModal.open,
  verifyPrizeClose: verifyPrizeModal.close
};

const mapStateToProps = state => {
  return {
    isVerifyPrizeOpen: state.modals.isVerifyPrizeOpen,
    userId: state.user.item.id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(VerifyPrizeModal));
