import React, { Component } from "react";
import { connect } from "react-redux";
import {
	profileEditSidebar,
	profileSidebar,
	sidebarCloseAll,
} from "../../../../actions/sidebars";
import { changePassword } from "../../../../actions/modals";
import AppInputComponent from "../AppInputComponent";
import { logoutUserIfNeeded } from "../../../../actions/user";
import {
	profileEditForm,
	usernameAvailableForm,
} from "../../../../actions/forms";
import arrowRightSvg from "../../../../assets/images/svg/nav-arrow-right.svg";
import CheckGreenCircle from "../../../../assets/images/svg/check-green-circle.svg";
import { validateUsername, validateName } from "../../../../utils/validators";
import { withTranslation } from "react-i18next";
import platformParams from "../../../../utils/platformParams";

class RightSidebarProfileEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabClass: true,
			inputClass: true,
			searchedUsername: this.props.name,
		};
		this.displayContentOnTab = this.displayContentOnTab.bind(this);
		this.showProfileEditSidebar = this.showProfileEditSidebar.bind(this);
		this.hideProfileEditSidebar = this.hideProfileEditSidebar.bind(this);
		this.hideProfileEditSidebarProxy =
			this.hideProfileEditSidebarProxy.bind(this);
	}

	inputClassChange() {
		this.setState({
			inputClass: this.state.inputClass ? false : true,
		});
	}
	displayContentOnTab() {
		this.setState({
			tabClass: this.state.tabClass ? false : true,
		});
	}

	componentWillMount() {
		if (!this.props.disableOuterClick) {
			document.addEventListener(
				"mousedown",
				this.hideProfileEditSidebarProxy,
				false
			);
		}
	}
	componentWillUnmount() {
		if (!this.props.disableOuterClick) {
			document.removeEventListener(
				"mousedown",
				this.hideProfileEditSidebarProxy,
				false
			);
		}
	}
	showProfileEditSidebar() {
		this.props.profileEditSidebarOpen();
	}
	hideProfileEditSidebar() {
		this.props.profileEditSidebarClose();
	}

	hideProfileEditSidebarProxy = (e) => {
		if (this.props.disableOuterClick) {
			return;
		}
		if (this.rightSidebar && this.rightSidebar.contains(e.target)) {
			return;
		}
		if (this.props.isprofileEditSidebarOpen) {
			this.hideProfileEditSidebar();
		}
		return;
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<nav
					className={
						"accounts-slider" +
						(this.props.isprofileEditSidebarOpen ? " active" : "")
					}
					ref={(rightSidebar) => {
						this.rightSidebar = rightSidebar;
					}}
				>
					<div className="fixed-sidebar-scroll-landscap">
						<section className="d-flex flex-column ">
							<section className="row no-gutters">
								<div className="col-sm-12 text-center">
									<div
										className={
											"sidebar-header 3" +
											platformParams["className"]
										}
									>
										<div className="d-flex  justify-content-between align-items-center message-header">
											<h6 className="bold">
												<span
													onClick={
														this.props
															.profileSidebarOpen
													}
													className="a back-arrow"
												>
													<img
														src={arrowRightSvg}
														alt=""
													/>
												</span>
												{t("editProfile")}
											</h6>
										</div>
										{/* <div className="account-tab">
                      <ul className="nav nav-pills mb-4 d-flex justify-content-center align-items-center">
                        <li className="nav-item col">
                          <span
                            onClick={this.displayContentOnTab}
                            className={
                              "nav-link " +
                              (this.state.tabClass ? "active show" : "")
                            }
                            id="pills-today-tab"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-today"
                            aria-selected="true"
                          >
                            My Account
                          </span>
                        </li>
                        <li className="nav-item col">
                          <span
                            onClick={this.displayContentOnTab}
                            className={
                              "nav-link " +
                              (this.state.tabClass ? "" : "active show")
                            }
                            id="pills-overall-tab"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-overall"
                            aria-selected="false"
                          >
                            Subscription
                          </span>
                        </li>
                      </ul>
                    </div> */}
									</div>
								</div>
							</section>
							<section className="form-info tab-content">
								<form
									id="pills-today"
									className={
										"tab-pane fade " +
										(this.state.tabClass
											? "active show"
											: "")
									}
									role="tabpanel"
									aria-labelledby="pills-today-tab"
									onSubmit={(e) => {
										e.preventDefault();
										if (
											!(
												this.props.profileEditForm
													.isSubmitting ||
												this.props.usernameAvailableForm
													.isSubmitting
											)
										) {
											if (
												this.props.profileEditForm
													.fields.firstName.error ||
												this.props.profileEditForm
													.fields.lastName.error
											) {
												return false;
											}

											if (
												!this.props.profileEditForm
													.fields.username.value
											) {
												this.props.profileEditFormSetError(
													"username",
													"NOT_EMPTY"
												);
												return false;
											}
											// if (
											//   this.props.profileEditForm.fields.firstName.value &&
											//   !validateName(
											//     this.props.profileEditForm.fields.firstName.value
											//   )
											// ) {
											//   this.props.profileEditFormSetError(
											//     "firstName",
											//     "USERNAME_LENGTH"
											//   );
											//   return false;
											// }
											// if (
											//   this.props.profileEditForm.fields.lastName.value &&
											//   !validateName(
											//     this.props.profileEditForm.fields.lastName.value
											//   )
											// ) {
											//   this.props.profileEditFormSetError(
											//     "lastName",
											//     "USERNAME_LENGTH"
											//   );
											//   return false;
											// }

											let postParam = {
												firstName: this.props
													.profileEditForm.fields
													.firstName.value
													? this.props.profileEditForm
															.fields.firstName
															.value
													: "",
												lastName: this.props
													.profileEditForm.fields
													.lastName.value
													? this.props.profileEditForm
															.fields.lastName
															.value
													: "",
												birthDate: null,
												name: this.props.profileEditForm
													.fields.username.value
													? this.props.profileEditForm
															.fields.username
															.value
													: "",
												avatarUrl:
													this.props.profilePic,
												socialFacebook: this.props
													.profileEditForm.fields
													.facebook.value
													? this.props.profileEditForm
															.fields.facebook
															.value
													: "",
												socialTwitter: this.props
													.profileEditForm.fields
													.twitter.value
													? this.props.profileEditForm
															.fields.twitter
															.value
													: "",
												socialInstagram: this.props
													.profileEditForm.fields
													.instagram.value
													? this.props.profileEditForm
															.fields.instagram
															.value
													: "",
											};
											if (
												!this.props
													.usernameAvailableForm
													.fields.username.error
											) {
												this.props.profileEditFormSubmit(
													postParam
												);
											}
										}
									}}
								>
									<div className="form-group with-lable">
										<div className="row">
											<div className="col-sm-12 ">
												<AppInputComponent
													inputClass={
														this.props
															.profileEditForm
															.fields.firstName
															.error
															? "form-control effect-17"
															: "form-control effect-16"
													}
													labelText={t("firstName")}
													value={
														this.props
															.profileEditForm
															.fields.firstName
															.value
													}
													onChange={(event) => {
														this.props.profileEditFormResetError(
															"firstName"
														);
														if (
															event.target
																.value &&
															!validateName(
																event.target
																	.value
															)
														) {
															this.props.profileEditFormSetError(
																"firstName",
																"USERNAME_LENGTH"
															);
															return false;
														}

														this.props.profileEditFormUpdateField(
															"firstName",
															event.target.value
														);
													}}
												/>
											</div>
											<div className="col-sm-12 mt-5 ">
												<AppInputComponent
													inputClass={
														this.props
															.profileEditForm
															.fields.lastName
															.error
															? "form-control effect-17"
															: "form-control effect-16"
													}
													labelText={t("lastName")}
													value={
														this.props
															.profileEditForm
															.fields.lastName
															.value
													}
													onChange={(event) => {
														this.props.profileEditFormResetError(
															"lastName"
														);
														if (
															event.target
																.value &&
															!validateName(
																event.target
																	.value
															)
														) {
															this.props.profileEditFormSetError(
																"lastName",
																"USERNAME_LENGTH"
															);
															return false;
														}
														this.props.profileEditFormUpdateField(
															"lastName",
															event.target.value
														);
													}}
												/>
											</div>
											<div className="col-sm-12 mt-5 ">
												<AppInputComponent
													inputClass={
														this.props
															.profileEditForm
															.fields.username
															.errorMsg ||
														this.props
															.usernameAvailableForm
															.fields.username
															.errorMsg ||
														this.state
															.searchedUsername ===
															""
															? "form-control effect-17"
															: "form-control effect-16"
													}
													labelText={t("username")}
													value={
														this.props
															.profileEditForm
															.fields.username
															.value
													}
													onKeyPress={(e) => {
														if (e.which === 32) {
															e.preventDefault();
															return false;
														}
													}}
													onChange={(event) => {
														this.props.usernameAvailableFormReset(
															usernameAvailableForm
														);
														this.setState({
															searchedUsername:
																event.target
																	.value,
														});
														this.props.profileEditFormUpdateField(
															"username",
															event.target.value
														);
														if (
															!event.target.value
														) {
															this.props.profileEditFormResetError(
																profileEditForm
															);
															this.props.usernameAvailableFormSetError(
																"username",
																"NOT_EMPTY"
															);
															return false;
														} else {
															if (
																!validateUsername(
																	event.target
																		.value
																)
															) {
																this.props.usernameAvailableFormSetError(
																	"username",
																	"USERNAME_LENGTH"
																);
																return false;
															}
														}
														if (
															event.target
																.value !==
															this.props.name
														) {
															this.props.usernameAvailableFormSubmit(
																{
																	name: event
																		.target
																		.value,
																}
															);
														}
													}}
													inputAddon={() =>
														!this.props
															.usernameAvailableForm
															.fields.username
															.error &&
														!this.props
															.profileEditForm
															.fields.username
															.error &&
														this.state
															.searchedUsername !==
															"" ? (
															<img
																src={
																	CheckGreenCircle
																}
																className="checked-playername"
																alt=""
															/>
														) : (
															""
														)
													}
												/>
											</div>
											<div className="col-sm-12 mt-5 ">
												<AppInputComponent
													labelText={t("mobileNum")}
													value={
														this.props.phoneNumber
													}
													readOnly={true}
												/>
											</div>
											<div className="col-sm-12 mt-5 ">
												<AppInputComponent
													labelText={t("instaHandle")}
													value={
														this.props
															.profileEditForm
															.fields.instagram
															.value
													}
													onChange={(event) => {
														this.props.profileEditFormSetError(
															"username",
															""
														);
														this.props.profileEditFormUpdateField(
															"instagram",
															event.target.value
														);
													}}
												/>
											</div>
											<div className="col-sm-12 mt-5 ">
												<AppInputComponent
													labelText={t("fbName")}
													value={
														this.props
															.profileEditForm
															.fields.facebook
															.value
													}
													onChange={(event) => {
														this.props.profileEditFormSetError(
															"username",
															""
														);
														this.props.profileEditFormUpdateField(
															"facebook",
															event.target.value
														);
													}}
												/>
											</div>
											<div className="col-sm-12 mt-5 ">
												<AppInputComponent
													labelText={t(
														"twiterHandle"
													)}
													value={
														this.props
															.profileEditForm
															.fields.twitter
															.value
													}
													onChange={(event) => {
														this.props.profileEditFormSetError(
															"username",
															""
														);
														this.props.profileEditFormUpdateField(
															"twitter",
															event.target.value
														);
													}}
												/>
											</div>

											{/* <div className="col-sm-12 mt-3 ">
                        <span
                          onClick={() => {
                            this.props.changePasswordOpen();
                            // this.props.sidebarCloseAll();
                          }}
                          className="text-secondary"
                        >
                          <h6>Change Password</h6>
                        </span>
                      </div> */}
											<div className="col-sm-12 mt-5 ">
												<button
													type="submit"
													className={
														"[ btn btn-" +
														platformParams[
															"baseButtonClassSufix"
														] +
														" btn-block dark ]"
													}
												>
													{t("updateProfile")}
												</button>
											</div>
											{/* <div className="col-sm-12 mt-5 text-center ">
                        <span className="text-dark">
                          <h6 className="bold">Delete My Account</h6>
                        </span>
                      </div> */}
										</div>
									</div>
								</form>
								<form
									id="pills-overall"
									className={
										"tab-pane fade " +
										(this.state.tabClass
											? ""
											: "active show")
									}
									role="tabpanel"
									aria-labelledby="pills-overall-tab"
								>
									<h6>{t("dataGoes")}</h6>
								</form>
							</section>
						</section>
					</div>
				</nav>
			</>
		);
	}
}
const mapDispatchToProps = {
	disableOuterClick: false,
	profileEditSidebarOpen: profileEditSidebar.open,
	profileEditSidebarClose: profileEditSidebar.close,
	logoutUserIfNeeded,
	sidebarCloseAll,
	profileSidebarOpen: profileSidebar.open,
	changePasswordOpen: changePassword.open,

	usernameAvailableFormReset: usernameAvailableForm.reset,
	usernameAvailableFormSetError: usernameAvailableForm.setError,
	usernameAvailableFormSubmit: usernameAvailableForm.submit,

	profileEditFormSubmit: profileEditForm.submit,
	profileEditFormReset: profileEditForm.reset,
	profileEditFormResetField: profileEditForm.resetField,
	profileEditFormUpdateField: profileEditForm.updateField,
	profileEditFormSetError: profileEditForm.setError,
	profileEditFormSetSuccess: profileEditForm.setSuccess,
	profileEditFormResetError: profileEditForm.resetError,
	profileEditFormResetSuccess: profileEditForm.resetSuccess,
};

const mapStateToProps = (state) => {
	return {
		usernameAvailableForm: state.forms.usernameAvailableForm,
		profileEditForm: state.forms.profileEditForm,
		isprofileEditSidebarOpen: state.sidebars.isProfileEditSidebarOpen,
		profileId: state.user.item ? state.user.item.id : 0,
		// firstName: state.user.item ? state.user.item.first_name : "",
		phoneNumber: state.user.item ? state.user.item.phoneNumber : "",
		// lastName: state.user.item ? state.user.item.last_name : "",
		// name: state.user.item ? state.user.item.name : "",
		profilePic: state.user.item ? state.user.item.avatarUrl : "",
		// mobile: state.user.item ? "" : "",
		// instagram: state.user.item ? state.user.item.social_instagram : "",
		// twitter: state.user.item ? state.user.item.social_twitter : "",
		// facebook: state.user.item ? state.user.item.social_facebook : ""
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(RightSidebarProfileEdit));
