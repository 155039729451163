import {
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGIN_USER,
  CHANGE_LANGUAGE,
} from "../types/login";

const initialState = {
  isLogging: false,
  isLoggedIn: false,
  currentLang: "en"
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLogging: true
      };
    case LOGIN_USER:
      return {
        ...state,
        isLoggedIn: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        currentLang: action.currentLang,
        // ...axiosInstance.setLang(action.language)
      };
    default:
      return state;
  }
};
export default user;
