import {
  ListActionCreator,
  PaginationListActionCreator,
  DetailActionCreator
} from "./creators";
import moment from "moment";
import {
  getChallengesLandingPopular,
  getPremiumChallengesViewAll,
  getLiveChallengesViewAll,
  getQuickPlayChallengesViewAll,
  getUpcomingChallengesViewAll,
  getCategoryChallengesViewAll,
  getBrandChallengesViewAll,
  getChallengeDetail,
  getChallengesForSpecificBrand,
  getFeatureChallenges,
  getChallengesLeaderboard,
  getBrandLeaderboard
} from "../services/challenges.service";
import {
  getMyTournaments,
  getMyTournamentsEndedOfTwoMonth
} from "../services/tournaments.service";

import {
  ChallengesLandingPopular,
  ChallengesListLive,
  ChallengesListQuickPlay,
  ChallengesListUpcoming,
  ChallengesListPremium,
  ChallengesByBrand,
  FeatureChallenges
} from "../redux-config/listActionMap";

import {
  ChallengesLiveViewAll,
  ChallengesQuickPlayViewAll,
  ChallengesUpcomingViewAll,
  ChallengesCategoryViewAll,
  ChallengesBrandViewAll,
  MytournamentsPlaying,
  MytournamentsFinished,
  ChallengesLeaderboardsViewAll,
  BrandLeaderboardsViewAll
} from "../redux-config/plistActionMap";
import {
  ChallengeDetails,
  ClaimChallengeDetails
} from "../redux-config/detailActionMap";

import { quickChallengeJoin } from "./modals";

export const claimChallengeDetails = DetailActionCreator({
  ...ClaimChallengeDetails,
  service: getChallengeDetail,
  dataMapper: json => {
    return json.data.data;
  },
  onResponse: (json, dispatch) => {
    if (json.data.success) {
      if (
        json.data.data.hasAlreadyJoined === false &&
        -1 * moment().diff(json.data.data.timeOfEnd, "minutes") >= 1
      ) {
        dispatch(quickChallengeJoin.open());
      }
      dispatch(challengesByBrand.fetchIfNeeded(json.data.data.brandId));
    }
  }
});

export const challengeDetails = DetailActionCreator({
  ...ChallengeDetails,
  service: getChallengeDetail,
  dataMapper: json => {
    return json.data.data;
  },
  onResponse: (json, dispatch) => {
    if (json.data.success) {
      if (
        json.data.data.hasAlreadyJoined === false &&
        -1 * moment().diff(json.data.data.timeOfEnd, "minutes") >= 1
      ) {
        dispatch(quickChallengeJoin.open());
      }
      dispatch(challengesByBrand.fetchIfNeeded(json.data.data.brandId));
      dispatch(challengesLeaderboardsViewAll.invalidateAll());
      dispatch(
        challengesLeaderboardsViewAll.fetchIfNeeded({
          challengeId: json.data.data.id,
          page: 1
        })
      );
    }
  }
});

export const mytournamentsFinished = PaginationListActionCreator({
  ...MytournamentsFinished,
  service: getMyTournamentsEndedOfTwoMonth,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});
export const mytournamentsPlaying = PaginationListActionCreator({
  ...MytournamentsPlaying,
  service: getMyTournaments,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const challenges = ListActionCreator({
  ...ChallengesLandingPopular,
  service: getChallengesLandingPopular,
  dataMapper: json => json.data.data
});

export const challengesLive = ListActionCreator({
  ...ChallengesListLive,
  service: getLiveChallengesViewAll,
  dataMapper: json => json.data.data
});

export const featureChallenge = ListActionCreator({
  ...FeatureChallenges,
  service: getFeatureChallenges,
  dataMapper: json => json.data.data
});

export const challengesQuickPlay = ListActionCreator({
  ...ChallengesListQuickPlay,
  service: getQuickPlayChallengesViewAll,
  dataMapper: json => json.data.data
});

export const challengesUpcoming = ListActionCreator({
  ...ChallengesListUpcoming,
  service: getUpcomingChallengesViewAll,
  dataMapper: json => json.data.data
});

export const challengesPremium = ListActionCreator({
  ...ChallengesListPremium,
  service: getPremiumChallengesViewAll,
  dataMapper: json => json.data.data
});

export const challengesLiveViewAll = PaginationListActionCreator({
  ...ChallengesLiveViewAll,
  service: getLiveChallengesViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const challengesQuickPlayViewAll = PaginationListActionCreator({
  ...ChallengesQuickPlayViewAll,
  service: getQuickPlayChallengesViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const challengesUpcomingViewAll = PaginationListActionCreator({
  ...ChallengesUpcomingViewAll,
  service: getUpcomingChallengesViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const challengesCategoryViewAll = PaginationListActionCreator({
  ...ChallengesCategoryViewAll,
  service: getCategoryChallengesViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const challengesBrandViewAll = PaginationListActionCreator({
  ...ChallengesBrandViewAll,
  service: getBrandChallengesViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const challengesLeaderboardsViewAll = PaginationListActionCreator({
  ...ChallengesLeaderboardsViewAll,
  service: getChallengesLeaderboard,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});
export const brandLeaderboardsViewAll = PaginationListActionCreator({
  ...BrandLeaderboardsViewAll,
  service: getBrandLeaderboard,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const challengesByBrand = ListActionCreator({
  ...ChallengesByBrand,
  service: getChallengesForSpecificBrand,
  dataMapper: json => json.data.data
});

export const challengesOfFriendsViewAll = PaginationListActionCreator({
  ...ChallengesUpcomingViewAll,
  service: getUpcomingChallengesViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});
