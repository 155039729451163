import React, { Component } from "react";
import { connect } from "react-redux";
import { initialiseSocket } from "../actions/socket";

export default ComposedComponent => {
  class SocketHoc extends Component {
    componentDidMount() {
      if (this.props.userDetail) {
        this.props.initialiseSocket();
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }
  const mapDispatchToProps = {
    initialiseSocket
  };

  const mapStateToProps = state => {
    return {
      userDetail: state.user.item,
      currentLang : state.login.currentLang
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(SocketHoc);
};
