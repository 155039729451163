import React, { Component } from "react";
import { connect } from "react-redux";
import { leftSidebar, sidebarCloseAll } from "../../../../actions/sidebars";
import CloseIcon from "../../../../assets/images/svg/nav-close.svg";
import {
  login,
  register,
  createName,
  modalCloseAll,
  unsubscribe
} from "../../../../actions/modals";
import { logoutUserIfNeeded } from "../../../../actions/user";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { isNgCountry, isZaCountry } from "../../../../utils/misc";
import platformParams from "../../../../utils/platformParams";

class LeftSidebarWithLogin extends Component {
  constructor(props) {
    super(props);
    this.showLeftSidebar = this.showLeftSidebar.bind(this);
    this.hideLeftSidebar = this.hideLeftSidebar.bind(this);
    this.hideLeftSidebarProxy = this.hideLeftSidebarProxy.bind(this);
    this.state = {
        isActiveNestingMenu: false
    }
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.hideLeftSidebarProxy, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.hideLeftSidebarProxy, false);
  }
  showLeftSidebar() {
    this.setState({isActiveNestingMenu: false});
    this.props.leftSidebarOpen();
  }
  hideLeftSidebar() {
    this.props.leftSidebarClose();
    this.setState({isActiveNestingMenu: false});
  }
  hideLeftSidebarProxy = e => {
    if (this.leftSidebar && this.leftSidebar.contains(e.target)) {
      return;
    }
    if (this.props.isLeftSidebarOpen) {
      this.hideLeftSidebar();
    }
    return;
  };

  render() {
    const  {isActiveNestingMenu } = this.state;
    const { t } = this.props;
    const lang = i18n.language;
    let envClass = platformParams['className'];
    return (
      <>
        <nav
          className={
            "d-block d-xl-none left-sidebar" +
            (this.props.isLeftSidebarOpen ? " active" : "")
          }
          ref={leftSidebar => {
            this.leftSidebar = leftSidebar;
          }}
        >
          <div className="dismiss" onClick={this.hideLeftSidebar}>
            <span className="icon-close">
              <img src={CloseIcon} alt="" />
            </span>
          </div>
          <div className={"sidebar-header 2" + platformParams['className']}>
            <div className="navbar-brand">
              <img
                src={platformParams['logoImage']}
                alt=""
                className="sidebarLogo"
              />
            </div>
          </div>
          <ul className="list-unstyled mt-4">
            <li className="nav-item">
              <a
                href={"/" + lang + "/dashboard"}
                className={"nav-link " + envClass}
              >
                {t("navDashboard")}
              </a>
            </li>
            <li className="nav-item">
              <span className="nav-link nestingMenu" onClick={() => {
                  this.setState((state, props) => ({
                      isActiveNestingMenu: !state.isActiveNestingMenu
                  }))
              }} >
                Support 
              </span>
            </li>
            <div className={"subMenuContainer " + (isActiveNestingMenu ? 'fadein' : 'fadeout')}>
                <li className={"nav-item sub-item "}>
                    <a
                    href={"/" + lang + "/help-support"}
                    className={"nav-link " + envClass}
                    >
                        Help & Support
                    </a>
                </li>
                <li className={"nav-item sub-item "}>
                    <a
                      href={"/" + lang + "/terms-conditions"}
                      className={"nav-link " + envClass}
                    >
                        Terms & Conditions
                    </a>
                </li>
                <li className={"nav-item sub-item "}>
                    <a
                      href={"/" + lang + "/privacy-policy"}
                      className={"nav-link " + envClass}
                    >
                        Privacy Policy
                    </a>
                </li>
                <li className={"nav-item sub-item "}>
                    <span
                        className="a"
                        onClick={() => {
                          this.props.unsubscribeOpen();
                        }}
                      >
                        Unsubscribe
                    </span>
                </li>
            </div>
            <li className="nav-item">
              <a
                href={"/" + lang + "/my-tournaments"}
                className={"nav-link " + envClass}
              > {t("myTournament")} </a>
            </li>
            { 
              !isNgCountry() ? 
              <li className="nav-item">
                <a href={"/" + lang + "/raffles"} className={"nav-link " + envClass}>
                  {t("raffles")}
                </a>
              </li>
              : <></>
            }
            { 
              process.env.REACT_APP_PLATFORM != "arcadex" ? 
                <li className="nav-item">
                  <span
                    className="a nav-link"
                    onClick={() => {
                      this.props.modalCloseAll();
                      this.props.sidebarCloseAll();
                      this.props.logoutUserIfNeeded();
                    }}
                  >
                    {t("logoutMobile")}
                  </span>
                </li>
               : <></>
            }
          </ul>
        </nav>
      </>
    );
  }
}
const mapDispatchToProps = {
  leftSidebarOpen: leftSidebar.open,
  loginOpen: login.open,
  leftSidebarClose: leftSidebar.close,
  logoutUserIfNeeded,
  registerOpen: register.open,
  createNameOpen: createName.open,
  modalCloseAll,
  sidebarCloseAll,
  unsubscribeOpen: unsubscribe.open
};

const mapStateToProps = state => {
  return {
    isLeftSidebarOpen: state.sidebars.isLeftSidebarOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LeftSidebarWithLogin));
