export const MytournamentsPlaying = {
  code: "MYTOURNAMENT_PLAYING",
  stateKey: "challenges",
  stateSubKey: "mplaying"
};
export const MytournamentsFinished = {
  code: "MYTOURNAMENT_FINISHED",
  stateKey: "challenges",
  stateSubKey: "mfinished"
};

export const ChallengesLiveViewAll = {
  code: "CHALLENGES_LIVE_VIEW_ALL",
  stateKey: "challenges",
  stateSubKey: "liveViewAll"
};

export const ChallengesQuickPlayViewAll = {
  code: "CHALLENGES_QUICK_PLAY_VIEW_ALL",
  stateKey: "challenges",
  stateSubKey: "quickPlayViewAll"
};

export const ChallengesUpcomingViewAll = {
  code: "CHALLENGES_UPCOMING_VIEW_ALL",
  stateKey: "challenges",
  stateSubKey: "upcomingViewAll"
};

export const BrandsPopularViewAll = {
  code: "BRANDS_POPULAR_VIEW_ALL",
  stateKey: "brands",
  stateSubKey: "popularBrandsViewAll"
};

export const HostChallengesLiveViewAll = {
  code: "HOST_CHALLENGES_LIVE_VIEW_ALL",
  stateKey: "brands",
  stateSubKey: "liveViewAll"
};
export const HostChallengesUpcomingViewAll = {
  code: "HOST_CHALLENGES_UPCOMING_VIEW_ALL",
  stateKey: "brands",
  stateSubKey: "upcomingViewAll"
};
export const HostChallengesQuickPlayViewAll = {
  code: "HOST_CHALLENGES_QUICK_PLAY_VIEW_ALL",
  stateKey: "brands",
  stateSubKey: "quickPlayViewAll"
};
export const HostTopPlayersViewAll = {
  code: "HOST_TOP_PLAYERS_VIEW_ALL",
  stateKey: "brands",
  stateSubKey: "topPlayersViewAll"
};

export const FreeToPlayViewAll = {
  code: "CATEGORIES_FREE_TO_PLAY_VIEW_ALL",
  stateKey: "categories",
  stateSubKey: "freeToPlayViewAll"
};

export const ChallengesCategoryViewAll = {
  code: "CHALLENGES_CATEGORY_VIEW_ALL",
  stateKey: "challenges",
  stateSubKey: "ChallengesCategoryViewAll"
};

export const NewsListViewAll = {
  code: "NEWS_LIST_VIEW_ALL",
  stateKey: "news",
  stateSubKey: "newsListViewAll"
};

export const ChallengesBrandViewAll = {
  code: "CHALLENGES_BRAND_VIEW_ALL",
  stateKey: "challenges",
  stateSubKey: "ChallengesBrandViewAll"
};

export const ArcadeGamesByCategoryViewAll = {
  code: "ARCADE_GAMES_BY_CATEGORY_VIEW_ALL",
  stateKey: "categories",
  stateSubKey: "ArcadeGamesByCategoryViewAll"
};

export const RafflesViewAll = {
  code: "RAFFLES_VIEW_ALL",
  stateKey: "raffles",
  stateSubKey: "rafflesViewAll"
};

export const RafflesActiveViewAll = {
  code: "RAFFLES_ACTIVE_VIEW_ALL",
  stateKey: "raffles",
  stateSubKey: "rafflesActiveViewAll"
};

export const FriendsChallengesViewAll = {
  code: "FRIENDS_CHALLENGES_VIEW_ALL",
  stateKey: "profiles",
  stateSubKey: "friendsChallengesViewAll"
};

export const ChallengesLeaderboardsViewAll = {
  code: "CHALLENGES_LEADERBOARDS_VIEW_ALL",
  stateKey: "challenges",
  stateSubKey: "ChallengesLeaderboardsViewAll"
};

export const BrandLeaderboardsViewAll = {
  code: "BRAND_LEADERBOARDS_VIEW_ALL",
  stateKey: "brands",
  stateSubKey: "BrandLeaderboardsViewAll"
};