import {
  INCREMENT_FETCH,
  DECREMENT_FETCH,
  INCREMENT_SUBMIT,
  DECREMENT_SUBMIT
} from "../types/loaders";
export const incrementFetch = () => ({
  type: INCREMENT_FETCH
});
export const decrementFetch = () => ({
  type: DECREMENT_FETCH
});
export const incrementSubmit = () => ({
  type: INCREMENT_SUBMIT
});
export const decrementSubmit = () => ({
  type: DECREMENT_SUBMIT
});
