/* eslint eqeqeq: 0 */
import "babel-polyfill";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import createStore from "./store";
import App from "./App";
import SocketHoc from "./hoc/SocketHoc";
import "./styles/master.scss";
import { axios } from "./utils/axios";
import { logoutUserIfNeeded } from "./actions/user";
import i18n from './i18n';
import { I18nextProvider } from "react-i18next";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Redirect, withRouter } from "react-router";
import socket from "./socket";
import { messageHistoryRequest } from "./actions/messagehistory";
import { emitInitSocket } from "./actions/socket";
import { notificationsRequest } from "./actions/notification";
import config from "./services/config";
import { API_SERVER_URL } from "./services/config/apiEndpoints";
import { axiosInstance } from "./utils/axios";
import { diff_minutes } from "./utils/date";

const { store, persistor } = createStore();
axios.interceptors.response.use(response => {
  // setTimeout(() => {
    window.location.href='https://mtnarena.co.za';
  // }, 5000);
  if ( response.data &&
    response.data.error &&
    response.data.error.code )
    {
      if(response.data.error.code === "A002" ||
        response.data.error.code === "A001" ||
        response.data.error.code === "JWT1" ||
        response.data.error.code === "JWT2" ||
        response.data.error.code === "JWT5" ||
        response.data.error.code === "RJWT1" ) 
        {
          store.dispatch(logoutUserIfNeeded());
        }
      else if(response.data.error.code === "JWT3"){
        if(localStorage.getItem('refreshTokenReqSent')){
          var refreshTokenReqSentAt = new Date(localStorage.getItem('refreshTokenReqSentAt'));
          if(diff_minutes(new Date(), refreshTokenReqSentAt) >= 2 ){
            store.dispatch(logoutUserIfNeeded());
          }
          return true;
        }
        localStorage.setItem('refreshTokenReqSent', true);
        localStorage.setItem('refreshTokenReqSentAt', new Date().toJSON());
        axios.post(API_SERVER_URL + config.vasRefreshToken,
          {
            "refresh_token": localStorage.getItem('refreshToken')
          })
          .then(res => {
              if (res.status === 200 && res.data && res.data.token) {
                axiosInstance.setToken(res.data.token, res.data.refresh_token);
                window.location.reload();
              }
          }).catch(err => {
            console.log(err);
            store.dispatch(logoutUserIfNeeded());
          });
      }
    }
    
  return response;
});
socket.on('auth complete', () => {
  store.dispatch(messageHistoryRequest());
  store.dispatch(notificationsRequest());
})

socket.on('reconnect_attempt', (attemptNumber) => {
  // console.log(attemptNumber,"attemptNumber");
  attemptNumber === 1 && store.dispatch(emitInitSocket());
  // if(attemptNumber === 1){
  //   console.log(localStorage.getItem('RAtimestamp'),"RAtimestamp");
  //   if(localStorage.getItem('RAtimestamp') !== '' && localStorage.getItem('RAtimestamp') !== null){
  //     localStorage.setItem("RAtimestamp", new Date().getTime());
  //     store.dispatch(emitInitSocket());
  //     console.log(attemptNumber,"attemptNumber");
  //   }else{
  //     var secondsPast = (new Date().getTime() - localStorage.getItem("RAtimestamp")) / 1000;
  //     if(secondsPast > 30){
  //       console.log(secondsPast,"secondsPast");
  //       store.dispatch(emitInitSocket());
  //     }
  //   }
  // }
});

const MyRedirect = withRouter((props) =>
  <Redirect {...props} to={props.to + props.location.search} />
)
const AppWithSocket = SocketHoc(App);
render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Switch>
              <Route path="/:locale" component={AppWithSocket} />
              <MyRedirect to="/en" />
            </Switch>
          </Router>
          {/* <AppWithSocket /> */}
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
