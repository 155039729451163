import { TUTORIALS_REQUEST, TUTORIALS_RESET_ALL } from "../types/tutorials";

export const tutorialRequest = tutorialsPreferance => {
  return {
    type: TUTORIALS_REQUEST,
    data: tutorialsPreferance
  };
};

export const tutorialResetAll = () => {
  return {
    type: TUTORIALS_RESET_ALL
  };
};
