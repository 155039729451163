import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import i18n from "../i18n";

export default function(ComposedComponent) {
  class LoginHoc extends Component {
    render() {
      const lang = i18n.language;
      return (
        <>
          {!this.props.isAuthenticated ? (
            <ComposedComponent {...this.props} />
          ) : (
            // <Redirect to={this.props.match.url + "/dashboard"} />
            <Redirect to={"/" + lang + "/dashboard"} />
          )}
        </>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      isAuthenticated: state.login.isLoggedIn
    };
  };
  return connect(mapStateToProps)(LoginHoc);
}
