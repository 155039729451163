import { MODAL_CLOSE_ALL } from "../../types/modals";

export default params => (state = false, action) => {
  switch (action.type) {
    case "MODAL_" + params.code + "_OPEN":
      return true;
    case "MODAL_" + params.code + "_CLOSE":
    case MODAL_CLOSE_ALL:
      return false;
    default:
      return state;
  }
};
