import {
  NOTIFICATIONS_RECEIVED_NEW,
  REMOVE_NOTIFICATION,
  BLACK_IMG_B64,
  REMOVE_ALL_NOTIFICATIONS,
  NOTIFICATIONS_OPEN
} from "../types/notification";

const initialState = {
  unread: 0,
  alert:false,
  list: [],
  friends: [],
  unreadFriends:0
};

const notifications = (state = initialState, action) => {
  switch (action.type) {

   case REMOVE_NOTIFICATION:{

      let list = state.list.slice(0);
      let newList = list.filter(n => n.id != action.payload.id);
      let friendNotifications = state.friends || [];
      friendNotifications = friendNotifications.slice(0);
      let friendsList = friendNotifications.filter(
        n => n.id != action.payload.id
      );

      return {
        ...state,
        list: newList,
        unread: Math.min(99, newList.slice(0).filter(n => !n.isRead).length),
        friends: friendsList,
        unreadFriends: Math.min(
          99,
          friendsList.slice(0).filter(n => !n.isRead).length
        )
      };
    }

    case NOTIFICATIONS_OPEN:{
      let markSeenIds = [];
      const list = state.list.slice(0).map(n => {
        markSeenIds.push(n.id);
        n.isRead = true;
        n.seen = true;
        return n;
      });
      //sockets.onNotificationSeen(markSeenIds);
      return {
        ...state,
        list: list,
        unread: Math.min(
          99,
          list.filter(n => {
            return !n.isRead;
          }).length
        ) 
      };
    }

    case NOTIFICATIONS_RECEIVED_NEW:{
        return {
          ...state,
          list: action.payload.list|| [],
          unread: action.payload.unread||0,
          friends: action.payload.friends||[],
          unreadFriends: action.payload.unreadFriends||0
        };
    }
    case REMOVE_ALL_NOTIFICATIONS:
      return {
        ...state,
        unread: 0,
        alert:false,
        list: [],
        friends: [],
        unreadFriends:0
      };

    default:
      return state;
  }
};
export default notifications;
