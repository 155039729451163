import React, {Component} from "react";
import ReactDOM from "react-dom";
import {connect} from "react-redux";
import {multipleTabEnforcedModal} from "../../../actions/modals";
import "./MultipleTabEnforcedModal.scss";
import Modal from "../Modal";
import {withTranslation, Trans} from "react-i18next";
import {axios} from "../../../utils/axios";
import {parseQuery} from "../../../utils/misc";

import i18n from "../../../i18n";
import platformParams from '../../../utils/platformParams';


class MultipleTabEnforcedModal extends Component {

    constructor(props) {
        super(props);
        this.showMultipleTabEnforcedModal = this.showMultipleTabEnforcedModal.bind(this);
        this.hideMultipleTabEnforcedModal = this.hideMultipleTabEnforcedModal.bind(this);
    }

    showMultipleTabEnforcedModal() {
        this.props.multipleTabEnforcedModalOpen();
    }

    hideMultipleTabEnforcedModal() {
        this.props.multipleTabEnforcedModalClose();
    }

    componentDidMount() {
        // Check if modal should pop up and show it if needs be
        if (window.location.search) {
            const query = parseQuery(window.location.search);
            if (query.multipletabs === '1') {
                this.showMultipleTabEnforcedModal();
            }
        }
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <Modal
                    showCloseBtn={false}
                    isOpen={this.props.isMultipleTabsEnforcedModalOpen}
                    disableOuterClick={true}
                    modalClass="modal"
                    modalCustomClass="bg-miggsterBGDarkGrey modal-multiple-tabs-enforced"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 align-self-center">
                                <h1>Oops!</h1>
                                <h3>You have too many game tabs open. Throw yourself into one game at a time for maximum focus!</h3>
                            </div>
                        </div>
                    </div>


                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {
    multipleTabEnforcedModalOpen: multipleTabEnforcedModal.open,
    multipleTabEnforcedModalClose: multipleTabEnforcedModal.close,
};

const mapStateToProps = state => {
    return {
        isMultipleTabsEnforcedModalOpen: state.modals.isMultipleTabsEnforcedModalOpen
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(MultipleTabEnforcedModal));
