import {
  ListActionCreator,
  PaginationListActionCreator,
  DetailActionCreator
} from "./creators";
import {
  getNewsViewAll,
  getNewsDetails,
  getNewsListWithCategories,
  getNewsCategories
} from "../services/news.service";
import {
  NewsList,
  NewsListByCategories,
  NewsCategoriesList
} from "../redux-config/listActionMap";
import { NewsListViewAll } from "../redux-config/plistActionMap";
import { NewsDetails } from "../redux-config/detailActionMap";

export const newsDetails = DetailActionCreator({
  ...NewsDetails,
  service: getNewsDetails,
  dataMapper: json => json.data.data
});

export const newsList = ListActionCreator({
  ...NewsList,
  service: getNewsViewAll,
  dataMapper: json => (json.data.success ? json.data.data : [])
});

export const newsCategoriesList = ListActionCreator({
  ...NewsCategoriesList,
  service: getNewsCategories,
  dataMapper: json => (json.data.success ? json.data.data : [])
});

export const newsListViewAll = PaginationListActionCreator({
  ...NewsListViewAll,
  service: getNewsViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const newsListByCategories = ListActionCreator({
  ...NewsListByCategories,
  service: getNewsListWithCategories,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});
