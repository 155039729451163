import React, { Component } from "react";
import { connect } from "react-redux";
import { login, activateUserModal } from "../../../actions/modals";
import "./ActivateUserModal.scss";
import Modal from "../Modal";
import Logo from "../../../assets/images/svg/logo.svg";
import { withTranslation } from "react-i18next";
import platformParams from "../../../utils/platformParams";

class ActivateUserModal extends Component {
  constructor(props) {
    super(props);
    this.hideActivateUserModal = this.hideActivateUserModal.bind(this);

    this.state = {
      isLandScape: false
    };
  }

  hideActivateUserModal() {
    this.props.activateUserModalClose();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Modal
          handleHide={this.hideActivateUserModal}
          isOpen={this.props.isActiveUserOpen}
          disableOuterClick={true}
        >
          <div className="card-popup login-form">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <img src={Logo} alt="" className="mainLogo" />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <h4 className="bold mb-2 mb-sm-4">{t("yourAccountActive")}</h4>
                <p>{t("goodLuckHaveFun")}</p>

                <button
                  type="button"
                  className={"btn btn-"+ platformParams['baseButtonClassSufix'] +" light btn-block mt-5 mt-sm-5"}
                  onClick={() => {
                    this.props.loginOpen();
                  }}
                >
                  {t("signin")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  loginOpen: login.open,
  activateUserModalClose: activateUserModal.close
};

const mapStateToProps = state => {
  return {
    isActiveUserOpen: state.modals.isActiveUserOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ActivateUserModal));
