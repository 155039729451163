import { combineReducers } from "redux";
import {
  DetailReducerCreator,
  ListReducerCreator,
  PaginationListReducerCreator
} from "./creators";
import { FriendsProfile } from "../redux-config/detailActionMap";
import {
  FriendsFollowers,
  FriendsTournaments,
  Followings,
  FriendsFollowings,
  FriendsFollowersViewAll,
  FriendsFollowingViewAll,
  LanguagesList
} from "../redux-config/listActionMap";
import { FriendsChallengesViewAll } from "../redux-config/plistActionMap";

const profiles = combineReducers({
  [FriendsProfile.stateSubKey]: DetailReducerCreator(FriendsProfile),
  [FriendsFollowers.stateSubKey]: ListReducerCreator(FriendsFollowers),
  [FriendsFollowings.stateSubKey]: ListReducerCreator(FriendsFollowings),
  [FriendsTournaments.stateSubKey]: ListReducerCreator(FriendsTournaments),
  [Followings.stateSubKey]: ListReducerCreator(Followings),
  [FriendsChallengesViewAll.stateSubKey]: PaginationListReducerCreator(
    FriendsChallengesViewAll
  ),
  [FriendsFollowersViewAll.stateSubKey]: ListReducerCreator(
    FriendsFollowersViewAll
  ),
  [FriendsFollowingViewAll.stateSubKey]: ListReducerCreator(
    FriendsFollowingViewAll
  ),
  [LanguagesList.stateSubKey]: ListReducerCreator(
    LanguagesList
  )
});

export default profiles;
