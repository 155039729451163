import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getFriends = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getFriends,
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const followFriend = postParams => {  
  return axios
    .request({
      url:
        API_SERVER_URL + config.follow.replace("##id##", postParams.followeeId),
      method: "post",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const unfollowFriend = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.unfollow.replace("##id##", postParams.followeeId),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const addAsFriendService = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.addAsFriend.replace("##id##", postParams.profileSecondId),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const friendDeclinedService = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.friendDeclined.replace("##id##", postParams.requestorId),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const friendAcceptedService = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.friendAccepted.replace("##id##", postParams.requestorId),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const unFriendService = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.unFriend.replace("##id##", postParams.profileIdToUnfriend),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};
