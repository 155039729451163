import React, { Component } from "react";
import { connect } from "react-redux";
import { unsubscribe } from "../../../actions/modals";
import { AllMsgClear } from "../../../actions/alerts";
import "./UnsubscribeModal.scss";
import ModalUS from "../ModalUS";
import { APP_MESSAGES } from "../../../services/config/messages";
import { withTranslation } from "react-i18next";
import {
  billingUnsubscribeForm
} from "../../../actions/vas";

class UnsubscribeModal extends Component {
  constructor(props) {
    super(props);
    this.hideUnsubscribe = this.hideUnsubscribe.bind(this);

    this.state = {
      isLandScape: false
    };
  }

  hideUnsubscribe() {
    this.props.unsubscribeClose();
    this.props.billingUnsubscribeFormResetError("phonenumber");
    this.props.AllMsgClear();
  }
  modalFooter() {
    const { t } = this.props;
    return (
      <div className="modal-footer">
        <ul className="list-group w-100 list-group-flush m-0">
          <li
            className="list-group-item text-center p-4 bg-mtnThemeMainColor border-bottom"
            onClick={e => {
              e.preventDefault();
            }}
          >
            <span
              className={"a text-white"}
              onClick={() => {
                let postParam = {
                  phoneNumber: this.props.phoneNumber
                };
                this.props.billingUnsubscribeFormSubmit(postParam);
              }}
            >
              <b>{t("yes")}</b>
            </span>
          </li>
          <li className="list-group-item text-center p-4 bg-mtnThemeMainColor">
            <span className="a text-white" onClick={this.hideUnsubscribe}>
             <b> {t("challengePage.cancel")}</b>
            </span>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    let Error;

    Error = this.props.unSubscribeForm.fields.phonenumber.errorMsg
      ? APP_MESSAGES[this.props.unSubscribeForm.fields.phonenumber.errorMsg](
          this.props.unSubscribeForm.fields.phonenumber.errorPayload,
          () => {
            this.props.billingUnsubscribeFormResetError("phonenumber");
          }
        )
      : props => <>{""}</>;

    const { t } = this.props;
    return (
      <>
        <ModalUS
          handleHide={this.hideUnsubscribe}
          isOpen={this.props.isUnsubscribeOpen}
          disableOuterClick={false}
          modalClass="modal modal-unfollow modal-change-password unsubscribeModal"
          withFooter={true}
          footer={this.modalFooter()}
        >
          <div className="card-popup login-form">
          <div className="row">
              <div className="col-sm-12" style={{ color: "red" }}>
                <Error />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4"></div>
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h5>{t("unsubscribe")} ?</h5>
              </div>
            </div>
          
            <div className="row">
              <div className="col-sm-12" />
            </div>
          </div>
        </ModalUS>
      </>
    );
  }
}
const mapDispatchToProps = {
  unsubscribeOpen: unsubscribe.open,
  unsubscribeClose: unsubscribe.close,
  AllMsgClear,
  billingUnsubscribeFormSubmit: billingUnsubscribeForm.submit,

  billingUnsubscribeFormReset: billingUnsubscribeForm.reset,
  billingUnsubscribeFormResetField: billingUnsubscribeForm.resetField,
  billingUnsubscribeFormUpdateField: billingUnsubscribeForm.updateField,
  billingUnsubscribeFormSetError: billingUnsubscribeForm.setError,
  billingUnsubscribeFormSetSuccess: billingUnsubscribeForm.setSuccess,
  billingUnsubscribeFormResetError: billingUnsubscribeForm.resetError,
  billingUnsubscribeFormResetSuccess: billingUnsubscribeForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    isUnsubscribeOpen: state.modals.isUnsubscribeOpen,
    phoneNumber: state.user.item ? state.user.item.phoneNumber : 0,
    isLogging: state.login.isLogging,
    unSubscribeForm: state.forms.unsubscribeform
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UnsubscribeModal));
