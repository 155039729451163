import {
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_USERNAME_LENGTH,
  MAX_USERNAME_LENGTH,
  MIN_NAME_LENGTH,
  MAX_NAME_LENGTH,
  MIN_NUMBER_NAME_LENGTH,
  MAX_NUMBER_NAME_LENGTH
} from "./constants";

export const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
/*eslint-enable no-useless-escape */

export const validateMsisdn = mobNumber => {
  let regex = /^((\+27)|(0027)|(0[1-9])|(27))\d+/g;
  let match = regex.exec(mobNumber);
  if (match && match.length === 6) {
    if (match[2]) return mobNumber.substring(1);
    if (match[3]) return mobNumber.substring(2);
    if (match[4])
      return mobNumber.substring(1).padStart(mobNumber.length + 1, "27");
    if (match[5]) return mobNumber;
    return false;
  } else {
    return false;
  }
};

export const checkSignUpValidationError = (email, password) => {
  let result = {
    error: false,
    errorText: ""
  };

  if (!validateEmail(email)) {
    result.error = true;
    result.errorText = "Please, provide a valid email address"; //#todo -> move this outside and use codes?
  } else if (!validatePassword(password)) {
    result.error = true;
    result.errorText = `Password must be between ${MIN_PASSWORD_LENGTH} and ${MAX_PASSWORD_LENGTH} characters long`;
  }

  return result;
};

export const checkResetPasswordError = (password, repeatPassword) => {
  let result = {
    error: false,
    errorText: ""
  };

  if (!validatePassword(password)) {
    result.error = true;
    result.errorText = `Password must be between ${MIN_PASSWORD_LENGTH} and ${MAX_PASSWORD_LENGTH} characters long`;
  } else if (!validatePasswordRepeat(password, repeatPassword)) {
    result.error = true;
    result.errorText = "Password and repeat password should be equal";
  }

  return result;
};

export const validatePassword = password => {
  if (
    password === "" ||
    password.length < MIN_PASSWORD_LENGTH ||
    password.length > MAX_PASSWORD_LENGTH
  )
    return false;

  return true;
};

export const validateNumber = number => {
  if (
    number === "" ||
    number.length < MIN_NUMBER_NAME_LENGTH ||
    number.length > MAX_NUMBER_NAME_LENGTH
  )
    return false;

  return true;
};

export const validatePasswordRepeat = (pass, repeatPass) => {
  if (pass !== repeatPass) return false;
  return true;
};

export const validateUsername = username => {
  if (
    username === "" ||
    username.length < MIN_USERNAME_LENGTH ||
    username.length > MAX_USERNAME_LENGTH
  )
    return false;

  return true;
};

export const validateName = name => {
  if (
    name === "" ||
    name.length < MIN_NAME_LENGTH ||
    name.length > MAX_NAME_LENGTH
  )
    return false;

  return true;
};
