import { combineReducers } from "redux";
import {
  ListReducerCreator,
  DetailReducerCreator,
  PaginationListReducerCreator
} from "./creators";
import {
  ChallengesLandingPopular,
  ChallengesListLive,
  ChallengesListQuickPlay,
  ChallengesListUpcoming,
  ChallengesListPremium,
  ChallengesByBrand,
  FeatureChallenges
} from "../redux-config/listActionMap";
import { ChallengeDetails, ClaimChallengeDetails } from "../redux-config/detailActionMap";

import {
  ChallengesLiveViewAll,
  ChallengesQuickPlayViewAll,
  ChallengesUpcomingViewAll,
  ChallengesCategoryViewAll,
  ChallengesBrandViewAll,
  MytournamentsFinished,
  MytournamentsPlaying,
  ChallengesLeaderboardsViewAll
} from "../redux-config/plistActionMap";

const challenges = combineReducers({
  [ChallengeDetails.stateSubKey]: DetailReducerCreator(ChallengeDetails),
  [ClaimChallengeDetails.stateSubKey]: DetailReducerCreator(ClaimChallengeDetails),
  [MytournamentsFinished.stateSubKey]: PaginationListReducerCreator(
    MytournamentsFinished
  ),
  [MytournamentsPlaying.stateSubKey]: PaginationListReducerCreator(
    MytournamentsPlaying
  ),
  [ChallengesLandingPopular.stateSubKey]: ListReducerCreator(
    ChallengesLandingPopular
  ),
  [ChallengesListLive.stateSubKey]: ListReducerCreator(ChallengesListLive),
  [ChallengesListQuickPlay.stateSubKey]: ListReducerCreator(
    ChallengesListQuickPlay
  ),
  [ChallengesListUpcoming.stateSubKey]: ListReducerCreator(
    ChallengesListUpcoming
  ),
  [ChallengesListPremium.stateSubKey]: ListReducerCreator(
    ChallengesListPremium
  ),
  [ChallengesLiveViewAll.stateSubKey]: PaginationListReducerCreator(
    ChallengesLiveViewAll
  ),
  [ChallengesQuickPlayViewAll.stateSubKey]: PaginationListReducerCreator(
    ChallengesQuickPlayViewAll
  ),
  [ChallengesUpcomingViewAll.stateSubKey]: PaginationListReducerCreator(
    ChallengesUpcomingViewAll
  ),
  [ChallengesCategoryViewAll.stateSubKey]: PaginationListReducerCreator(
    ChallengesCategoryViewAll
  ),
  [ChallengesBrandViewAll.stateSubKey]: PaginationListReducerCreator(
    ChallengesBrandViewAll
  ),
  [ChallengesLeaderboardsViewAll.stateSubKey]: PaginationListReducerCreator(
    ChallengesLeaderboardsViewAll
  ),
  [ChallengesByBrand.stateSubKey]: ListReducerCreator(ChallengesByBrand),
  [FeatureChallenges.stateSubKey]: ListReducerCreator(FeatureChallenges)
});

export default challenges;
