import {
  DetailActionCreator,
  ListActionCreator,
  PaginationListActionCreator
} from "./creators";
import {
  onGetUserProfile,
  getUserFollowers,
  getUserFollowings,
  onGetLanguages
} from "../services/users.service";
import { getMyTournaments, getProfileEnteredTournaments } from "../services/tournaments.service";
import { FriendsProfile } from "../redux-config/detailActionMap";
import {
  FriendsFollowers,
  FriendsTournaments,
  Followings,
  FriendsFollowings,
  FriendsFollowersViewAll,
  FriendsFollowingViewAll,
  LanguagesList
} from "../redux-config/listActionMap";
import { FriendsChallengesViewAll } from "../redux-config/plistActionMap";

export const friendsProfile = DetailActionCreator({
  ...FriendsProfile,
  service: onGetUserProfile,
  dataMapper: json => {
    return json.data.data;
  }
});

export const friendsFollowers = ListActionCreator({
  ...FriendsFollowers,
  service: getUserFollowers,
  dataMapper: json => {
    return { items: json.data.data.follower };
  }
});
export const friendsFollowings = ListActionCreator({
  ...FriendsFollowings,
  service: getUserFollowings,
  dataMapper: json => {
    return { items: json.data.data.followee };
  }
});

export const friendsTournaments = ListActionCreator({
  ...FriendsTournaments,
  service: getMyTournaments,
  dataMapper: json => json.data.data
});

export const followings = ListActionCreator({
  ...Followings,
  service: getUserFollowings,
  dataMapper: json => {
    return { items: json.data.data.followee };
  }
});

export const friendsChallengesViewAll = PaginationListActionCreator({
  ...FriendsChallengesViewAll,
  service: getProfileEnteredTournaments,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const friendsFollowersViewAll = ListActionCreator({
  ...FriendsFollowersViewAll,
  service: getUserFollowers,
  dataMapper: json => {
    return { items: json.data.data.follower };
  }
});

export const friendsFollowingViewAll = ListActionCreator({
  ...FriendsFollowingViewAll,
  service: getUserFollowings,
  dataMapper: json => {
    return { items: json.data.data.followee };
  }
});
export const languagesList = ListActionCreator({
  ...LanguagesList,
  service: onGetLanguages,
  dataMapper: json => {
    return { items: json.data.data };
  }
});