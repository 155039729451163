import { MODAL_CLOSE_ALL, MODAL_OPEN_ANY } from "../types/modals";

import { combineReducers } from "redux";
import { ModalReducerCreator } from "./creators";
import {
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  ChangePassword,
  CreateName,
  CreateAvatar,
  CreateTutorial,
  JoinChallengePassword,
  QuickChallengeJoin,
  EndGameModal,
  EndGameAnimateModal,
  ReportPlayer,
  Unfollow,
  Unsubscribe,
  VerifyPrize,
  ActivateUserModal,
  VasError,
  UnsubscribeSuccess,
  SubscribeFailed,
  MaintenanceModeModal,
  MultipleTabEnforcedModal,
  DeclarationModal
} from "../redux-config/modalActionMap";

const modals = combineReducers({
  [Login.stateSubKey]: ModalReducerCreator(Login),
  [Register.stateSubKey]: ModalReducerCreator(Register),
  [ForgotPassword.stateSubKey]: ModalReducerCreator(ForgotPassword),
  [ResetPassword.stateSubKey]: ModalReducerCreator(ResetPassword),
  [ChangePassword.stateSubKey]: ModalReducerCreator(ChangePassword),
  [CreateName.stateSubKey]: ModalReducerCreator(CreateName),
  [CreateAvatar.stateSubKey]: ModalReducerCreator(CreateAvatar),
  [CreateTutorial.stateSubKey]: ModalReducerCreator(CreateTutorial),
  [JoinChallengePassword.stateSubKey]: ModalReducerCreator(
    JoinChallengePassword
  ),
  [QuickChallengeJoin.stateSubKey]: ModalReducerCreator(QuickChallengeJoin),
  [EndGameModal.stateSubKey]: ModalReducerCreator(EndGameModal),
  [EndGameAnimateModal.stateSubKey]: ModalReducerCreator(EndGameAnimateModal),
  [ReportPlayer.stateSubKey]: ModalReducerCreator(ReportPlayer),
  [Unfollow.stateSubKey]: ModalReducerCreator(Unfollow),
  [Unsubscribe.stateSubKey]: ModalReducerCreator(Unsubscribe),
  [VerifyPrize.stateSubKey]: ModalReducerCreator(VerifyPrize),
  [ActivateUserModal.stateSubKey]: ModalReducerCreator(ActivateUserModal),
  [VasError.stateSubKey]: ModalReducerCreator(VasError),
  [UnsubscribeSuccess.stateSubKey]: ModalReducerCreator(UnsubscribeSuccess),
  [SubscribeFailed.stateSubKey]: ModalReducerCreator(SubscribeFailed),
  [MaintenanceModeModal.stateSubKey]: ModalReducerCreator(MaintenanceModeModal),
  [MultipleTabEnforcedModal.stateSubKey]: ModalReducerCreator(MultipleTabEnforcedModal),
  [DeclarationModal.stateSubKey]: ModalReducerCreator(DeclarationModal),
  isAnyModalOpen: (state = false, action) => {
    switch (action.type) {
      case MODAL_OPEN_ANY:
        return true;
      case MODAL_CLOSE_ALL:
        return false;
      default:
        return state;
    }
  }
});
export default modals;
