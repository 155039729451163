import React, { Component } from "react";
import "./MessageList.scss";
import ProfileImage from "../../../../assets/images/avatar-demo.png";
class MessageListItem extends Component {
  constructor(props){
    super(props);
    this.addDefaultSrc = this.addDefaultSrc.bind(this);
  }
  addDefaultSrc(ev) {
    ev.target.src = ProfileImage;
  }
  render() {
    
    const {allOnlineUserList} = this.props;
    const profileDetail = {
      id:this.props.userId,
      userName:this.props.userName,
      profileUrl:this.props.profileUrl,
    }
    return (
      <>
        <div className="a" 
         onClick={e => {
          this.props.showChatListSidebar(profileDetail);
        }}>
        <div className="row no-gutters d-flex justify-content-between align-items-center message-list bb-1">
          <div className="media col">
          
            {this.props.profileUrl ? (
            <img src={this.props.profileUrl}
                 alt="profile" className="profile-pic-48" 
                 onError={this.addDefaultSrc}
                //  onError={(e)=>(e.target.src="https://api.adorable.io/avatars/200/"+ this.props.userId +".png")}
                  />
          ) : (
            <img src={ProfileImage}
               alt="profile"
               className="profile-pic-48" 
               onError={this.addDefaultSrc}
              //  onError={(e)=>(e.target.src="https://api.adorable.io/avatars/200/"+ this.props.userId +".png")} 
               />
          )}
           {allOnlineUserList.includes(this.props.userId)?(<figure><figcaption className="online-48"></figcaption></figure>):null}
            <div className="media-body ml-3">
              <h6 className={this.props.isRead ? "" : "bold"}>{this.props.userName}</h6>
              <p className="mb-0 text-truncate mw-150">
              {this.props.message}
              </p>
              <p className="mb-0 fs-12 time-indication">{this.props.time}</p>
            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}

export default MessageListItem;
