export const PasswordEyeLogin = {
  code: "PW_EYE_LOGIN",
  stateKey: "toggles",
  stateSubKey: "pwEyeLogin"
};
export const PasswordEyeRegister = {
  code: "PW_EYE_REGISTER",
  stateKey: "toggles",
  stateSubKey: "pwEyeRegister"
};
export const PasswordEyeResetPass = {
  code: "PW_EYE_RESET_PASS",
  stateKey: "toggles",
  stateSubKey: "pwEyeResetPw"
};
export const PasswordEyeChangeOldPass = {
  code: "PW_EYE_CHANGE_OLD_PASS",
  stateKey: "toggles",
  stateSubKey: "pwEyeChangeOldPw"
};
export const PasswordEyeChangePass = {
  code: "PW_EYE_CHANGE_PASS",
  stateKey: "toggles",
  stateSubKey: "pwEyeChangePw"
};
export const HyveLogin = {
  code: "HYVE_LOGIN",
  stateKey: "toggles",
  stateSubKey: "hyveLogin"
};
export const HyveRegister = {
  code: "HYVE_REGISTER",
  stateKey: "toggles",
  stateSubKey: "hyveRegister"
};

export const MyTournamenPlaying = {
  code: "MY_TOURNAMENT_PLAYING",
  stateKey: "toggles",
  stateSubKey: "myTournamenPlaying"
};

export const GameFullScreen = {
  code: "GAME_FULL_SCREEN",
  stateKey: "toggles",
  stateSubKey: "gameFullScreen"
};
export const OtherReason = {
  code: "OTHER_REASON",
  stateKey: "toggles",
  stateSubKey: "otherReason"
};
