import { MESSAGE_HISTORY_REQUEST,MESSAGE_HISTORY_RESPONSE,MESSAGE_HISTORY_RESET } from "../types/messageshistory";

const initialState = {
  isFetching: false,
  items: [],
  unread:0,
};

const messagehistory = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_HISTORY_REQUEST:
      return {
        ...state,
        isFetching: true
     };

    case MESSAGE_HISTORY_RESPONSE:
    {
      let messageList = action.payload;
      let unreadCount = Math.min(99,messageList.filter(message => {return !message.isRead;}).length);
      return {
        ...state,
        isFetching: false,
        items: messageList,
        unread:unreadCount
    }; 
    
    }
    case MESSAGE_HISTORY_RESET:
      return {
        isFetching: false,
        items: [],
    }; 
    
    default:
      return state;
  }
};

export default messagehistory;
