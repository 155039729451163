import { combineReducers } from "redux";
import {
  ListReducerCreator,
  PaginationListReducerCreator,
  DetailReducerCreator
} from "./creators";
import {
  NewsList,
  NewsListByCategories,
  NewsCategoriesList
} from "../redux-config/listActionMap";
import { NewsListViewAll } from "../redux-config/plistActionMap";
import { NewsDetails } from "../redux-config/detailActionMap";

const news = combineReducers({
  [NewsDetails.stateSubKey]: DetailReducerCreator(NewsDetails),
  [NewsListByCategories.stateSubKey]: ListReducerCreator(NewsListByCategories),
  [NewsCategoriesList.stateSubKey]: ListReducerCreator(NewsCategoriesList),
  [NewsList.stateSubKey]: ListReducerCreator(NewsList),
  [NewsListViewAll.stateSubKey]: PaginationListReducerCreator(NewsListViewAll)
});

export default news;
