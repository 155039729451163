import { VALUE_UPDATE } from "../types/forms";
import { FormReducerCreator } from "./creators";
import {
  LoginForm,
  LoginHyveForm,
  RegisterForm,
  JoinChallengePasswordForm,
  FinishGameChallengeForm,
  RegisterHyveForm,
  SubscribeForm,
  CreateUsernameForm,
  UsernameAvailableForm,
  CreateAvatarForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  ChangePasswordForm,
  CreateTutorialForm,
  ProfileEditForm,
  GetProfileDataForm,
  QuestionForm,
  JoinRafflesForm,
  ReportPlayerForm,
  UnfollowForm,
  GetClaimPrizeForm,
  VerifyPrizeForm,
  FriendDeclineForm,
  FriendAcceptForm,
  PublishRaffleForm,
  ContactForm,
  UnsubscribeForm,
  EditAvatarForm,
  NGLoginForm
} from "../redux-config/formActionMap";
import { combineReducers } from "redux";

const initialState = {
  login: {
    email: "",
    password: ""
  },
  register: {
    email: "",
    password: "",
    mobileno: ""
  },
  resetPassword: {
    newPassword: "",
    renewPassword: ""
  },
  forgotPassword: {
    email: ""
  },
  subscribe: {
    email: ""
  },
  createUserName: {
    username: ""
  },
  createUserAvtar: {
    avatar: ""
  },
  createTutorial: {
    tutorial: ""
  }
};

const forms1 = (state = initialState, action) => {
  switch (action.type) {
    case VALUE_UPDATE:
      let newState = {};
      const { scope, field, value } = action.payload;
      newState[field] = value;

      return {
        ...state,
        [scope]: {
          ...state[scope],
          ...newState
        }
      };

    default:
      return state;
  }
};

const forms = combineReducers({
  [LoginForm.stateSubKey]: FormReducerCreator(LoginForm),
  [LoginHyveForm.stateSubKey]: FormReducerCreator(LoginHyveForm),
  [RegisterForm.stateSubKey]: FormReducerCreator(RegisterForm),
  [RegisterHyveForm.stateSubKey]: FormReducerCreator(RegisterHyveForm),
  [SubscribeForm.stateSubKey]: FormReducerCreator(SubscribeForm),
  [JoinChallengePasswordForm.stateSubKey]: FormReducerCreator(
    JoinChallengePasswordForm
  ),
  [FinishGameChallengeForm.stateSubKey]: FormReducerCreator(
    FinishGameChallengeForm
  ),
  [CreateUsernameForm.stateSubKey]: FormReducerCreator(CreateUsernameForm),
  [UsernameAvailableForm.stateSubKey]: FormReducerCreator(UsernameAvailableForm),
  [CreateAvatarForm.stateSubKey]: FormReducerCreator(CreateAvatarForm),
  [EditAvatarForm.stateSubKey]: FormReducerCreator(EditAvatarForm),
  [ForgotPasswordForm.stateSubKey]: FormReducerCreator(ForgotPasswordForm),
  [ResetPasswordForm.stateSubKey]: FormReducerCreator(ResetPasswordForm),
  [ChangePasswordForm.stateSubKey]: FormReducerCreator(ChangePasswordForm),
  [CreateTutorialForm.stateSubKey]: FormReducerCreator(CreateTutorialForm),
  [QuestionForm.stateSubKey]: FormReducerCreator(QuestionForm),
  [ReportPlayerForm.stateSubKey]: FormReducerCreator(ReportPlayerForm),
  [ProfileEditForm.stateSubKey]: FormReducerCreator(ProfileEditForm),
  [GetProfileDataForm.stateSubKey]: FormReducerCreator(GetProfileDataForm),
  [JoinRafflesForm.stateSubKey]: FormReducerCreator(JoinRafflesForm),
  [UnfollowForm.stateSubKey]: FormReducerCreator(UnfollowForm),
  [UnsubscribeForm.stateSubKey]: FormReducerCreator(UnsubscribeForm),
  [GetClaimPrizeForm.stateSubKey]: FormReducerCreator(GetClaimPrizeForm),
  [VerifyPrizeForm.stateSubKey]: FormReducerCreator(VerifyPrizeForm),
  [FriendDeclineForm.stateSubKey]: FormReducerCreator(FriendDeclineForm),
  [FriendAcceptForm.stateSubKey]: FormReducerCreator(FriendAcceptForm),
  forms1,
  [PublishRaffleForm.stateSubKey]: FormReducerCreator(PublishRaffleForm),
  [ContactForm.stateSubKey]: FormReducerCreator(ContactForm),
  [NGLoginForm.stateSubKey]: FormReducerCreator(NGLoginForm)
});
export default forms;
