import React, { Component } from "react";
import { connect } from "react-redux";
import IncludeModals from "../components/IncludeModals";
import ToastrContainer from "../../components/Toastr/ToastrContainer";

class MainLayout extends Component {
  render() {
    if (this.props.isAnyModalOpen || this.props.isAnySidebarOpen) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
    return (
      <>
        <section
          className={
            "mainSection" + (!this.props.isLoggedIn ? " premium" : " premium")
          }
        >
          {this.props.fetchCount > 0 ? (
            <div className="load-bar secondary">
              <div className="bar" />
              <div className="bar" />
            </div>
          ) : (
            ""
          )}

          <div
            className={
              (this.props.isAnyModalOpen || this.props.isAnySidebarOpen
                ? "disable-scroll"
                : "") +
              " " +
              (this.props.isAnyModalOpen ? "d-none d-lg-block" : "")
            }
          >
            <div className={"mainContainer"} style={{ marginTop: "0px" }}>
              {this.props.children}
            </div>
          </div>
          <div
            className={this.props.fetchCount > 0 ? "ui-blocker" : "d-none"}
          />
          <div
            className={
              "overlay" + (this.props.isAnySidebarOpen ? " active" : "")
            }
          />
        </section>
        <ToastrContainer />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { modals, sidebars } = state;

  const { isAnyModalOpen } = modals || {
    isAnyModalOpen: false
  };
  const { isAnySidebarOpen } = sidebars || {
    isAnySidebarOpen: false
  };
  return {
    isLoggedIn: state.login.isLoggedIn,
    isAnyModalOpen,
    isAnySidebarOpen,
    fetchCount: state.loaders.fetchCount,
    submitCount: state.loaders.submitCount
  };
};

export default connect(mapStateToProps)(MainLayout);
