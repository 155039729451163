import { ModalActionCreator, ModalCloseAll } from "./creators";
import {
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  CreateName,
  CreateAvatar,
  CreateTutorial,
  JoinChallengePassword,
  QuickChallengeJoin,
  EndGameModal,
  EndGameAnimateModal,
  ChangePassword,
  ReportPlayer,
  Unfollow,
  VerifyPrize,
  ActivateUserModal,
  VasError,
  Unsubscribe,
  UnsubscribeSuccess,
  SubscribeFailed,
  MaintenanceModeModal,
  MultipleTabEnforcedModal,
  DeclarationModal
} from "../redux-config/modalActionMap";

export const login = ModalActionCreator(Login);
export const register = ModalActionCreator(Register);
export const forgotPassword = ModalActionCreator(ForgotPassword);
export const resetPassword = ModalActionCreator(ResetPassword);
export const changePassword = ModalActionCreator(ChangePassword);
export const createName = ModalActionCreator(CreateName);
export const createAvatar = ModalActionCreator(CreateAvatar);
export const createTutorial = ModalActionCreator(CreateTutorial);
export const joinChallengePassword = ModalActionCreator(JoinChallengePassword);
export const quickChallengeJoin = ModalActionCreator(QuickChallengeJoin);
export const endGameModal = ModalActionCreator(EndGameModal);
export const endGameAnimateModal = ModalActionCreator(EndGameAnimateModal);
export const reportPlayer = ModalActionCreator(ReportPlayer);
export const unfollow = ModalActionCreator(Unfollow);
export const verifyPrizeModal = ModalActionCreator(VerifyPrize);
export const activateUserModal = ModalActionCreator(ActivateUserModal);
export const vasError = ModalActionCreator(VasError);
export const unsubscribe = ModalActionCreator(Unsubscribe);
export const unsubscribeSuccess = ModalActionCreator(UnsubscribeSuccess);
export const subscribeFailed = ModalActionCreator(SubscribeFailed);
export const maintenanceModeModal = ModalActionCreator(MaintenanceModeModal);
export const multipleTabEnforcedModal = ModalActionCreator(MultipleTabEnforcedModal);
export const declarationModal = ModalActionCreator(DeclarationModal);

export const modalCloseAll = ModalCloseAll;
