import {
  ERROR_SET,
  ERROR_CLEAR,
  SUCCESS_SET,
  SUCCESS_CLEAR,
  ALL_MSG_CLEAR
} from "../types/errors";

const alertInitialState = {
  msg: "",
  scope: ""
};
const initialState = {
  error: alertInitialState,
  success: alertInitialState
};

const alerts = (state = initialState, action) => {
  switch (action.type) {
    case ERROR_SET:
      return {
        ...state,
        error: {
          msg: action.payload.errorMsg,
          scope: action.payload.scope
        }
      };
    case ERROR_CLEAR:
      return {
        ...state,
        error: alertInitialState
      };
    case SUCCESS_SET:
      return {
        ...state,
        success: {
          msg: action.payload.successMsg,
          scope: action.payload.scope
        }
      };
    case SUCCESS_CLEAR:
      return {
        ...state,
        success: alertInitialState
      };
    case ALL_MSG_CLEAR:
      return initialState;

    default:
      return state;
  }
};
export default alerts;
