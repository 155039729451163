import {
  ListActionCreator,
  PaginationListActionCreator,
  DetailActionCreator
} from "./creators";

import {
  getPopularBrands,
  getPopularBrandsViewAll,
  getBrandDetails,
  getTopPlayersOfBrand
} from "../services/brands.service";

import {
  getLiveChallengesViewAll,
  getUpcomingChallengesViewAll,
  getQuickPlayChallengesViewAll
} from "../services/challenges.service";

import {
  BrandsListPopular,
  BrandsListLive,
  BrandsListQuickPlay,
  BrandsListUpcoming,
  BrandsTopPlayersList
} from "../redux-config/listActionMap";
import {
  BrandsPopularViewAll,
  HostChallengesLiveViewAll,
  HostChallengesUpcomingViewAll,
  HostChallengesQuickPlayViewAll,
  HostTopPlayersViewAll
} from "../redux-config/plistActionMap";
import { BrandDetails } from "../redux-config/detailActionMap";

export const brandDetails = DetailActionCreator({
  ...BrandDetails,
  service: getBrandDetails,
  dataMapper: json => json.data.success?json.data.data:json.data.error
});

export const brandsListPopular = ListActionCreator({
  ...BrandsListPopular,
  service: getPopularBrands,
  dataMapper: json => json.data.data
});

export const brandsPopularViewAll = PaginationListActionCreator({
  ...BrandsPopularViewAll,
  service: getPopularBrandsViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const hostChallengesLiveViewAll = PaginationListActionCreator({
  ...HostChallengesLiveViewAll,
  service: getLiveChallengesViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});
export const hostChallengesUpcomingViewAll = PaginationListActionCreator({
  ...HostChallengesUpcomingViewAll,
  service: getUpcomingChallengesViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});
export const hostChallengesQuickPlayViewAll = PaginationListActionCreator({
  ...HostChallengesQuickPlayViewAll,
  service: getQuickPlayChallengesViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const brandsLive = ListActionCreator({
  ...BrandsListLive,
  service: getLiveChallengesViewAll,
  dataMapper: json => json.data.data
});

export const brandsQuickPlay = ListActionCreator({
  ...BrandsListQuickPlay,
  service: getQuickPlayChallengesViewAll,
  dataMapper: json => json.data.data
});

export const brandsUpcoming = ListActionCreator({
  ...BrandsListUpcoming,
  service: getUpcomingChallengesViewAll,
  dataMapper: json => json.data.data
});

export const brandsTopPlayers = PaginationListActionCreator({
  ...BrandsTopPlayersList,
  service: getTopPlayersOfBrand,
  dataMapper: json => json.data.data
});
export const hostsTopPlayersViewAll = PaginationListActionCreator({
  ...HostTopPlayersViewAll,
  service: getTopPlayersOfBrand,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});
