import { TUTORIALS_REQUEST, TUTORIALS_RESET_ALL } from "../types/tutorials";

const tutorialsInitialStateGenerator = {
  hasCreatenamePreferences: false,
  hasCreateavatarPreferences: false,
  hasCreatetutorialPreferences: false
};

const tutorials = (state = tutorialsInitialStateGenerator, action) => {
  switch (action.type) {
    case TUTORIALS_REQUEST:
      return {
        ...state,
        ...action.data
      };
    case TUTORIALS_RESET_ALL:
      return tutorialsInitialStateGenerator;
    default:
      return state;
  }
};
export default tutorials;
